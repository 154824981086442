import React from 'react';
import * as actionTypes from './actionTypes';

export function openDialog(dialogType) {
    return {
        type: actionTypes.APP_OPEN_DIALOG,
        dialogType: dialogType
    };
};

export function closeDialog() {
    return {
        type: actionTypes.APP_CLOSE_DIALOG
    };
};

export function showAlert(message, alertType) {
    return {
        type: actionTypes.APP_SHOW_ALERT,
        message: message,
        alertType: alertType
    };
};

export function hideAlert() {
    return {
        type: actionTypes.APP_HIDE_ALERT
    };
};