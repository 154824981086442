import React from 'react';
import Header from 'components/common/header/Header';
import Footer from 'components/common/footer/Footer';
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize } from "react-localize-redux";
import globalTranslations from "translations/global.json";
import Container from '@material-ui/core/Container';

const MainLayout = props => {

    React.useEffect(() => {
        props.initialize({
            languages: [
                { name: 'English', code: 'en' },
                { name: 'Español', code: 'es' }
            ],
            translation: globalTranslations,
            options: { defaultLanguage: 'es', renderToStaticMarkup }
        });
        const defaultLanguage = localStorage.getItem('langCode') || 'es';
        props.setActiveLanguage(defaultLanguage);
    }, []);

    return (
        <div>
            <Header />
            <Container disableGutters maxWidth="lg">
                {props.children}
                <Footer />
            </Container>
        </div>
    );
}

export default withLocalize(MainLayout);