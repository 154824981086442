import React from 'react';
import * as appParams from 'config/params';
import * as actionTypes from './actionTypes';
import { Translate } from "react-localize-redux";
import axios from 'axios';
import * as action from 'state/actions';
import firebase from 'firebase.js';

export function categoryFetchList() {
    return async (dispatch) => {
        dispatch(action.CATEGORY_LIST_START());
        let categories = [];
        try {
            await firebase.firestore().collection('categories').get()
                .then((query) => {
                    query.forEach(function (doc) {
                        categories.push({ id: doc.id, ...doc.data() });
                    });
                });            
        } catch (error) {
            return dispatch(action.CATEGORY_LIST_FAIL({ error: error }));
        }
        return dispatch(action.CATEGORY_LIST_SUCCESS({ data: categories }));
    };
};

export function categoryFetchItem(id) {
    return dispatch => {
        dispatch(categoryItemStart());
        axios.post(appParams.WS_BACKEND + '/category/get', { id: id })
            .then(response => {
                dispatch(categoryItemSuccess(response.data));
            })
            .catch(error => {
                dispatch(categoryItemFail(error.response.data.error));
                dispatch(showAlert(error.response.data.error, "error"))
            });
    };
};

export function categorySubmitItem(formData) {
    return dispatch => {
        dispatch(categorySubmitStart());
        const data = new FormData();
        data.append('id', formData.id == null || formData.id == "" ? "0" : formData.id);
        data.append('code', formData.code);
        data.append('name', formData.name);
        data.append('priority', formData.priority);
        data.append('categoryId', formData.categoryId == null || formData.categoryId == "" ? "0" : formData.categoryId);
        if (formData.photo != null) {
            data.append('photo', formData.photo);
        }
        axios.post(appParams.WS_BACKEND + '/category/save', data)
            .then(response => {                
                dispatch(showAlert(formData.id == null ? "CREATED" : "UPDATED", "success"));
                dispatch(categorySubmitSuccess());
                categoryFetchItem(response.data.id);
                //Router.push('/category/[id]', '/category/' + response.data.id);
            })
            .catch(error => {
                dispatch(categorySubmitFail(error.response.data.error));
                dispatch(showAlert(error.response.data.error, "error"))
            });
    };
};

export function categoryDeleteItem(id) {
    return dispatch => {
        dispatch(categoryDeleteStart());
        axios.post(appParams.WS_BACKEND + '/category/delete', { id: id })
            .then(response => {
                dispatch(showAlert("DELETED", "success"));
                dispatch(categoryDeleteSuccess());
                dispatch(categoryFetchList());                
            })
            .catch(error => {
                dispatch(categoryDeleteFail(error.response.data.error));
                dispatch(showAlert(error.response.data.error, "error"))
            });
    };
};

export function categorySubmitStart() {
    return {
        type: actionTypes.CATEGORY_SUBMIT_START
    };
};

export function categorySubmitSuccess() {
    return {
        type: actionTypes.CATEGORY_SUBMIT_SUCCESS
    };
};

export function categorySubmitFail(error) {
    //let errorMessage = <Translate id="error" />;
    let errorMessage = error;
    return {
        type: actionTypes.CATEGORY_SUBMIT_FAIL,
        error: errorMessage
    };
};

export function categorySubmitEnd() {
    return {
        type: actionTypes.CATEGORY_SUBMIT_END
    };
};

export function categoryListStart() {
    return {
        type: actionTypes.CATEGORY_LIST_START
    };
};

export function categoryListSuccess(data) {
    return {
        type: actionTypes.CATEGORY_LIST_SUCCESS,
        data: data
    };
};

export function categoryListFail(error) {
    //let errorMessage = <Translate id="error" />;
    let errorMessage = error;
    return {
        type: actionTypes.CATEGORY_LIST_FAIL,
        error: errorMessage
    };
};

export function categoryListEnd() {
    return {
        type: actionTypes.CATEGORY_LIST_END
    };
};

export function categoryItemStart() {
    return {
        type: actionTypes.CATEGORY_ITEM_START
    };
};

export function categoryItemSuccess(data) {
    return {
        type: actionTypes.CATEGORY_ITEM_SUCCESS,
        data: data
    };
};

export function categoryItemFail(error) {
    //let errorMessage = <Translate id="error" />;
    let errorMessage = error;
    return {
        type: actionTypes.CATEGORY_ITEM_FAIL,
        error: errorMessage
    };
};

export function categoryItemEnd() {
    return {
        type: actionTypes.CATEGORY_ITEM_END
    };
};

export function categoryDeleteStart() {
    return {
        type: actionTypes.CATEGORY_DELETE_START
    };
};

export function categoryDeleteSuccess() {
    return {
        type: actionTypes.CATEGORY_DELETE_SUCCESS
    };
};

export function categoryDeleteFail(error) {
    //let errorMessage = <Translate id="error" />;
    let errorMessage = error;
    return {
        type: actionTypes.CATEGORY_DELETE_FAIL,
        error: errorMessage
    };
};

export function categoryDeleteEnd() {
    return {
        type: actionTypes.CATEGORY_DELETE_END
    };
};

export function showAlert(message, alertType) {
    let msj = '';
    switch (message) {
        case 'CREATED':
            msj = <Translate id="successCreated" data={{ name: <Translate id="category" /> }} />;
            break;
        case 'UPDATED':
            msj = <Translate id="successUpdated" data={{ name: <Translate id="category" /> }} />;
            break;
        case 'DELETED':
            msj = <Translate id="successDeleted" data={{ name: <Translate id="category" /> }} />;
            break;
        case 'CHILD_CONSTRAINT':
            msj = <Translate id="childConstraint" data={{ name: <Translate id="category" /> }} />;
            break;
        case 'NOT_FOUND':
            msj = <Translate id="notFound" data={{ name: <Translate id="category" /> }} />;
            break;
        case 'ALREADY_EXISTS':
            msj = <Translate id="alreadyExists" data={{ name: <Translate id="category" /> }} />;
            break;
        default:
            msj = alertType == "error" ? <Translate id="error" /> : "";
            break;
    }

    return {
        type: actionTypes.APP_SHOW_ALERT,
        message: msj,
        alertType: alertType
    };
};