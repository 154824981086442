import React from 'react';
import Typography from '@material-ui/core/Typography';
import MainLayout from 'components/common/layout/MainLayout';
import { withStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Grid from '@material-ui/core/Grid';
import red from '@material-ui/core/colors/red';
import { Translate, withLocalize } from "react-localize-redux";
import NewsCard from 'components/common/card/NewsCard';
import HomeCarousel from 'components/common/carousel/HomeCarousel';
import Button from '@material-ui/core/Button';

const styles = theme => ({
    grid: {
        flexGrow: 1,
        margin: 20,
        [theme.breakpoints.up('xl')]: {
            marginLeft: '0px !important',
            marginRight: '0px !important',
        }
    },
    layout: {
        display: 'flex',
        flexWrap: 'wrap',
        minWidth: 300,
        width: '100%',
        marginTop: '56px'
    },
    image: {
        position: 'relative',
        height: 150,
        [theme.breakpoints.down('xs')]: {
            width: '100% !important', // Overrides inline-style
            height: 100,
        },
        '&:hover, &$focusVisible': {
            zIndex: 1,
            '& $imageBackdrop': {
                opacity: 0.15,
            },
            '& $imageMarked': {
                opacity: 0,
            },
            '& $imageTitle': {
                border: '4px solid currentColor',
            },
        },
    },
    focusVisible: {},
    imageButton: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.common.white,
    },
    imageSrc: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundSize: 'cover',
        backgroundPosition: 'center 40%',
    },
    imageBackdrop: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: theme.palette.common.black,
        opacity: 0.4,
        transition: theme.transitions.create('opacity'),
    },
    imageTitle: {
        position: 'relative',
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
    },
    imageMarked: {
        height: 3,
        width: 18,
        backgroundColor: theme.palette.common.white,
        position: 'absolute',
        bottom: -2,
        left: 'calc(50% - 9px)',
        transition: theme.transitions.create('opacity'),
    },
    cardTitle: {
        lineHeight: 1.5,
    },
    cardTitleBox: {
        paddingBottom: 0,
    },
    cardTitleBoxLink: {
        textDecoration: 'none',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    actions: {
        display: 'flex',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
    searchBox: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        maxWidth: '500px',
        margin: '0 auto'
    },
    searchBoxInput: {
        marginLeft: 8,
        flex: 1,
    },
    searchBoxIconButton: {
        padding: 10,
    },
    searchCheckBox: {
        textAlign: 'center',
        display: 'inherit'
    },
    label1: {
        padding: '50px',
        [theme.breakpoints.up('md')]: {
            padding: '100px',
            paddingLeft: '0px',
        },
    },
    label2: {
        padding: '50px',
        paddingBottom: '0px',
        [theme.breakpoints.up('md')]: {
            padding: '100px',
            paddingTop: '50px',
            paddingBottom: '0px',
        },
    },
    img: {
        display: 'block',
        width: '100%',
        overflow: 'hidden'
    },
});
const images = [
    {
        url: '/img/teatro.jpg',
        title: 'Artes Escénicas',
        width: '20%',
    },
    {
        url: '/img/drawing.jpg',
        title: 'Artes Gráficas',
        width: '20%',
    },
    {
        url: '/img/coaching.jpg',
        title: 'Crecimiento Personal',
        width: '20%',
    },
    {
        url: '/img/health.jpg',
        title: 'Salud & Bienestar',
        width: '20%',
    },
    {
        url: '/img/informatics.jpg',
        title: 'Informática',
        width: '20%',
    }
];


const Home = props => {
    const { classes } = props;
    return (
        <MainLayout>            
            <HomeCarousel />
            <Typography variant="h5" style={{ marginTop: '50px', marginBottom: '30px', textAlign: 'center', paddingRight: '50px', paddingLeft: '50px'}}>
                ¿Por dónde debo empezar?
            </Typography>
            <Grid container style={{paddingRight: '50px', paddingLeft: '50px'}}>
                <Grid item xs={12} sm={4} style={{ textAlign: 'center' }}>
                    <img src="/img/list.png" style={{ width: '70px', marginTop: '20px' }} />
                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                        Crea un temario
                    </Typography>
                    <Typography variant="body2" style={{ padding: '10px' }}>
                        Define la estructura del curso,
                        <br />objetivos y temas.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} style={{ textAlign: 'center' }}>
                    <img src="/img/microfono.png" style={{ width: '70px', marginTop: '20px' }} />
                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                        Comienza a grabarte
                    </Typography>
                    <Typography variant="body2" style={{ padding: '10px' }}>
                        Te asesoramos para que no tengas problemas
                        <br />en la grabación y edición de los videos.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} style={{ textAlign: 'center' }}>
                    <img src="/img/publish.png" style={{ width: '70px', marginTop: '20px' }} />
                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                        Publica tu curso
                    </Typography>
                    <Typography variant="body2" style={{ padding: '10px' }}>
                        Te ayudamos a promocionar tu curso a través
                        <br />de nuestras redes sociales.
                    </Typography>
                </Grid>
            </Grid>
            <Grid container style={{ backgroundColor: 'rgb(2 91 158 / 3%)', marginTop: '80px' }}>
                <Grid item xs={12} sm={12} md={6}>
                    <img className={classes.img} src="/img/rocket_500.png" />
                </Grid>
                <Grid item xs={12} sm={12} md={6} className={classes.label1}>
                    <Typography variant="h4" style={{ fontWeight: 'bold' }}>
                        Posiciona tu marca personal
                    </Typography>
                    <br /><br />
                    <Typography variant="h6">
                        Conviértete en un referente en los temas que más dominas y comparte tu pasión por enseñar.
                    </Typography>
                    <br /><br />
                    <Grid container>
                        <Grid item xs={12} sm={12} md={4} style={{ textAlign: 'center' }}>
                            <img src="/img/internet.png" style={{ width: '70px', marginTop: '20px' }} />
                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                Tema Libre
                             </Typography>
                            <Typography variant="body2" style={{ padding: '10px' }}>
                                Nuestras categorías de cursos son libres, y se van renovando de acuerdo a tus necesidades.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} style={{ textAlign: 'center' }}>
                            <img src="/img/coin.png" style={{ width: '70px', marginTop: '20px' }} />
                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                Rentabilidad
                            </Typography>
                            <Typography variant="body2" style={{ padding: '10px' }}>
                                Te ofrecemos una rentabilidad muy competitiva (75%) por cada venta que consiga tu curso.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} style={{ textAlign: 'center' }}>
                            <img src="/img/benefit.png" style={{ width: '70px', marginTop: '20px' }} />
                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                Beneficios
                            </Typography>
                            <Typography variant="body2" style={{ padding: '10px' }}>
                                Te inivitamos a nuestros talleres gratuitos. Participa de nuestras campañas y semanas promocionales.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Typography variant="h5" style={{ marginTop: '50px', textAlign: 'center' }}>
                Preguntas Frecuentes
            </Typography>
            <Grid container className={classes.label2}>
                <Grid item xs={12} sm={6} style={{ textAlign: 'center' }}>
                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                        ¿Qué temas puedo enseñar?
                    </Typography>
                    <Typography variant="body2" style={{ padding: '10px' }}>
                        Los temas son libres. Sin embargo cada curso pasará por una revisión dónde se verificará autenticidad (Derechos de autor)
                        y que cumplan con nuestras políticas de contenido. Se considera inapropiado temas ofensivos, racistas, sexistas, pornográficos,
                        difamatorios o que conlleven a desinformación.
                    </Typography>
                    <br />
                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                        ¿Existe algún costo por subir un curso?
                    </Typography>
                    <Typography variant="body2" style={{ padding: '10px' }}>
                        Es totalmente gratuito. La plataforma se mantiene con las comisiones que se logren por cada venta.
                    </Typography>
                    <br />
                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                        ¿Cuánto tiempo dura un curso en la plataforma?
                    </Typography>
                    <Typography variant="body2" style={{ padding: '10px' }}>
                        Los cursos permanecerán en la plataforma mientras mantengan un flujo de ventas.
                        Si el curso no consigue ventas en 6 meses, se retirará de la plataforma previa notificación al instructor.
                    </Typography>
                    <br />                   
                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                        ¿Qué implica participar en las semanas promocionales?
                    </Typography>
                    <Typography variant="body2" style={{ padding: '10px' }}>
                        Las campañas y semanas promocionales buscan conseguir un mayor flujo de ventas.
                        Para lograr esto, muchas veces se deben aplicar descuentos sobre el precio de venta
                        que son asumidos por ambas partes, ya que esto no afecta el porcentaje de rentabilidad establecido.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} style={{ textAlign: 'center' }}>
                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                        ¿Cómo se define el precio de venta de mi curso?
                    </Typography>
                    <Typography variant="body2" style={{ padding: '10px' }}>
                        Nosotros te recomendamos un precio competitivo de acuerdo al mercado. 
                        Sin embargo la decisión final la toma el instructor.
                    </Typography>
                    <br />
                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                        ¿Cómo sé que alguien compró mi curso?
                    </Typography>
                    <Typography variant="body2" style={{ padding: '10px' }}>
                        Cada vez que se logre una venta, te llegará una notificación por correo.
                        Adicionalmente podrás tener un seguimiento detallado de las transacciones
                        y alumnos que adquirieron el curso en el panel de instructores dentro de la plataforma.
                    </Typography>
                    <br />
                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                        ¿Cuándo recibiré el pago por la venta de cursos?
                    </Typography>
                    <Typography variant="body2" style={{ padding: '10px' }}>
                        Los pagos se realizan quincenalmente con la rentabilidad acumulada por la venta de cursos.
                        Recuerda que las transacciones con tarjetas de crédito se confirman en 48 horas.
                        Antes de realizar el depósito, requerimos nos emitas un Recibo por Honarios.
                    </Typography>
                    <br />
                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                        ¿A quién le pertenece los materiales del curso?
                    </Typography>
                    <Typography variant="body2" style={{ padding: '10px' }}>
                        Todos los materiales, recursos y contenido audiovisual le pertenecen al instructor.
                        Tú decides cuándo retirarlos, o reutilizarlos para otros fines.
                    </Typography>
                </Grid>
            </Grid>
            {/*<div className={classes.layout}>
                {images.map(image => (
                    <ButtonBase
                        focusRipple
                        key={image.title}
                        className={classes.image}
                        focusVisibleClassName={classes.focusVisible}
                        style={{
                            width: image.width,
                        }}
                    >
                        <span
                            className={classes.imageSrc}
                            style={{
                                backgroundImage: `url(${image.url})`,
                            }}
                        />
                        <span className={classes.imageBackdrop} />
                        <span className={classes.imageButton}>
                            <Typography
                                component="span"
                                variant="subtitle1"
                                color="inherit"
                                className={classes.imageTitle}
                            >
                                {image.title}
                                <span className={classes.imageMarked} />
                            </Typography>
                        </span>
                    </ButtonBase>
                ))}
                        </div>*/}
            {/*<div className={classes.grid}>
                <Typography variant="h5" style={{ paddingBottom: '20px' }}>
                    Últimas Noticias
                </Typography>
                <Grid container spacing={2}>
                    <NewsCard
                        img="/img/announcement.jpg"
                        type="#conozcounmaster"
                        title="CONVOCATORIA ABIERTA PARA INSTRUCTORES"
                        description="Join the Master se encuentra en la búsqueda de instructores para el lanzamiento de la nueva plataforma de cursos virtuales. ¡Contáctanos en nuestras redes sociales!"
                    />
                    <NewsCard
                        img="/img/zoom.jpg"
                        type="zoom"
                        title="PRESENTACIÓN ZOOM PARA INSTRUCTORES"
                        description="Invitamos a instructores y empresas interasadas en dictar cursos virtuales, a la presentación vía ZOOM de la plataforma Join the Master. Preguntar por la invitación en nuestras redes sociales." />
                    <NewsCard
                        img="/img/benefits.jpg"
                        type="instructores"
                        title="BENEFICIOS PARA NUEVOS INSTRUCTORES"
                        description="Los instructores pueden subir su curso completamente gratis. Adicionalmente recibirán asesoría gratuita en edición de videos y marketing digital. ¡No dejes pasar esta oportunidad!"
                    />
                    <NewsCard
                        img="/img/students.jpg"
                        type="cursos"
                        title="HAZ QUE TU CURSO LLEGUE A MÁS PERSONAS"
                        description="Rentabiliza lo que sabes y llega a un mayor número de estudiantes a través de nuestra plataforma. Te ofrecemos las mejores herramientas para asegurar la calidad de tus cursos."
                    />
                </Grid>
                    </div>*/}
        </MainLayout>
    );
}

export default withStyles(styles)(withLocalize(Home));