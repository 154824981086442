import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';

const styles = theme => ({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
});

const DrawerButton = props => {

    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpen(!open);
    };

    const { classes } = props;
    const list = (
        <div
            className={classes.list}
            role="presentation"
            onClick={(event) => toggleDrawer(event)}
            onKeyDown={(event) => toggleDrawer(event)}
        >
            <List>
                {
                    props.options.map((option, index) => (
                        <Link key={index} href={option.link}>
                            <a style={{ color: "inherit", textDecoration: "none" }}>
                                <ListItem button key={index}>
                                    <ListItemIcon>{option.icon}</ListItemIcon>
                                    <ListItemText primary={option.title} />
                                </ListItem>
                            </a>
                        </Link>
                    ))
                }
            </List>
        </div>
    );

    return (
        <React.Fragment>
            <IconButton onClick={(event) => toggleDrawer(event)} className={classes.menuButton} color="inherit" aria-label="Menu">
                <MenuIcon />
            </IconButton>
            <Drawer anchor="left" open={open} onClose={(event) => toggleDrawer(event)}>
                {list}
            </Drawer>
        </React.Fragment>
    );
}


export default withStyles(styles)(DrawerButton);