import React from 'react';
import { Translate } from "react-localize-redux";
import * as validator from 'utils/form/validator';
import ItemForm from 'components/common/form/ItemForm';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
});

const CompanyForm = props => {

    const formParams = {
        photo: {
            type: validator.FORM_ELEMENT_INPUT_FILE,
            value: '',
            file: null,
            config: {
                label: <Translate id="photo" />,
                validation: {},
            },
            valid: false,
            touched: false,
            errorMessage: [],
            order: 1
        },
        name: {
            type: validator.FORM_ELEMENT_INPUT_TEXT,
            value: props.item && props.item.name != null ? props.item.name : '',
            config: {
                label: <Translate id="companyName" />,
                validation: {
                    required: {
                        error: <Translate id="notEmpty" data={{ name: <Translate id="companyName" /> }} />
                    },
                    minLength: {
                        value: 3,
                        error: <Translate id="notMinLength" data={{ value: 3 }} />
                    },
                    maxLength: {
                        value: 50,
                        error: <Translate id="notMaxLength" data={{ value: 250 }} />
                    }
                },
            },
            valid: false,
            touched: false,
            errorMessage: [],
            order: 2
        },        
        alias: {
            type: validator.FORM_ELEMENT_INPUT_TEXT,
            value: props.item && props.item.alias != null ? props.item.alias : '',
            config: {
                label: <Translate id="companyAlias" />,
                helper: <Translate id="companyAliasHelper" />,
                validation: {
                    required: {
                        error: <Translate id="notEmpty" data={{ name: <Translate id="companyAlias" /> }} />
                    },
                    minLength: {
                        value: 3,
                        error: <Translate id="notMinLength" data={{ value: 3 }} />
                    },
                    maxLength: {
                        value: 50,
                        error: <Translate id="notMaxLength" data={{ value: 40 }} />
                    }
                },
            },
            valid: false,
            touched: false,
            errorMessage: [],
            order: 3
        },
        description: {
            type: validator.FORM_ELEMENT_INPUT_TEXTAREA,
            value: props.item && props.item.description != null ? props.item.description : '',
            config: {
                label: <Translate id="shortDescription" />,
                validation: {
                    maxLength: {
                        value: 50,
                        error: <Translate id="notMaxLength" data={{ value: 500 }} />
                    }
                },
            },
            valid: false,
            touched: false,
            errorMessage: [],
            order: 4
        },        
        location: {
            type: validator.FORM_ELEMENT_INPUT_TEXT,
            value: props.item && props.item.location != null ? props.item.location : '',
            config: {
                label: <Translate id="location" />,
                validation: {
                    maxLength: {
                        value: 50,
                        error: <Translate id="notMaxLength" data={{ value: 200 }} />
                    }
                },
            },
            valid: false,
            touched: false,
            errorMessage: [],
            order: 5
        },
        facebook: {
            type: validator.FORM_ELEMENT_INPUT_TEXT,
            value: props.item && props.item.facebook != null ? props.item.facebook : '',
            config: {
                label: 'Facebook',
                validation: {
                    maxLength: {
                        value: 50,
                        error: <Translate id="notMaxLength" data={{ value: 100 }} />
                    }
                },
            },
            valid: false,
            touched: false,
            errorMessage: [],
            order: 6
        },
        twitter: {
            type: validator.FORM_ELEMENT_INPUT_TEXT,
            value: props.item && props.item.twitter != null ? props.item.twitter : '',
            config: {
                label: 'Twitter',
                validation: {
                    maxLength: {
                        value: 50,
                        error: <Translate id="notMaxLength" data={{ value: 100 }} />
                    }
                },
            },
            valid: false,
            touched: false,
            errorMessage: [],
            order: 7
        },
        instagram: {
            type: validator.FORM_ELEMENT_INPUT_TEXT,
            value: props.item && props.item.instagram != null ? props.item.instagram : '',
            config: {
                label: 'Instagram',
                validation: {
                    maxLength: {
                        value: 50,
                        error: <Translate id="notMaxLength" data={{ value: 100 }} />
                    }
                },
            },
            valid: false,
            touched: false,
            errorMessage: [],
            order: 8
        },
        linkedin: {
            type: validator.FORM_ELEMENT_INPUT_TEXT,
            value: props.item && props.item.linkedin != null ? props.item.linkedin : '',
            config: {
                label: 'Linkedin',
                validation: {
                    maxLength: {
                        value: 50,
                        error: <Translate id="notMaxLength" data={{ value: 100 }} />
                    }
                },
            },
            valid: false,
            touched: false,
            errorMessage: [],
            order: 9
        },
    };

    const { classes } = props;

    return (
        <ItemForm
            formParams={formParams}
            onValidSubmit={props.onValidSubmit}
            formError={props.submitError}
        />
    );
}

export default withStyles(styles)(CompanyForm);