import React from 'react';
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import HomeIcon from '@material-ui/icons/Home';
import UpdateIcon from '@material-ui/icons/Create';
import PeopleIcon from '@material-ui/icons/People';
import CreateCourseIcon from '@material-ui/icons/OndemandVideo';
import { Translate } from "react-localize-redux";

const styles = theme => ({
});

const CompanyButtons = props => {
    const { classes } = props;
    return (
        <BottomNavigation
            value={props.tabValue}
            onChange={(event, newValue) => {
                if (newValue == 0) {
                    props.history.push('/company/' + props.item.alias);
                } else if (newValue == 1) {
                    props.history.push('/company/update/' + props.item.id);
                } else if (newValue == 2) {
                    props.history.push('/company/members/' + props.item.id);
                }
            }}
            showLabels
            style={{ width: '100%' }}
        >
            <BottomNavigationAction label={<Translate id="home" />} icon={<HomeIcon />} />
            <BottomNavigationAction label={<Translate id="profileEdit" />} icon={<UpdateIcon />} />
            {props.isOwner ? <BottomNavigationAction label={<Translate id="membersEdit" />} icon={<PeopleIcon />} /> : null}
        </BottomNavigation>
    );
}

export default withStyles(styles)(withRouter(CompanyButtons));