export const DEFAULT_PAGE_TITLE = 'Join the Master - Bienvenido';
export const DEFAULT_MATERIAL_UI_THEME = {
    palette: {
        primary: {
            main: '#ffa726',
            light: '#ffd95b',
            dark: '#c77800',
            contrastText: '#000000',
        },
        secondary: {
            main: '#1976d2',
            light: '#63a4ff',            
            dark: '#004ba0',
            contrastText: '#ffffff',
        },
    },
    typography: {
      useNextVariants: true,
      fontFamily: `"Kumbh Sans", "Helvetica", "Arial", sans-serif`,
    },
    overrides: {
        MuiCardContent: {
          root: {
            "&:last-child": {
              paddingBottom: '16px'
            }
          }
        }
      }
};
export const WS_TOKEN='AIzaSyC059d9pW1pF4gID3mNfnhMYPLBEKycLXk';
export const WS_SIGNUP = 'https://www.googleapis.com/identitytoolkit/v3/relyingparty/signupNewUser?key='+WS_TOKEN;
export const WS_SIGNIN = 'https://www.googleapis.com/identitytoolkit/v3/relyingparty/verifyPassword?key='+WS_TOKEN;
export const WS_SET_PROFILE = 'https://www.googleapis.com/identitytoolkit/v3/relyingparty/setAccountInfo?key='+WS_TOKEN;
export const WS_GET_PROFILE = 'https://www.googleapis.com/identitytoolkit/v3/relyingparty/getAccountInfo?key='+WS_TOKEN;
export const WS_EMAIL_VERIFICATION = 'https://www.googleapis.com/identitytoolkit/v3/relyingparty/getOobConfirmationCode?key='+WS_TOKEN;
export const WS_PASS_RECOVERY = 'https://www.googleapis.com/identitytoolkit/v3/relyingparty/getOobConfirmationCode?key='+WS_TOKEN;
export const WS_CHANGE_PASS = 'https://www.googleapis.com/identitytoolkit/v3/relyingparty/setAccountInfo?key='+WS_TOKEN;
export const WS_BACKEND = 'http://localhost:8080/jtm-ws/'+WS_TOKEN;
export const ROLE_APP_ADMIN = 'Kwv75dPe42jhBRGyh1Y3';
export const ROLE_APP_EDITOR = 'ivfCMihDGIfIV3C3PEPw';
export const ROLE_APP_VIEWER = '0Aot9zy6ENn25ZorkK7u';
export const ROLE_APP_USER = 'dXOHXDjQJnlZmvuxwve4';
export const ROLE_COM_OWNER = 'rlNy6E15aIKphBY32s2P';
export const ROLE_COM_ADMIN = 'ybGqYY5EEB6s6IOorIJ9';
export const ROLE_COM_EDITOR = 'nMPTev7EmNztTSi91Jqy';
export const ROLE_COM_INSTRUCTOR = 'hKFfSXEYhusoOuaZQvg0';
export const ROLE_CUR_OWNER = 'vcWokWAYp003DH03Fnr5';
export const ROLE_CUR_INSTRUCTOR = 'yW4GPYl2kQyb792LoEEi';
export const STATUS_CUR_DRAFT = '0';
export const STATUS_CUR_PENDING_REVIEW = '1';
export const STATUS_CUR_PUBLISHED = '2';
export const STATUS_CUR_ARCHIVED = '3';