import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import * as appParams from 'config/params';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    avatar: {
        width: theme.spacing(5),
        height: theme.spacing(5),
        margin: 'auto'
    }
});

const UserProfileCompanies = props => {

    const { classes } = props;
    return (
        props.list.length > 0 ?
            <React.Fragment>
                <Typography variant="h6" style={{ paddingBottom: '15px' }}>
                    Empresas
                </Typography>
                <Grid container alignItems="center" spacing={3} style={{ marginBottom: '10px' }}>
                    {props.list.map((company, index) => (
                        <Grid key={company.id} item xs={6} sm={6} md={3} lg={3} style={{ textAlign: 'center' }}>
                            <Link to={"/company/" + company.alias} style={{ textDecoration: 'none', color: 'inherit' }}>
                                <Avatar
                                    src={company.photo ? company.photo : null} className={classes.avatar}
                                />
                                <Typography gutterBottom variant="caption" >
                                    {company.name}
                                </Typography>
                            </Link>
                        </Grid>
                    ))}
                </Grid>
            </React.Fragment> : null
    );
}

export default withStyles(styles)(UserProfileCompanies);