import * as action from 'state/actions';

export const openDialog = (dialogType) => {
    return (dispatch) => {
        dispatch(action.APP_OPEN_DIALOG({ dialogType }));
    };
};

export const closeDialog = () => {
    return (dispatch) => {
        dispatch(action.APP_CLOSE_DIALOG());
    };
};

export const showAlert = (message, alertType) => {
    return (dispatch) => {
        dispatch(action.APP_SHOW_ALERT({ message, alertType }));
    };
};

export const hideAlert = () => {
    return (dispatch) => {
        dispatch(action.APP_HIDE_ALERT());
    };
};