import React from 'react';
import { useParams, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import * as actions from 'state/actions/index';
import MainLayout from 'components/common/layout/MainLayout';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CompanySummary from './box/CompanySummary';
import CompanyButtons from './box/CompanyButtons';
import CompanyForm from './CompanyForm';

const styles = theme => ({
    root: {
        flexGrow: 1,
        margin: 20,
        marginTop: theme.spacing(10)
    },
    spinner: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: theme.spacing(4)
    }
});

const CompanyUpdate = props => {

    const { companyId } = useParams();

    React.useEffect(() => {
        props.gFetchItem(companyId);
    }, [companyId]);

    const onValidSubmit = (formData) => {
        formData["companyId"] = companyId;
        formData["oldPhoto"] = props.gItem.photo;
        formData["oldAlias"] = props.gItem.alias;
        props.gSubmitItem(formData);
    }

    const { classes } = props;

    return (
        <MainLayout>
            {props.gItemError ? <Redirect to="/not-found" /> : null}
            <div className={classes.root}>
                {props.gItemLoading ?
                    (<div className={classes.spinner}><CircularProgress className={classes.progress} size={50} /></div>) :
                    (<Grid container spacing={2}>                        
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                            <CompanySummary item={props.gItem} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={9} lg={9}>
                            <Grid container spacing={2}>                                
                                {props.gIsAuth && (props.gUserId == props.gItem.owner.id || (props.gItem.editors && props.gItem.editors.includes(props.gUserId))) ?
                                    <Grid item xs={12}>
                                        <Card>
                                            <CardContent style={{ padding: '0' }}>
                                                <CompanyButtons item={props.gItem} isOwner={props.gUserId == props.gItem.owner.id} tabValue={1} />
                                            </CardContent>
                                        </Card>
                                    </Grid> : <Redirect to="/" />}
                                <Grid item xs={12}>
                                    <Card>
                                        <CardContent>
                                            {props.gItemLoading || props.gSubmitLoading ?
                                                (<div className={classes.spinner}><CircularProgress className={classes.progress} size={50} /></div>) :
                                                <CompanyForm
                                                    item={props.gItem}
                                                    onValidSubmit={onValidSubmit}
                                                    submitError={props.gSubmitError}
                                                />
                                            }
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>)}
            </div>
        </MainLayout>
    );
}

const mapStateToProps = state => {
    return {
        gIsAuth: !!state.auth.user.id,
        gUserId: state.auth.user.id,
        gItem: state.company.item,
        gItemLoading: !state.company.item,
        gItemError: state.company.itemError,
        gSubmitLoading: state.company.submitLoading,
        gSubmitError: state.company.submitError,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        gFetchItem: (companyId) => dispatch(actions.companyFetchItemByCompanyId(companyId)),
        gSubmitItem: (formData) => dispatch(actions.companySubmitItem(formData)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CompanyUpdate));