import React from 'react';
import * as appParams from 'config/params';
import { Translate } from "react-localize-redux";
import { Redirect, Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Chip from '@material-ui/core/Chip';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Image from 'material-ui-image';

const styles = theme => ({
    cardTitle: {
        lineHeight: 1.5,
    },
    cardTitleBox: {
        //backgroundColor: 'black',
        paddingBottom: 0,
    },
    cardTitleBoxLink: {
        textDecoration: 'none',
    },
    actions: {
        display: 'flex',
        //backgroundColor: 'black'
    },
    price: {
        paddingLeft: '10px'
    },
    price2: {
        textDecoration: 'line-through',
        color: '#cecece',
        paddingLeft: '10px',
    },
});

const CourseCard = props => {
    const { classes } = props;
    let status = '';
    switch (props.item.status + '') {
        case appParams.STATUS_CUR_DRAFT:
            status = <Chip label="Borrador" />;
            break;
        case appParams.STATUS_CUR_PENDING_REVIEW:
            status = <Chip label="En Revisión" style={{ backgroundColor: 'blue', color: 'white' }} />;
            break;
        case appParams.STATUS_CUR_PUBLISHED:
            status = <Chip label="Publicado" style={{ backgroundColor: 'green', color: 'white' }} />;
            break;
        case appParams.STATUS_CUR_ARCHIVED:
            status = <Chip label="Archivado" />;
            break;
    }
    return <Grid item xs={12} sm={6} md={4} lg={4}>
        <Card className={classes.card} variant="outlined">
            <Link to={"/course/" + props.item.id} style={{ color: "inherit", textDecoration: "none" }}>
                <CardContent style={{ padding: 0 }}>
                    <Image
                        src={props.item.photo ? props.item.photo : ''}
                        aspectRatio={(16 / 9)}
                    />
                </CardContent>
                <CardContent className={classes.cardTitleBox}>
                    <div style={{ paddingBottom: '10px' }}>
                        {status}
                    </div>
                    <Typography variant="h6" style={{ fontSize: '1rem', fontWeight: 'bolder' }}>
                        {props.item.name}
                    </Typography>
                    {props.item.description && props.item.description.trim() != "" ?
                        <Typography style={{ fontSize: '13px', paddingTop: '10px' }}>
                            {props.item.description}
                        </Typography> : null
                    }
                </CardContent>
            </Link>
            <CardActions className={classes.actions} disableSpacing>
                {props.item.price > 0 ?
                    <Grid item xs={12} className={classes.price}>
                        <Typography display="inline" variant="h6" style={{ fontSize: '1rem' }} className={classes.price1}>
                            S/. {parseFloat(props.item.price).toFixed(2)}
                        </Typography>
                        <Typography display="inline" variant="subtitle2" className={classes.price2}>
                            S/. {parseFloat(props.item.price + 10).toFixed(2)}
                        </Typography>
                    </Grid> :
                    <Grid item xs={12} className={classes.price}>
                        <Typography display="inline" variant="h6" style={{ fontSize: '1rem' }} className={classes.price1}>
                            <Translate id="free" />
                        </Typography>
                    </Grid>
                }
                <IconButton aria-label="Add to favorites" className={classes.expand}>
                    <FavoriteIcon />
                </IconButton>
            </CardActions>
        </Card>
    </Grid>;
}

export default withStyles(styles)(CourseCard);