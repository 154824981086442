import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CategoryIcon from '@material-ui/icons/Category';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/core/styles';
import DropDownButton from 'components/common/button/DropDownButton';
import FloatingActionButton from 'components/common/button/FloatingActionButton';
import HeaderDialog from 'components/common/dialog/HeaderDialog';
import DrawerButton from 'components/common/drawer/DrawerButton';
import LanguageDialog from 'components/common/dialog/LanguageDialog';
import AccountIcon from '@material-ui/icons/AccountCircle';
import NotificationIcon from 'mdi-material-ui/BellOutline';
import CartIcon from 'mdi-material-ui/CartOutline';
import SignInIcon from 'mdi-material-ui/Login';
import SignUpIcon from 'mdi-material-ui/AccountPlus';
import SignOutIcon from 'mdi-material-ui/Logout';
import ProfileIcon from '@material-ui/icons/Person';
import KeyIcon from 'mdi-material-ui/Key';
import HelpIcon from 'mdi-material-ui/Lifebuoy';
import LanguageIcon from '@material-ui/icons/Language';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import * as actions from 'state/actions/index';
import { Translate } from "react-localize-redux";
import Alert from 'components/common/alert/Alert';
import * as appParams from 'config/params';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import CreateCourseIcon from '@material-ui/icons/OndemandVideo';

const styles = theme => ({
    root: {
        flexGrow: 1
    },
    background: {
        backgroundColor: 'white'
    },
    flex: {
        flex: 1,
        // textAlign: 'center'
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    logo: {
        width: '120px',
        marginTop: '12px'
    },
    optionMargin: {
        marginRight: 5,
    },
    badge: {
        //top: 0,
        width: 20,
        height: 20
    },
    searchBox: {
        padding: '0px 4px',
        display: 'flex',
        position: 'fixed',
        marginLeft: '150px',
        marginTop: '-45px',
        width: '300px',
    },
    searchBoxInput: {
        marginLeft: 8,
        flex: 1,
    },
    searchBoxIconButton: {
        padding: 10,
    },
});

const Header = props => {

    const handleProfileClick = () => {
        props.history.push('/user/' + props.gUsername);
    }

    const handleDialogClick = (dialogType) => {
        props.gOpenDialog(dialogType);
    }

    const handleLogout = (dialogType) => {
        props.gLogout();
        props.history.push('/');
    }

    const { classes } = props;
    let menuOptions = [];
    if (props.gRole != appParams.ROLE_APP_USER) {
        menuOptions = [
            {
                title: <Translate id="category" />,
                icon: <CategoryIcon />,
                link: "/category/list",
            }
        ];
    }
    const authOptions = [
        {
            title: <Translate id="signIn" />,
            icon: <SignInIcon />,
            onClick: () => handleDialogClick('signIn'),
        },
        {
            title: <Translate id="signUp" />,
            icon: <SignUpIcon />,
            onClick: () => handleDialogClick('signUp'),
        }
    ];
    const profileOptions = [
        {
            title: <Translate id="profile" />,
            icon: <ProfileIcon />,
            onClick: () => handleProfileClick(),
        },
        {
            title: <Translate id="password" />,
            icon: <KeyIcon />,
            onClick: () => handleDialogClick('changePass'),
        },
        {
            title: <Translate id="help" />,
            icon: <HelpIcon />,
            onClick: () => null,
        },
        {
            title: <Translate id="logOut" />,
            icon: <SignOutIcon />,
            onClick: () => handleLogout(),
        }
    ];
    const avatar = () => {
        if (props.gFullName) {
            const fullname = props.gFullName.split(' ');
            const initials = (fullname[0] != null ? fullname[0].charAt(0) : '') + (fullname[1] != null ? fullname[1].charAt(0) : '');
            return <Avatar>{initials.toUpperCase()}</Avatar>;
        }
        return null;
    }
    return (
        <div className={classes.root}>
            <AppBar position="fixed">
                <Toolbar className={classes.background}>
                    <div className={classes.flex}>
                        <Typography className={classes.flex} variant="h6" color="inherit">
                            <Link to="/">
                                <img src="/img/logo-jtm.png" className={classes.logo} />
                            </Link>
                        </Typography>
                        {/*<Hidden smDown>
                            <Paper className={classes.searchBox} elevation={0}>
                                <Translate>{({ translate }) => <InputBase className={classes.searchBoxInput} placeholder={translate('searchPlaceholder')} />}</Translate>
                                <IconButton className={classes.searchBoxIconButton} aria-label="Search">
                                    <SearchIcon />
                                </IconButton>
                            </Paper>
                        </Hidden>*/}
                    </div>
                    {/*props.gIsAuth ? (
                        <React.Fragment>
                            <Hidden smDown>
                                <Link to="/course/create" style={{ color: "inherit", textDecoration: "none" }}>
                                    <Button
                                        variant="outlined"
                                        color="default"
                                        style={{ marginRight: '15px' }}
                                        startIcon={<CreateCourseIcon />}
                                    >
                                        <Translate id="createCourse" />
                                    </Button>
                                </Link>
                            </Hidden>
                            <Hidden mdUp>
                                <Link to="/course/create" style={{ color: "inherit", textDecoration: "none" }}>
                                    <IconButton>
                                        <CreateCourseIcon />
                                    </IconButton>
                                </Link>
                            </Hidden>
                            <IconButton className={classes.optionMargin}>
                                <Badge badgeContent={0} color="secondary" classes={{ badge: classes.badge }}>
                                    <NotificationIcon />
                                </Badge>
                            </IconButton>
                            <DropDownButton icon={avatar()} options={profileOptions} />
                        </React.Fragment>
                    ) :
                        (<div>
                            <Hidden smDown>
                                {authOptions.map((option, index) => (
                                    <Button key={index} color="inherit" onClick={option.onClick}>{option.title}</Button>
                                ))}
                            </Hidden>
                            <Hidden mdUp>
                                <DropDownButton icon={<AccountIcon />} options={authOptions} />
                            </Hidden>
                                </div>)*/}
                </Toolbar>
                <HeaderDialog />
                <LanguageDialog />
            </AppBar>
            {props.gAlertIsOpen ? (
                <Alert
                    variant={props.gAlertType}
                    message={props.gAlertMsg}
                    onClose={props.gHideAlert}
                    open={true}
                />) : null}
        </div>
    );
}

const mapStateToProps = state => {
    return {
        gIsAuth: !!state.auth.user.id,
        gRole: state.auth.sessionRole,
        gFullName: state.auth.user.fullName,
        gUsername: state.auth.user.username,
        gDialogType: state.app.dialogType,
        gAlertIsOpen: state.app.alertIsOpen,
        gAlertMsg: state.app.alertMsg,
        gAlertType: state.app.alertType
    };
};

const mapDispatchToProps = dispatch => {
    return {
        gOpenDialog: (dialogType) => dispatch(actions.openDialog(dialogType)),
        gLogout: () => dispatch(actions.authLogout()),
        gHideAlert: () => dispatch(actions.hideAlert())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(Header)));