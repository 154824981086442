import React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import AdminRoute from './AdminRoute';
import NotFound from './NotFound';
import Home from 'components/home/Home';
import UserProfileItem from 'components/user/UserProfileItem';
import UserProfileUpdate from 'components/user/UserProfileUpdate';
import CompanyCreate from 'components/company/CompanyCreate';
import CompanyItem from 'components/company/CompanyItem';
import CompanyUpdate from 'components/company/CompanyUpdate';
import CompanyMembers from 'components/company/CompanyMembers';
import CourseCreate from 'components/course/CourseCreate';

const RouterComponent = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <PrivateRoute exact path="/user/profile/update" component={UserProfileUpdate} />
        <Route exact path="/user/:username" component={UserProfileItem} />
        <PrivateRoute exact path="/company/members/:companyId" component={CompanyMembers} />
        <PrivateRoute exact path="/company/update/:companyId" component={CompanyUpdate} />
        <PrivateRoute exact path="/company/profile/create" component={CompanyCreate} />
        <PrivateRoute exact path="/course/create" component={CourseCreate} />
        <Route exact path="/company/:alias" component={CompanyItem} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default RouterComponent;
