import { createReducer } from 'redux-act';
import * as action from 'state/actions';

const initialState = {
  user: {
    id: null,
    isAdmin: null
  },
  notifyEmail: null,
  loading: false,
  error: null,
  restoring: false,
  restoringError: null,
  restoredPassword: false,
  changedPassword: false
};

export const authReducer = createReducer(
  {
    [action.AUTH_SIGN_IN_INIT]: () => ({
      ...initialState,
      loading: true
    }),
    [action.AUTH_SIGN_IN_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error
    }),
    [action.AUTH_SIGN_UP_INIT]: () => ({
      ...initialState,
      loading: true
    }),
    [action.AUTH_SIGN_UP_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error
    }),
    [action.AUTH_SIGN_UP_SUCCESS]: (state, payload) => ({
      ...initialState,
      notifyEmail: payload.email,
      loading: false,
      error: null
    }),
    [action.AUTH_FETCH_USER_DATA_INIT]: () => ({
      ...initialState,
      loading: true
    }),
    [action.AUTH_FETCH_USER_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      user: {
        id: payload.id,
        isAdmin: payload.isAdmin,
        email: payload.email,
        fullName: payload.fullName,
        username: payload.username,
        photo: payload.photo,
        createdAt: payload.createdAt
      },
      loading: false,
      error: null
    }),
    [action.AUTH_FETCH_USER_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error
    }),
    [action.AUTH_LOGOUT_INIT]: () => ({ ...initialState }),
    [action.AUTH_LOGOUT_SUCCESS]: state => ({ ...state }),

    [action.AUTH_RESET_PASSWORD_INIT]: () => ({
      ...initialState,
      loading: true
    }),
    [action.AUTH_RESET_PASSWORD_SUCCESS]: (state, payload) => ({
      ...state,
      loading: false,
      error: null,
      notifyEmail: payload.email
    }),
    [action.AUTH_RESET_PASSWORD_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error
    }),
    [action.AUTH_CHANGE_PASSWORD_INIT]: state => ({
      ...state,
      loading: true
    }),
    [action.AUTH_CHANGE_PASSWORD_SUCCESS]: (state, payload) => ({
      ...state,
      loading: false,
      error: null,
      notifyEmail: payload.email
    }),
    [action.AUTH_CHANGE_PASSWORD_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error
    }),
    [action.AUTH_CLEAN_UP]: state => ({
      ...state,
      error: null,
      notifyEmail: null,
      changedPassword: false
    }),
    [action.AUTH_RESTORE_SESSION_INIT]: state => ({ ...state, restoring: true }),
    [action.AUTH_RESTORE_SESSION_SUCCESS]: state => ({
      ...state,
      restoring: false,
      restoringError: null
    }),
    [action.AUTH_RESTORE_SESSION_FAIL]: state => ({
      ...state,
      restoring: false,
      restoringError: true
    }),
    [action.AUTH_UPDATE_USER_DATA]: (state, payload) => ({
      ...state,
      user: {
        id: payload.id || state.user.id,
        isAdmin: payload.isAdmin || state.user.isAdmin,
        email: payload.email || state.user.email,
        fullName: payload.fullName,
        username: payload.username,
        photo: payload.photo || state.user.photo,
        createdAt: payload.createdAt || state.user.createdAt
      }
    }), 

    // Not Used ??
    [action.AUTH_SET_PASSWORD_INIT]: state => ({ ...state, loading: true }),
    [action.AUTH_SET_PASSWORD_SUCCESS]: state => ({
      ...state,
      loading: false,
      error: null
    }),
    [action.AUTH_SET_PASSWORD_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error
    }),       
    [action.AUTH_PROVIDER_INIT]: state => ({
      ...state,
      loading: true
    }),
    [action.AUTH_PROVIDER_SUCCESS]: (state, payload) => ({
      ...state,
      userData: {
        id: payload.id,
        isAdmin: payload.isAdmin,
        email: payload.email,
        name: payload.name,
        username: payload.username,
        logoUrl: payload.logoUrl,
        createdAt: payload.createdAt
      },
      error: null,
      loading: false
    }),
    [action.AUTH_PROVIDER_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error
    })
  },
  initialState
);
