import { createReducer } from 'redux-act';
import * as action from 'state/actions';

const initialState = {
    dialogType: null,
    formLoading: false,
    formError: null,
    alertIsOpen: false,
    alertMsg: null,
    alertType: null
};

export const appReducer = createReducer(
    {
        [action.APP_OPEN_DIALOG]: (state, action) => ({
            ...state,
            dialogType: action.dialogType
        }),

        [action.APP_CLOSE_DIALOG]: (state, action) => ({
            ...state,
            dialogType: null
        }),

        [action.APP_SUBMIT_START]: (state, action) => ({
            ...state,
            formError: null,
            formLoading: true
        }),

        [action.APP_SUBMIT_SUCCESS]: (state, action) => ({
            ...state,
            formError: null,
            formLoading: false
        }),

        [action.APP_SUBMIT_FAIL]: (state, action) => ({
            ...state,
            formError: action.error,
            formLoading: false
        }),

        [action.APP_SUBMIT_END]: (state, action) => ({
            ...state,
            formError: null,
            formLoading: false
        }),

        [action.APP_SHOW_ALERT]: (state, action) => ({
            ...state,
            alertIsOpen: true,
            alertMsg: action.message,
            alertType: action.alertType
        }),

        [action.APP_HIDE_ALERT]: (state, action) => ({
            ...state,
            alertIsOpen: false,
            alertMsg: null,
            alertType: null
        }),
    },
    initialState
);