import React from 'react';
import * as appParams from 'config/params';
import * as actionTypes from './actionTypes';
import { Translate } from "react-localize-redux";
import axios from 'axios';
import * as company from './company';
import * as course from './course';
import * as action from 'state/actions';
import firebase from 'firebase.js';

export function userFetchItemByUsername(username) {
    return async (dispatch) => {
        dispatch(action.USER_ITEM_START());
        let user;
        try {
            await firebase.firestore().collection('users').where("username", "==", username).limit(1).get()
                .then((query) => {
                    query.forEach(function (doc) {
                        user = { id: doc.id, ...doc.data() };
                    });
                });
        } catch (error) {
            return dispatch(action.USER_ITEM_FAIL({ error: error }));
        }
        if (!user) {
            return dispatch(action.USER_ITEM_FAIL({ error: "NOT_FOUND" }));
        }
        dispatch(action.companyFetchListByUserId(user.id));
        dispatch(action.courseFetchListByUserId(user.id));
        return dispatch(action.USER_ITEM_SUCCESS({ data: user }));
    };
};

export function userFetchItemByUserId() {
    return async (dispatch, getState) => {
        dispatch(action.USER_ITEM_START());
        const { id } = getState().auth.user;
        let user;
        try {
            await firebase.firestore().collection('users').doc(id).get()
                .then((doc) => {
                    user = { id: doc.id, ...doc.data() };
                });
        } catch (error) {
            return dispatch(action.USER_ITEM_FAIL({ error: error }));
        }
        if (!user) {
            return dispatch(action.USER_ITEM_FAIL({ error: "NOT_FOUND" }));
        }
        return dispatch(action.USER_ITEM_SUCCESS({ data: user }));
    };
};

export function userSubmitItem(formData) {
    return async (dispatch, getState) => {
        dispatch(action.USER_SUBMIT_START());
        const { id, username, photo } = getState().auth.user;
        // Validate username
        if (formData.username != username) {
            let user;
            try {
                await firebase.firestore().collection('users').where("username", "==", formData.username).limit(1).get()
                    .then((query) => {
                        query.forEach(function (doc) {
                            user = { id: doc.id, ...doc.data() };
                        });
                    });
            } catch (error) {
                dispatch(showAlert('', 'error'));
                return dispatch(action.USER_SUBMIT_FAIL({ error: error }));
            }
            if (user) {
                dispatch(showAlert('ALREADY_EXISTS', 'error'));
                return dispatch(action.USER_SUBMIT_FAIL({ error: 'ALREADY_EXISTS' }));
            }
        }
        // Delete old photo & upload new photo
        let deletePhotoTask;
        let uploadPhotoTask;
        let newPhotoUrl = photo;
        let imagePreviewUrl = null;
        if (formData.photo) {
            newPhotoUrl = getPhotoUrl(id, formData.photo);
            deletePhotoTask = photo && deletePhoto(photo);
            uploadPhotoTask = uploadPhoto(id, formData.photo);
            imagePreviewUrl = URL.createObjectURL(formData.photo);
        }
        // Update user data
        const userData = {
            fullName: formData.fullName,
            username: formData.username,
            description: formData.description,
            workExperience: formData.workExperience,
            academicExperience: formData.academicExperience,
            location: formData.location,
            facebook: formData.facebook,
            twitter: formData.twitter,
            instagram: formData.instagram,
            linkedin: formData.linkedin,
            photo: newPhotoUrl,
        };
        const updateUserDbTask = firebase.firestore().collection('users').doc(id).update(userData);
        // Execute all tasks
        try {
            await Promise.all([deletePhotoTask, uploadPhotoTask, updateUserDbTask]);
        } catch (error) {
            dispatch(showAlert('', 'error'));
            return dispatch(action.USER_SUBMIT_FAIL({ error: error }));
        }
        dispatch(showAlert('UPDATED', 'success'));
        dispatch(userFetchItemByUserId());
        dispatch(action.USER_SUBMIT_SUCCESS({ photo: imagePreviewUrl }));
        return dispatch(action.AUTH_UPDATE_USER_DATA({ ...userData, id }));
    }
};

export function showAlert(message, alertType) {
    return async (dispatch, getState) => {
        let msj = '';
        switch (message) {
            case 'CREATED':
                msj = <Translate id="successCreated" data={{ name: <Translate id="user" /> }} />;
                break;
            case 'UPDATED':
                msj = <Translate id="successUpdated" data={{ name: <Translate id="user" /> }} />;
                break;
            case 'DELETED':
                msj = <Translate id="successDeleted" data={{ name: <Translate id="user" /> }} />;
                break;
            case 'CHILD_CONSTRAINT':
                msj = <Translate id="childConstraint" data={{ name: <Translate id="user" /> }} />;
                break;
            case 'NOT_FOUND':
                msj = <Translate id="notFound" data={{ name: <Translate id="user" /> }} />;
                break;
            case 'ALREADY_EXISTS':
                msj = <Translate id="alreadyExists" data={{ name: <Translate id="user" /> }} />;
                break;
            default:
                msj = alertType == "error" ? <Translate id="error" /> : "";
                break;
        }
        dispatch(action.APP_SHOW_ALERT({ message: msj, alertType: alertType }));
    }
};

const deletePhoto = (oldPhoto) => {
    if (!oldPhoto.includes('firebasestorage')) {
        return null;
    }
    const photoPath = oldPhoto.split('users%2F').pop().split('?alt=media').shift();
    return firebase.storage().ref(`users/${photoPath}`).delete();
};

const uploadPhoto = (uid, file) => {
    const storageRef = firebase.storage().ref();
    const fileExtension = file.name.split('.').pop();
    const fileName = `${uid}.${fileExtension}`;
    return storageRef.child(`users/${fileName}`).put(file);
};

const getPhotoUrl = (uid, file) => {
    const fileExtension = file.name.split('.').pop();
    const bucketUrl = `${process.env.REACT_APP_FIRE_BASE_STORAGE_API}`;
    return `${bucketUrl}/o/users%2F${uid}_200x200.${fileExtension}?alt=media`;
};