import React from 'react';
import * as validator from 'utils/form/validator';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { Translate } from "react-localize-redux";
import PhotoIcon from '@material-ui/icons/Photo';

/*
Props:
    key (Mandatory): String
    elementType (Mandatory): String
    elementValue (Mandatory)
    labelName (Mandatory): String
    invalid (Mandatory): Boolean
    shouldValidate (Mandatory): Boolean
    touched (Mandatory): Boolean
    errorMessage (Mandatory): Array[]
    onChange (Mandatory): Function()
    isVisible (Optional): Boolean (Default: true)
    isFullWidth (Optional): Boolean (Default: true)
*/

const styles = theme => ({
    margin: {
        marginBottom: theme.spacing(2),
    },
    marginButtons: {
        textAlign: 'center'
    },
    size: {
        width: 40,
        height: 40,
    },
    sizeIcon: {
        fontSize: 20,
    },
    label: {
        fontSize: 13,
    },
    button: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
    spanLine: {
        display: 'block'
    }
});

const FormElement = props => {

    const [showPassword, setShowPassword] = React.useState(false);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const { classes } = props;
    const isFullWidth = props.isFullWidth ? props.isFullWidth : true;
    let hasError = false;
    if (props.invalid && props.shouldValidate && props.touched) {
        hasError = true;
    }
    if (props.isVisible) {
        switch (props.elementType) {
            case validator.FORM_ELEMENT_INPUT_TEXT:
                return (<FormControl fullWidth={isFullWidth} className={classes.margin} error={hasError} aria-describedby={"error-" + props.key}>
                    <InputLabel htmlFor={props.key}>{props.labelName}</InputLabel>
                    <Input
                        id={props.key}
                        value={props.elementValue}
                        onChange={props.onChange}
                    />
                    {hasError ? (
                        <FormHelperText id={"error-" + props.key}>
                            {props.errorMessage.map((error, index) => (
                                <span key={index} className={classes.spanLine}>{error}</span>
                            ))}
                        </FormHelperText>
                    ) : props.helperText ?
                            (<FormHelperText id={"help-" + props.key}>
                                {props.helperText}
                            </FormHelperText>) : null
                    }
                </FormControl>);
            case validator.FORM_ELEMENT_INPUT_EMAIL:
                return (<FormControl fullWidth={isFullWidth} className={classes.margin} error={hasError} aria-describedby={"error-" + props.key}>
                    <InputLabel htmlFor={props.key}>{props.labelName}</InputLabel>
                    <Input
                        id={props.key}
                        type="email"
                        value={props.elementValue}
                        onChange={props.onChange}
                    />
                    {hasError ? (
                        <FormHelperText id={"error-" + props.key}>
                            {props.errorMessage.map((error, index) => (
                                <span key={index} className={classes.spanLine}>{error}</span>
                            ))}
                        </FormHelperText>
                    ) : null}
                </FormControl>);
            case validator.FORM_ELEMENT_INPUT_PASSWORD:
                return (<FormControl fullWidth={isFullWidth} className={classes.margin} error={hasError} aria-describedby={"error-" + props.key}>
                    <InputLabel htmlFor={props.key}>{props.labelName}</InputLabel>
                    <Input
                        id={props.key}
                        type={showPassword ? 'text' : 'password'}
                        value={props.elementValue}
                        onChange={props.onChange}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="Toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    {hasError ? (
                        <FormHelperText id={"error-" + props.key}>
                            {props.errorMessage.map((error, index) => (
                                <span key={index} className={classes.spanLine}>{error}</span>
                            ))}
                        </FormHelperText>
                    ) : null}
                </FormControl>);
            case validator.FORM_ELEMENT_CHECKBOX:
                return (
                    <FormControl fullWidth={isFullWidth} error={hasError} aria-describedby={"error-" + props.key}>
                        <FormControlLabel classes={{ label: classes.label }}
                            control={
                                <Checkbox
                                    id={props.key}
                                    checked={props.elementValue}
                                    onChange={props.onChange}
                                    color="primary"
                                    className={classes.size}
                                    icon={<CheckBoxOutlineBlankIcon className={classes.sizeIcon} />}
                                    checkedIcon={<CheckBoxIcon className={classes.sizeIcon} />}
                                />
                            }
                            label={props.labelName}
                        />
                        {hasError ? (
                            <FormHelperText id={"error-" + props.key}>
                                {props.errorMessage.map((error, index) => (
                                    <span key={index} className={classes.spanLine}>{error}</span>
                                ))}
                            </FormHelperText>
                        ) : null}
                    </FormControl>);
            case validator.FORM_ELEMENT_INPUT_SELECT:
                return (<FormControl fullWidth={isFullWidth} className={classes.margin} error={hasError} aria-describedby={"error-" + props.key}>
                    <InputLabel htmlFor={props.key}>{props.labelName}</InputLabel>
                    <Select
                        id={props.key}
                        value={props.elementValue}
                        onChange={props.onChange}>
                        <MenuItem value="">
                            <em><Translate id="none" /></em>
                        </MenuItem>
                        {props.selectList.map(option => (
                            <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                        ))}
                    </Select>
                    {hasError ? (
                        <FormHelperText id={"error-" + props.key}>
                            {props.errorMessage.map((error, index) => (
                                <span key={index} className={classes.spanLine}>{error}</span>
                            ))}
                        </FormHelperText>
                    ) : props.helperText ?
                            (<FormHelperText id={"help-" + props.key}>
                                {props.helperText}
                            </FormHelperText>) : null
                    }
                </FormControl>);
            case validator.FORM_ELEMENT_INPUT_FILE:
                return (<FormControl fullWidth={isFullWidth} className={classes.margin} error={hasError} aria-describedby={"error-" + props.key}>
                    <InputLabel htmlFor={props.key}>{props.labelName}</InputLabel>
                    <Input
                        id={props.key}
                        value={props.elementValue}
                        onChange={props.onChange}
                        type="file"
                        startAdornment={
                            <InputAdornment position="start">
                                <PhotoIcon />
                            </InputAdornment>
                        }
                    />
                    {hasError ? (
                        <FormHelperText id={"error-" + props.key}>
                            {props.errorMessage.map((error, index) => (
                                <span key={index} className={classes.spanLine}>{error}</span>
                            ))}
                        </FormHelperText>
                    ) : null}
                </FormControl>);
            case validator.FORM_ELEMENT_INPUT_TEXTAREA:
                return (<FormControl fullWidth={isFullWidth} className={classes.margin} error={hasError} aria-describedby={"error-" + props.key}>
                    <InputLabel htmlFor={props.key}>{props.labelName}</InputLabel>
                    <Input
                        id={props.key}
                        value={props.elementValue}
                        onChange={props.onChange}
                        multiline
                    />
                    {hasError ? (
                        <FormHelperText id={"error-" + props.key}>
                            {props.errorMessage.map((error, index) => (
                                <span key={index} className={classes.spanLine}>{error}</span>
                            ))}
                        </FormHelperText>
                    ) : props.helperText ?
                            (<FormHelperText id={"help-" + props.key}>
                                {props.helperText}
                            </FormHelperText>) : null
                    }
                </FormControl>);
            default:
                return null;
        }
    } else {
        return null;
    }
}

export default withStyles(styles)(FormElement);