import React from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import * as validator from 'utils/form/validator';
import { sortObjectByOrder, updateObject } from 'utils/common/object';
import * as actions from 'state/actions/index';
import FormElement from 'components/common/form/FormElement';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import DoneIcon from '@material-ui/icons/Done';
import { Translate } from "react-localize-redux";
import Alert from '@material-ui/lab/Alert';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
    content: {
        '&:first-child': {
            paddingTop: theme.spacing(12),
        }
    },
    description: {
        fontSize: 13,
        marginBottom: theme.spacing(2)
    },
    spinner: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: theme.spacing(4)
    },
    error: {
        textAlign: 'center',
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(3),
        color: 'red'
    },
    forgotLink: {
        textDecoration: 'none'
    },
    forgotText: {
        marginTop: theme.spacing(2),
        color: 'rgba(0, 0, 0, 0.54)'
    },
    icon: {
        margin: theme.spacing(1),
        fontSize: 50,
        color: 'green'
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
});

const ContactDialog = props => {
    const [contactForm, setContactForm] = React.useState(
        {
            firstname: {
                type: validator.FORM_ELEMENT_INPUT_TEXT,
                value: '',
                config: {
                    label: 'Nombre',
                    validation: {
                        required: {
                            error: <Translate id="notEmpty" data={{ name: 'Este campo' }} />
                        },
                        minLength: {
                            value: 3,
                            error: <Translate id="notMinLength" data={{ value: 3 }} />
                        },
                        maxLength: {
                            value: 50,
                            error: <Translate id="notMaxLength" data={{ value: 50 }} />
                        }
                    },
                },
                valid: false,
                touched: false,
                errorMessage: [],
                order: 1
            },
            surname: {
                type: validator.FORM_ELEMENT_INPUT_TEXT,
                value: '',
                config: {
                    label: 'Apellidos',
                    validation: {
                        required: {
                            error: <Translate id="notEmpty" data={{ name: 'Este campo' }} />
                        },
                        minLength: {
                            value: 3,
                            error: <Translate id="notMinLength" data={{ value: 3 }} />
                        },
                        maxLength: {
                            value: 50,
                            error: <Translate id="notMaxLength" data={{ value: 50 }} />
                        }
                    },
                },
                valid: false,
                touched: false,
                errorMessage: [],
                order: 2
            },
            email: {
                type: validator.FORM_ELEMENT_INPUT_EMAIL,
                value: '',
                config: {
                    label: <Translate id="email" />,
                    validation: {
                        required: {
                            error: <Translate id="notEmpty" data={{ name: <Translate id="email" /> }} />
                        },
                        isEmail: {
                            error: <Translate id="notValid" data={{ name: <Translate id="email" /> }} />
                        },
                        maxLength: {
                            value: 50,
                            error: <Translate id="notMaxLength" data={{ value: 50 }} />
                        }
                    },
                },
                valid: false,
                touched: false,
                errorMessage: [],
                order: 3
            },
            phone: {
                type: validator.FORM_ELEMENT_INPUT_TEXT,
                value: '',
                config: {
                    label: 'Celular',
                    validation: {                        
                        maxLength: {
                            value: 20,
                            error: <Translate id="notMaxLength" data={{ value: 20 }} />
                        }
                    },
                },
                valid: false,
                touched: false,
                errorMessage: [],
                order: 4
            },            
            courses: {
                type: validator.FORM_ELEMENT_INPUT_TEXTAREA,
                value: '',
                config: {
                    label: '¿Qué curso puedes dictar?',
                    helper: 'Tema, objetivos, a quién está dirigido',
                    validation: {
                        required: {
                            error: <Translate id="notEmpty" data={{ name: 'Este campo' }} />
                        },
                        minLength: {
                            value: 3,
                            error: <Translate id="notMinLength" data={{ value: 3 }} />
                        },
                        maxLength: {
                            value: 500,
                            error: <Translate id="notMaxLength" data={{ value: 500 }} />
                        }
                    },
                },
                valid: false,
                touched: false,
                errorMessage: [],
                order: 5
            }
        }
    );

    const cleanFormData = () => {
        let formElements = {};
        for (let inputIdentifier in contactForm) {
            let updatedFormElement = updateObject(contactForm[inputIdentifier], {
                value: contactForm[inputIdentifier].type == validator.FORM_ELEMENT_CHECKBOX ? false : '',
                valid: false,
                touched: false,
                errorMessage: []
            });
            formElements[inputIdentifier] = updatedFormElement;
        }
        const updatedContactForm = updateObject(contactForm, formElements);
        setContactForm(updatedContactForm);
    }

    const handleClose = () => {
        cleanFormData();
        props.gCloseDialog();
    }

    const handleChange = (event, inputIdentifier) => {
        let inputValue = ''; let inputFile = null;
        switch (contactForm[inputIdentifier].type) {
            case validator.FORM_ELEMENT_CHECKBOX:
                inputValue = event.target.checked;
                break;
            case validator.FORM_ELEMENT_INPUT_FILE:
                inputValue = event.target.value;
                inputFile = event.target.files[0];
                break;
            default:
                inputValue = event.target.value;
                break;
        }
        const validation = validator.checkValidity(inputValue, contactForm[inputIdentifier].config.validation);
        const updatedFormElement = updateObject(contactForm[inputIdentifier], {
            value: inputValue,
            file: inputFile,
            valid: validation[0],
            touched: true,
            errorMessage: validation[1]
        });
        const updatedForm = updateObject(contactForm, {
            [inputIdentifier]: updatedFormElement
        });
        setContactForm(sortObjectByOrder(updatedForm));
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        let formIsValid = true;
        let formElements = {};
        const formData = {};
        for (let inputIdentifier in contactForm) {
            let inputValue = contactForm[inputIdentifier].value;
            let inputFile = contactForm[inputIdentifier].file;
            let validation = validator.checkValidity(inputValue, contactForm[inputIdentifier].config.validation);
            let inputIsValid = validation[0];
            let updatedFormElement = updateObject(contactForm[inputIdentifier], {
                valid: inputIsValid,
                touched: true,
                errorMessage: validation[1]
            });
            formElements[inputIdentifier] = updatedFormElement;
            formIsValid = inputIsValid && formIsValid;
            formData[inputIdentifier] = contactForm[inputIdentifier].type == validator.FORM_ELEMENT_INPUT_FILE ? inputFile : (inputValue + "");
        }
        const updatedForm = updateObject(contactForm, formElements);
        setContactForm(updatedForm);
        if (formIsValid) {
            props.gSubmitItem(formData);
        }
    }

    const { classes } = props;
    const formElementArray = [];
    for (let key in contactForm) {
        formElementArray.push({
            id: key,
            attr: contactForm[key]
        });
    }

    let dialogTitle = 'Datos del Instructor';
    let dialogDescription = 'Completa el siguiente formulario para que podamos contactarte, y... ¡Únete a los que saben!';
    let submitNotification = <Typography variant="subtitle1" align="center">
        <DoneIcon className={classes.icon} /><br />
        <Translate id="contactNotification" data={{ fullName: props.gSubmitedContact }} options={{ renderInnerHtml: true }} />
    </Typography>;
    let submitButton = <Translate id="submit" />;

    return (
        props.gIsDialogOpen ?
            (<Dialog fullScreen open={props.gIsDialogOpen} onClose={() => handleClose()} aria-labelledby="form-dialog-title" >
                <AppBar color="secondary" position="fixed">
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            {dialogTitle}
                        </Typography>
                        {props.gSubmitedContact ? null :
                            <Button autoFocus type="submit" onClick={(event) => handleSubmit(event)} color="inherit">
                                {submitButton}
                            </Button>}
                    </Toolbar>
                </AppBar>
                <form onSubmit={(event) => handleSubmit(event)} noValidate autoComplete="off">
                    <DialogContent className={classes.content}>
                        {props.gSubmitLoading ?
                            (<div className={classes.spinner}><CircularProgress className={classes.progress} size={50} /></div>) :
                            (props.gSubmitedContact ?
                                submitNotification : (
                                    <React.Fragment>
                                        {dialogDescription}
                                        <br /><br />
                                        {props.gSubmitError ? <Alert severity="error" style={{ marginBottom: '15px' }}>{props.gSubmitError}</Alert> : null}
                                        {formElementArray.map(formElement => (
                                            <FormElement
                                                key={formElement.id}
                                                isVisible="true"
                                                elementType={formElement.attr.type}
                                                elementValue={formElement.attr.value}
                                                labelName={formElement.attr.config.label}
                                                helperText={formElement.attr.config.helper}
                                                selectList={formElement.attr.config.selectList}
                                                invalid={!formElement.attr.valid}
                                                shouldValidate={formElement.attr.config.validation}
                                                touched={formElement.attr.touched}
                                                errorMessage={formElement.attr.errorMessage}
                                                onChange={(event) => handleChange(event, formElement.id)} />
                                        ))}
                                        <br /><br /><br /><br /><br /><br />
                                    </React.Fragment>
                                )
                            )
                        }
                    </DialogContent>
                </form>
            </Dialog>) : null
    );
}

const mapStateToProps = state => {
    return {
        gDialogType: state.app.dialogType,
        gIsDialogOpen: ["contact"].includes(state.app.dialogType),
        gSubmitLoading: state.contact.submitLoading,
        gSubmitError: state.contact.submitError,
        gSubmitedContact: state.contact.submitedContact,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        gCloseDialog: () => dispatch(actions.closeDialog()),
        gSubmitItem: (formData) => dispatch(actions.contactSubmitItem(formData)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ContactDialog));