import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import * as actions from 'store/actions/index';
import { Translate, withLocalize } from "react-localize-redux";

const styles = {
    list: {
        padding: '24px 24px 10px'
    },
    listItems: {
        textAlign: 'center'
    }
};

const LanguageDialog = props => {

    const handleClose = () => {
        props.gCloseDialog();
    }

    const handleItemClick = (langCode) => {
        props.setActiveLanguage(langCode)
        localStorage.setItem('langCode', langCode);
        props.gCloseDialog();
    }

    const { classes } = props;
    return (
        props.gIsDialogOpen ? (
            <Dialog open={props.gIsDialogOpen} onClose={() => handleClose()} aria-labelledby="language-dialog-title">
                <DialogTitle id="language-dialog-title" className={classes.list} ><Translate id="selectLanguage" /></DialogTitle>
                <div>
                    <List>
                        {props.languages.map((language, index) => (
                            <ListItem button onClick={() => handleItemClick(language.code)} key={index}>
                                <ListItemText primary={language.name} className={classes.listItems} />
                            </ListItem>
                        ))}
                    </List>
                </div>
            </Dialog>) : null
    );
}

const mapStateToProps = state => {
    return {
        gIsDialogOpen: ["changeLanguage"].includes(state.app.dialogType),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        gCloseDialog: () => dispatch(actions.closeDialog())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withLocalize(LanguageDialog)));