import React from 'react';
import { useParams, Redirect, Link } from "react-router-dom";
import * as appParams from 'config/params';
import * as validator from 'utils/form/validator';
import { connect } from 'react-redux';
import MainLayout from 'components/common/layout/MainLayout';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import * as actions from 'state/actions/index';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CompanySummary from './box/CompanySummary';
import CompanyButtons from './box/CompanyButtons';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import InsertIcon from '@material-ui/icons/PersonAdd';
import UpdateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Translate } from "react-localize-redux";
import Button from '@material-ui/core/Button';
import ItemForm from 'components/common/form/ItemForm';

const styles = theme => ({
    root: {
        flexGrow: 1,
        margin: 20,
        marginTop: theme.spacing(10)
    },
    spinner: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: theme.spacing(4)
    },
    avatar: {
        width: theme.spacing(5),
        height: theme.spacing(5),
        margin: 'auto'
    }
});

const CompanyMembers = props => {

    const { companyId } = useParams();

    React.useEffect(() => {
        props.gFetchItem(companyId);
        props.gFetchList(companyId);
    }, [companyId]);

    const roleName = (role) => {
        switch (role) {
            case appParams.ROLE_COM_OWNER:
                return <Translate id="owner" />
            case appParams.ROLE_COM_ADMIN:
                return <Translate id="admin" />
            case appParams.ROLE_COM_EDITOR:
                return <Translate id="editor" />
            case appParams.ROLE_COM_INSTRUCTOR:
                return <Translate id="instructor" />
            default:
                return ""
        }
    }

    const [open, setOpen] = React.useState(false);
    const [member, setMember] = React.useState(null);
    const [action, setAction] = React.useState(null);

    const handleDialogOpen = (member, action) => {
        setMember(member);
        setAction(action);
        setOpen(true);
    };

    const handleDialogClose = () => {
        setOpen(false);
    };

    const handleSubmit = (formData) => {
        switch (action) {
            case 'INSERT':
                props.gSubmitItem(props.gItem, props.gList, formData);
                break;
            case 'UPDATE':
                props.gUpdateItem(props.gItem, member, formData.role);
                break;
            case 'DELETE':
                props.gDeleteItem(props.gItem, member);
                break;
            default:
                break;
        }
        setMember(null);
        setAction(null);
        setOpen(false);
    };

    const insertFormParams = {
        email: {
            type: validator.FORM_ELEMENT_INPUT_EMAIL,
            value: '',
            config: {
                label: <Translate id="email" />,
                validation: {
                    required: {
                        error: <Translate id="notEmpty" data={{ name: <Translate id="email" /> }} />
                    },
                    isEmail: {
                        error: <Translate id="notValid" data={{ name: <Translate id="email" /> }} />
                    },
                    maxLength: {
                        value: 50,
                        error: <Translate id="notMaxLength" data={{ value: 50 }} />
                    }
                },
            },
            valid: false,
            touched: false,
            errorMessage: [],
            order: 1
        },
        role: {
            type: validator.FORM_ELEMENT_INPUT_SELECT,
            value: '',
            config: {
                label: <Translate id="role" />,
                validation: {
                    required: {
                        error: <Translate id="notEmpty" data={{ name: <Translate id="role" /> }} />
                    },
                },
                selectList: [
                    { id: 'EDITOR', name: <Translate id="editor" /> },
                    { id: 'INSTRUCTOR', name: <Translate id="instructor" /> }
                ]
            },
            valid: false,
            touched: false,
            errorMessage: [],
            order: 2
        }
    };

    const updateFormParams = {
        role: {
            type: validator.FORM_ELEMENT_INPUT_SELECT,
            value: member ? member.role : null,
            config: {
                label: <Translate id="role" />,
                validation: {
                    required: {
                        error: <Translate id="notEmpty" data={{ name: <Translate id="role" /> }} />
                    },
                },
                selectList: [
                    { id: 'EDITOR', name: <Translate id="editor" /> },
                    { id: 'INSTRUCTOR', name: <Translate id="instructor" /> }
                ]
            },
            valid: false,
            touched: false,
            errorMessage: [],
            order: 1
        }
    };

    const { classes } = props;
    return (
        <MainLayout>
            <div className={classes.root}>
                {props.gItemLoading || props.gListLoading ?
                    (<div className={classes.spinner}><CircularProgress className={classes.progress} size={50} /></div>) :
                    (<Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                            <CompanySummary item={props.gItem} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={9} lg={9}>
                            <Grid container spacing={2}>
                                {props.gIsAuth && props.gUserId == props.gItem.owner.id ?
                                    <Grid item xs={12}>
                                        <Card>
                                            <CardContent style={{ padding: '0' }}>
                                                <CompanyButtons item={props.gItem} isOwner={props.gUserId == props.gItem.owner.id} tabValue={2} />
                                            </CardContent>
                                        </Card>
                                    </Grid> : <Redirect to="/" />}
                                <Grid item xs={12}>
                                    <Card>
                                        <CardContent>
                                            <Typography gutterBottom variant="button">
                                                <Translate id="members" />
                                            </Typography>
                                            <Divider light style={{ marginBottom: '25px' }} />
                                            <Grid container alignItems="center" spacing={3} style={{ marginBottom: '20px' }}>
                                                <Grid item xs={6} sm={6} md={3} lg={3} style={{ textAlign: 'center' }}>
                                                    <Link to={"/user/" + props.gItem.owner.username} style={{ textDecoration: 'none', color: 'inherit' }}>
                                                        <Avatar src={props.gItem.owner.photo ? props.gItem.owner.photo : null} className={classes.avatar} />
                                                        <Typography gutterBottom variant="caption">
                                                            {props.gItem.owner.fullName}
                                                        </Typography>
                                                    </Link>
                                                    <Typography variant="overline" display="block" style={{ marginTop: '-8px' }}>
                                                        <Translate id="owner" />
                                                    </Typography>
                                                </Grid>
                                                {props.gList ? props.gList.map((m, index) => (
                                                    <Grid key={m.id} item xs={6} sm={6} md={3} lg={3} style={{ textAlign: 'center' }}>
                                                        <Link to={"/user/" + m.username} style={{ textDecoration: 'none', color: 'inherit' }}>
                                                            <Avatar src={m.photo ? m.photo : null} className={classes.avatar} />
                                                            <Typography gutterBottom variant="caption">
                                                                {m.fullName}
                                                            </Typography>
                                                        </Link>
                                                        <Typography variant="overline" display="block" style={{ marginTop: '-8px' }}>
                                                            {m.role == 'EDITOR' ? <Translate id="editor" /> : <Translate id="instructor" />}
                                                        </Typography>
                                                        <React.Fragment>
                                                            <IconButton aria-label="update" className={classes.margin} size="small" onClick={() => { handleDialogOpen(m, 'UPDATE') }}>
                                                                <UpdateIcon fontSize="inherit" />
                                                            </IconButton>
                                                            <IconButton aria-label="delete" className={classes.margin} size="small" onClick={() => { handleDialogOpen(m, 'DELETE') }} >
                                                                <DeleteIcon fontSize="inherit" />
                                                            </IconButton>
                                                        </React.Fragment>
                                                    </Grid>
                                                )) : null}
                                                <Grid item xs={6} sm={6} md={3} lg={3} style={{ textAlign: 'center' }}>
                                                    <a href="#!" onClick={() => { handleDialogOpen(null, 'INSERT') }} style={{ textDecoration: 'none', color: 'inherit' }}>
                                                        <Avatar className={classes.avatar}>
                                                            <InsertIcon />
                                                        </Avatar>
                                                        <Typography gutterBottom variant="caption" >
                                                            <Translate id="memberInsert" />
                                                        </Typography>
                                                    </a>
                                                    <div style={{ height: '23px' }}></div>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Dialog
                            open={open}
                            onClose={handleDialogClose}
                            fullWidth
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description">
                            <DialogTitle id="alert-dialog-title">
                                {action == 'INSERT' ? <Translate id="memberInsert" /> :
                                    action == 'UPDATE' ? <Translate id="memberUpdate" /> :
                                        action == 'DELETE' ? <Translate id="memberDelete" /> : null
                                }
                            </DialogTitle>
                            <DialogContent style={{ marginBottom: '20px' }}>
                                {action == 'UPDATE' && member ?
                                    <Grid item style={{ textAlign: 'center' }}>
                                        <Link to={"/user/" + member.username} style={{ textDecoration: 'none', color: 'inherit' }}>
                                            <Avatar src={member.photo ? member.photo : null} className={classes.avatar} />
                                            <Typography gutterBottom variant="caption">
                                                {member.fullName}
                                            </Typography>
                                        </Link>
                                        <Typography variant="overline" display="block" style={{ marginTop: '-8px' }}>
                                            {member.role == 'EDITOR' ? <Translate id="editor" /> : <Translate id="instructor" />}
                                        </Typography>
                                    </Grid> : null}
                                {action == 'INSERT' ?
                                    <ItemForm
                                        formParams={insertFormParams}
                                        onValidSubmit={handleSubmit}
                                        formError={props.submitError}
                                    /> :
                                    action == 'UPDATE' ?
                                        <ItemForm
                                            formParams={updateFormParams}
                                            onValidSubmit={handleSubmit}
                                            formError={props.submitError}
                                        /> :
                                        action == 'DELETE' ?
                                            <DialogContentText id="alert-dialog-description">
                                                <Translate id="confirmDeletionDescription" />
                                            </DialogContentText> : null
                                }
                            </DialogContent>
                            {action == 'DELETE' ?
                                <DialogActions>
                                    <Button onClick={handleDialogClose}>
                                        <Translate id="cancel" />
                                    </Button>
                                    <Button onClick={() => { handleSubmit() }} color="secondary" autoFocus>
                                        <Translate id="confirm" />
                                    </Button>
                                </DialogActions> : null
                            }
                        </Dialog>
                    </Grid>)}
            </div>
        </MainLayout>
    );
}

const mapStateToProps = state => {
    return {
        gIsAuth: !!state.auth.user.id,
        gUserId: state.auth.user.id,
        gItem: state.company.item,
        gItemLoading: state.company.item == null,
        gItemError: state.company.itemError,
        gList: state.companyUser.list,
        gListLoading: !state.companyUser.list,
        gListError: state.companyUser.listError
    };
};

const mapDispatchToProps = dispatch => {
    return {
        gFetchItem: (companyId) => dispatch(actions.companyFetchItemByCompanyId(companyId)),
        gFetchList: (companyId) => dispatch(actions.companyUserFetchListByCompanyId(companyId)),
        gSubmitItem: (company, members, formData) => dispatch(actions.companyUserSubmitItem(company, members, formData)),
        gUpdateItem: (company, member, newRole) => dispatch(actions.companyUserUpdateItem(company, member, newRole)),
        gDeleteItem: (company, member) => dispatch(actions.companyUserDeleteItem(company, member))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CompanyMembers));