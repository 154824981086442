import React from 'react';
import { Translate } from "react-localize-redux";
import * as validator from 'utils/form/validator';
import ItemForm from 'components/common/form/ItemForm';
import { withStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

const styles = theme => ({
});

const CourseForm = props => {

    let selectCategoryList = [];

    if (props.categoryList) {
        for (let i = 0; i < props.categoryList.length; i++) {
            selectCategoryList[i] = { id: props.categoryList[i].id + "", name: props.categoryList[i].name };
        }
    }

    let companyId = null;
    if (props.companyList) {
        let selectCompanyList = [];
        for (let i = 0; i < props.companyList.length; i++) {
            selectCompanyList[i] = { id: props.companyList[i].id + "", name: props.companyList[i].name };
        }
        if (selectCompanyList.length > 0) {
            companyId = {
                type: validator.FORM_ELEMENT_INPUT_SELECT,
                value: '',
                config: {
                    label: <Translate id="company" />,
                    validation: {
                    },
                    selectList: selectCompanyList
                },
                valid: false,
                touched: false,
                errorMessage: [],
                order: 1
            };
        }
    }

    let formParams = {
        companyId: companyId,
        categoryId: {
            type: validator.FORM_ELEMENT_INPUT_SELECT,
            value: props.item && props.item.category ? props.item.category.id + "" : "",
            config: {
                label: <Translate id="category" />,
                validation: {
                    required: {
                        error: <Translate id="notEmpty" data={{ name: <Translate id="category" /> }} />
                    },
                },
                selectList: selectCategoryList
            },
            valid: false,
            touched: false,
            errorMessage: [],
            order: companyId ? 2 : 1
        },
        name: {
            type: validator.FORM_ELEMENT_INPUT_TEXT,
            value: props.item && props.item.name != null ? props.item.name : '',
            config: {
                label: <Translate id="courseName" />,
                validation: {
                    required: {
                        error: <Translate id="notEmpty" data={{ name: <Translate id="name" /> }} />
                    },
                    minLength: {
                        value: 10,
                        error: <Translate id="notMinLength" data={{ value: 10 }} />
                    },
                    maxLength: {
                        value: 400,
                        error: <Translate id="notMaxLength" data={{ value: 400 }} />
                    }
                },
            },
            valid: false,
            touched: false,
            errorMessage: [],
            order: companyId ? 3 : 2
        },
        description: {
            type: validator.FORM_ELEMENT_INPUT_TEXTAREA,
            value: props.item && props.item.description != null ? props.item.description : '',
            config: {
                label: <Translate id="description" />,
                validation: {
                    maxLength: {
                        value: 500,
                        error: <Translate id="notMaxLength" data={{ value: 500 }} />
                    }
                },
            },
            valid: false,
            touched: false,
            errorMessage: [],
            order: companyId ? 4 : 3
        },
        purpose: {
            type: validator.FORM_ELEMENT_INPUT_TEXTAREA,
            value: props.item && props.item.purpose != null ? props.item.purpose : '',
            config: {
                label: <Translate id="purpose" />,
                validation: {
                    maxLength: {
                        value: 500,
                        error: <Translate id="notMaxLength" data={{ value: 500 }} />
                    }
                },
            },
            valid: false,
            touched: false,
            errorMessage: [],
            order: companyId ? 5 : 4
        },
        audience: {
            type: validator.FORM_ELEMENT_INPUT_TEXTAREA,
            value: props.item && props.item.audience != null ? props.item.audience : '',
            config: {
                label: <Translate id="audience" />,
                validation: {
                    maxLength: {
                        value: 500,
                        error: <Translate id="notMaxLength" data={{ value: 500 }} />
                    }
                },
            },
            valid: false,
            touched: false,
            errorMessage: [],
            order: companyId ? 6 : 5
        },
        requirements: {
            type: validator.FORM_ELEMENT_INPUT_TEXTAREA,
            value: props.item && props.item.requirements != null ? props.item.requirements : '',
            config: {
                label: <Translate id="requirements" />,
                validation: {
                    maxLength: {
                        value: 500,
                        error: <Translate id="notMaxLength" data={{ value: 500 }} />
                    }
                },
            },
            valid: false,
            touched: false,
            errorMessage: [],
            order: companyId ? 7 : 6
        }
    };

    if (formParams["companyId"] == null) {
        delete formParams['companyId'];
    }

    const { classes } = props;

    return (
        <React.Fragment>
            <ItemForm
                formParams={formParams}
                onValidSubmit={props.onValidSubmit}
                formError={props.submitError}
            />
        </React.Fragment>
    );
}

export default withStyles(styles)(CourseForm);