import { createReducer } from 'redux-act';
import * as action from 'state/actions';

const initialState = {
  list: null,
  listLoading: false,
  listError: null,
  item: null,
  itemLoading: false,
  itemError: null,
  submitLoading: false,
  submitError: null,
  deleteLoading: false,
  deleteError: null,
  updatedCompanyPhoto: null,
  submitedCompany: null
};

export const companyReducer = createReducer(
  {
    [action.COMPANY_SUBMIT_START]: (state, action) => ({
      ...state,
      submitError: null,
      submitLoading: true
    }),

    [action.COMPANY_SUBMIT_SUCCESS]: (state, action) => ({
      ...state,
      submitError: null,
      submitLoading: false,
      updatedCompanyPhoto: action.photo,
      submitedCompany: action.alias
    }),

    [action.COMPANY_SUBMIT_FAIL]: (state, action) => ({
      ...state,
      submitError: action.error,
      submitLoading: false
    }),

    [action.COMPANY_SUBMIT_END]: (state, action) => ({
      ...state,
      submitError: null,
      submitLoading: false
    }),

    [action.COMPANY_LIST_START]: (state, action) => ({
      ...state,
      list: null,
      listLoading: true,
      listError: null
    }),

    [action.COMPANY_LIST_SUCCESS]: (state, action) => ({
      ...state,
      list: action.data,
      listLoading: false,
      listError: null
    }),

    [action.COMPANY_LIST_FAIL]: (state, action) => ({
      ...state,
      list: null,
      listLoading: false,
      listError: action.error
    }),

    [action.COMPANY_LIST_END]: (state, action) => ({
      ...state,
      listLoading: false,
      listError: null
    }),

    [action.COMPANY_ITEM_START]: (state, action) => ({
      ...state,
      updatedCompanyPhoto: null,
      submitedCompany: null,
      item: null,
      itemLoading: true,
      itemError: null
    }),

    [action.COMPANY_ITEM_SUCCESS]: (state, action) => ({
      ...state,
      item: action.data,
      itemLoading: false,
      itemError: null
    }),

    [action.COMPANY_ITEM_FAIL]: (state, action) => ({
      ...state,
      item: null,
      itemLoading: false,
      itemError: action.error
    }),

    [action.COMPANY_ITEM_END]: (state, action) => ({
      ...state,
      itemLoading: false,
      itemError: null
    }),

    [action.COMPANY_DELETE_START]: (state, action) => ({
      ...state,
      deleteError: null,
      deleteLoading: true
    }),

    [action.COMPANY_DELETE_SUCCESS]: (state, action) => ({
      ...state,
      deleteError: null,
      deleteLoading: false
    }),

    [action.COMPANY_DELETE_FAIL]: (state, action) => ({
      ...state,
      deleteError: action.error,
      deleteLoading: false
    }),
    [action.COMPANY_DELETE_END]: (state, action) => ({
      ...state,
      deleteError: null,
      deleteLoading: false
    })
  },
  initialState
);