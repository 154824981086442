import React from 'react';
import { Translate } from "react-localize-redux";
import * as action from 'state/actions';
import firebase from 'firebase.js';

export const auth = (formData, isNewUser) => {
    return async (dispatch, getState) => {
        if (isNewUser) { // Sign Up
            dispatch(action.AUTH_SIGN_UP_INIT());
            let response;
            try {
                response = await firebase.auth().createUserWithEmailAndPassword(formData.email, formData.password);
            } catch (error) {
                console.log(error);
                const errorMessage = getErrorMessage(error.code);
                return dispatch(
                    action.AUTH_SIGN_UP_FAIL({ error: errorMessage })
                );
            }
            const emailVerification = firebase.auth().onAuthStateChanged(function (user) {
                user.sendEmailVerification();
            });
            const { uid } = response.user;
            const createUserDbTask = firebase.firestore().collection('users').doc(uid)
                .set({
                    fullName: formData.fullName,
                    email: formData.email,
                    username: uid,
                    createdAt: new Date(),
                    isAdmin: false
                });
            try {
                await Promise.all([emailVerification, createUserDbTask]);
            } catch (error) {
                const errorMessage = <Translate id="error" />;
                return dispatch(
                    action.AUTH_SIGN_UP_FAIL({ error: errorMessage })
                );
            }
            return dispatch(action.AUTH_SIGN_UP_SUCCESS({ email: formData.email }));
        } else { // Sign In
            dispatch(action.AUTH_SIGN_IN_INIT());
            try {
                await firebase.auth().signInWithEmailAndPassword(formData.email, formData.password);
            } catch (error) {
                console.log(error);
                const errorMessage = getErrorMessage(error.code);
                return dispatch(action.AUTH_SIGN_IN_FAIL({ error: errorMessage }));
            }
            const { emailVerified } = firebase.auth().currentUser;
            if (!emailVerified) {
                const errorMessage = <Translate id="emailNotValidated" />;
                return dispatch(action.AUTH_SIGN_IN_FAIL({ error: errorMessage }));
            }
            return dispatch(authFetchUser());
        }
    };
};

export const authFetchUser = () => {
    return async (dispatch) => {
        dispatch(action.AUTH_FETCH_USER_DATA_INIT());
        const { uid } = firebase.auth().currentUser;
        let user;
        try {
            await firebase.firestore().collection('users').doc(uid).get()
                .then((doc) => {
                    user = { id: doc.id, ...doc.data() };
                    dispatch(action.closeDialog());
                });
        } catch (error) {
            dispatch(authLogout());
            return dispatch(action.AUTH_FETCH_USER_DATA_FAIL({ error }));
        }
        if (!user) {
            return dispatch(authLogout());
        }
        return dispatch(action.AUTH_FETCH_USER_DATA_SUCCESS(user));
    };
};

export const authCheck = () => {
    return (dispatch) => {
        firebase.auth().onAuthStateChanged((user) => {
            dispatch(action.AUTH_RESTORE_SESSION_INIT());
            if (user !== null) {
                return dispatch(action.AUTH_RESTORE_SESSION_SUCCESS());
            }
            dispatch(action.AUTH_RESTORE_SESSION_FAIL());
            return dispatch(authLogout());
        });
    };
};

export const authLogout = () => {
    return async (dispatch) => {
        dispatch(action.AUTH_LOGOUT_INIT());
        dispatch(action.USERS_CLEAR_DATA_LOGOUT());
        await firebase.auth().signOut();
        dispatch(action.AUTH_LOGOUT_SUCCESS());
    };
};

export const authEnd = () => (dispatch) => dispatch(action.AUTH_CLEAN_UP());

export function authForgotPassRecovery(formData) {
    return async (dispatch, getState) => {
        dispatch(action.AUTH_RESET_PASSWORD_INIT());
        try {
            await firebase.auth().sendPasswordResetEmail(formData.email);
        } catch (error) {
            console.log(error);
            const errorMessage = getErrorMessage(error.code);
            return dispatch(action.AUTH_RESET_PASSWORD_FAIL({ error: errorMessage }));
        }
        return dispatch(action.AUTH_RESET_PASSWORD_SUCCESS({ email: formData.email }));
    };
};

export function authChangePass(formData) {
    return async (dispatch, getState) => {
        dispatch(action.AUTH_CHANGE_PASSWORD_INIT());
        const user = firebase.auth().currentUser;
        const { email } = user;
        const credential = firebase.auth.EmailAuthProvider.credential(
            email,
            formData.currentPassword
        );
        try {
            await user.reauthenticateWithCredential(credential);
        } catch (error) {
            console.log(error);
            const errorMessage = getErrorMessage(error.code);
            return dispatch(action.AUTH_CHANGE_PASSWORD_FAIL({ error: errorMessage }));
        }
        try {
            await user.updatePassword(formData.newPassword);
        } catch (error) {
            console.log(error);
            const errorMessage = getErrorMessage(error.code);
            return dispatch(action.AUTH_CHANGE_PASSWORD_FAIL({ error: errorMessage }));
        }
        return dispatch(action.AUTH_CHANGE_PASSWORD_SUCCESS({ email: email }));
    };    
};

const getErrorMessage = (error) => {
    let errorMessage = '';
    switch (error) {
        case 'auth/wrong-password':
        case 'auth/user-not-found':
            errorMessage = <Translate id="emailNotFound" />;
            break;
        case 'auth/email-already-in-use':
            errorMessage = <Translate id="emailExists" />;
            break;
        case 'EMAIL_NOT_VALIDATED':
            errorMessage = <Translate id="emailNotValidated" />;
            break;
        case 'WEAK_PASSWORD':
            errorMessage = <Translate id="weakPassword" />;
            break;
        default:
            errorMessage = <Translate id="error" />;
            break;
    }
    return errorMessage;
};