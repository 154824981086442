import React from 'react';
import { Translate } from "react-localize-redux";
import * as validator from 'utils/form/validator';
import ItemForm from 'components/common/form/ItemForm';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
});

const UserProfileForm = props => {

    const formParams = {
        photo: {
            type: validator.FORM_ELEMENT_INPUT_FILE,
            value: '',
            file: null,
            config: {
                label: <Translate id="photo" />,
                validation: {},
            },
            valid: false,
            touched: false,
            errorMessage: [],
            order: 1
        },
        fullName: {
            type: validator.FORM_ELEMENT_INPUT_TEXT,
            value: props.item && props.item.fullName != null ? props.item.fullName : '',
            config: {
                label: <Translate id="fullName" />,
                validation: {
                    required: {
                        error: <Translate id="notEmpty" data={{ name: <Translate id="fullName" /> }} />
                    },
                    minLength: {
                        value: 3,
                        error: <Translate id="notMinLength" data={{ value: 3 }} />
                    },
                    maxLength: {
                        value: 50,
                        error: <Translate id="notMaxLength" data={{ value: 50 }} />
                    }
                },
            },
            valid: false,
            touched: false,
            errorMessage: [],
            order: 2
        },        
        username: {
            type: validator.FORM_ELEMENT_INPUT_TEXT,
            value: props.item && props.item.username != null ? props.item.username : '',
            config: {
                label: <Translate id="username" />,
                validation: {
                    required: {
                        error: <Translate id="notEmpty" data={{ name: <Translate id="username" /> }} />
                    },
                    minLength: {
                        value: 3,
                        error: <Translate id="notMinLength" data={{ value: 3 }} />
                    },
                    maxLength: {
                        value: 20,
                        error: <Translate id="notMaxLength" data={{ value: 20 }} />
                    }
                },
            },
            valid: false,
            touched: false,
            errorMessage: [],
            order: 2
        },
        description: {
            type: validator.FORM_ELEMENT_INPUT_TEXTAREA,
            value: props.item && props.item.description != null ? props.item.description : '',
            config: {
                label: <Translate id="shortDescription" />,
                validation: {
                    maxLength: {
                        value: 500,
                        error: <Translate id="notMaxLength" data={{ value: 500 }} />
                    }
                },
            },
            valid: false,
            touched: false,
            errorMessage: [],
            order: 3
        },
        workExperience: {
            type: validator.FORM_ELEMENT_INPUT_TEXTAREA,
            value: props.item && props.item.workExperience != null ? props.item.workExperience : '',
            config: {
                label: <Translate id="workExperience" />,
                validation: {
                    maxLength: {
                        value: 500,
                        error: <Translate id="notMaxLength" data={{ value: 500 }} />
                    }
                },
            },
            valid: false,
            touched: false,
            errorMessage: [],
            order: 4
        },
        academicExperience: {
            type: validator.FORM_ELEMENT_INPUT_TEXTAREA,
            value: props.item && props.item.academicExperience != null ? props.item.academicExperience : '',
            config: {
                label: <Translate id="academicExperience" />,
                validation: {
                    maxLength: {
                        value: 500,
                        error: <Translate id="notMaxLength" data={{ value: 500 }} />
                    }
                },
            },
            valid: false,
            touched: false,
            errorMessage: [],
            order: 5
        },
        location: {
            type: validator.FORM_ELEMENT_INPUT_TEXT,
            value: props.item && props.item.location != null ? props.item.location : '',
            config: {
                label: <Translate id="location" />,
                validation: {
                    maxLength: {
                        value: 200,
                        error: <Translate id="notMaxLength" data={{ value: 200 }} />
                    }
                },
            },
            valid: false,
            touched: false,
            errorMessage: [],
            order: 6
        },
        facebook: {
            type: validator.FORM_ELEMENT_INPUT_TEXT,
            value: props.item && props.item.facebook != null ? props.item.facebook : '',
            config: {
                label: 'Facebook',
                validation: {
                    maxLength: {
                        value: 100,
                        error: <Translate id="notMaxLength" data={{ value: 100 }} />
                    }
                },
            },
            valid: false,
            touched: false,
            errorMessage: [],
            order: 7
        },
        twitter: {
            type: validator.FORM_ELEMENT_INPUT_TEXT,
            value: props.item && props.item.twitter != null ? props.item.twitter : '',
            config: {
                label: 'Twitter',
                validation: {
                    maxLength: {
                        value: 100,
                        error: <Translate id="notMaxLength" data={{ value: 100 }} />
                    }
                },
            },
            valid: false,
            touched: false,
            errorMessage: [],
            order: 8
        },
        instagram: {
            type: validator.FORM_ELEMENT_INPUT_TEXT,
            value: props.item && props.item.instagram != null ? props.item.instagram : '',
            config: {
                label: 'Instagram',
                validation: {
                    maxLength: {
                        value: 100,
                        error: <Translate id="notMaxLength" data={{ value: 100 }} />
                    }
                },
            },
            valid: false,
            touched: false,
            errorMessage: [],
            order: 9
        },
        linkedin: {
            type: validator.FORM_ELEMENT_INPUT_TEXT,
            value: props.item && props.item.linkedin != null ? props.item.linkedin : '',
            config: {
                label: 'Linkedin',
                validation: {
                    maxLength: {
                        value: 100,
                        error: <Translate id="notMaxLength" data={{ value: 100 }} />
                    }
                },
            },
            valid: false,
            touched: false,
            errorMessage: [],
            order: 10
        },
    };

    const { classes } = props;

    return (
        <ItemForm
            formParams={formParams}
            onValidSubmit={props.onValidSubmit}
            formError={props.submitError}
        />
    );
}

export default withStyles(styles)(UserProfileForm);