import React from 'react';
import { connect } from 'react-redux';
import * as actions from 'state/actions/index';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { Grid } from '@material-ui/core';
import ContactDialog from 'components/common/dialog/ContactDialog';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const tutorialSteps = [
    {
        label1: 'Enseña en Join the Master',
        label2: '¿Tienes algún conocimiento y quieres transformarlo en un curso virtual? Únete a nuestra comunidad de Instructores y empieza ahora mismo.',
        imgPath: '/img/teacher_800.png',
    },
    {
        label1: 'Enfócate en crear contenido',
        label2: 'Sólo necesitas un temario y empezar a grabarte. Nosotros te acompañaremos en todo el proceso hasta la publicación del curso.',
        imgPath: '/img/teacher2_800.png',
    },
];

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 55,
        [theme.breakpoints.up('sm')]: {
            marginTop: 65,
        },
    },
    label1: {
        padding: '50px',
        paddingBottom: '0px',
        [theme.breakpoints.up('sm')]: {
            padding: '100px',
            paddingRight: '0px',
        },
    },
    img: {
        display: 'block',
        width: '100%',
        overflow: 'hidden'
    },
}));

const HomeCarousel = props => {

    const classes = useStyles();
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = tutorialSteps.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const handleDialogClick = (dialogType) => {
        props.gOpenDialog(dialogType);
    }

    return (
        <div className={classes.root}>
            <ContactDialog />
            <AutoPlaySwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
                interval={30000}
            >
                {tutorialSteps.map((step, index) => (
                    <Grid container key={index} style={{ backgroundColor: 'rgb(17 51 81 / 42%)' }}>
                        {Math.abs(activeStep - index) <= 2 ? (
                            <React.Fragment>
                                <Grid item xs={12} sm={6} className={classes.label1}>
                                    <Typography variant="h4" style={{ fontWeight: 'bold', color: 'white' }}>{step.label1}</Typography>
                                    <br /><br />
                                    <Typography variant="h6" style={{ color: 'white' }}>{step.label2}</Typography>
                                    <br /><br />
                                    <Button size="large" variant="contained" color="secondary" onClick={() => handleDialogClick('contact')}>
                                        ¡Quiero que me contacten!
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <img className={classes.img} src={step.imgPath} alt={step.label1} />
                                </Grid>
                            </React.Fragment>
                        ) : null}
                    </Grid>
                ))}
            </AutoPlaySwipeableViews>
            <MobileStepper
                steps={maxSteps}
                position="static"
                variant="dots"
                activeStep={activeStep}
                nextButton={
                    <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                    </Button>
                }
                backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                    </Button>
                }
            />
        </div>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        gOpenDialog: (dialogType) => dispatch(actions.openDialog(dialogType)),
    }
};

export default connect(null, mapDispatchToProps)(HomeCarousel);