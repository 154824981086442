import React from 'react';
import Layout from 'components/common/layout/MainLayout';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const styles = (theme) => ({
    root: {
        flexGrow: 1,
        margin: 20,
        marginTop: theme.spacing(10),
    }
});

const NotFound = (props) => {
    const { classes } = props;
    return (
        <Layout>
            <div className={classes.root}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                        <Grid container spacing={2}>
                            vvvv
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </Layout>
    );
};

export default withStyles(styles)(NotFound);