import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import Layout from 'components/common/layout/MainLayout';

const AdminRoute = ({ path, component: Component }) => {
  const { id, isAdmin } = useSelector(
    state => ({
      id: state.auth.user.id,
      isAdmin: state.auth.user.isAdmin
    }),
    shallowEqual
  );

  return (
    <Layout>
      <Route
        exact
        path={path}
        render={() => (id && isAdmin ? <Component /> : <Redirect to="/" />)}
      />
    </Layout>
  );
};

AdminRoute.propType = {
  path: PropTypes.string.isRequired,
  component: PropTypes.element.isRequired
};

export default AdminRoute;