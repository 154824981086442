import { createAction } from 'redux-act';

export const PREFERENCES_SET_LOCALE = createAction('PREFERENCES_SET_LOCALE');

export const AUTH_SIGN_IN_INIT = createAction('AUTH_SIGN_IN_INIT');
export const AUTH_SIGN_IN_FAIL = createAction('AUTH_SIGN_IN_FAIL');
export const AUTH_SIGN_UP_INIT = createAction('AUTH_SIGN_UP_INIT');
export const AUTH_SIGN_UP_FAIL = createAction('AUTH_SIGN_UP_FAIL');
export const AUTH_SIGN_UP_SUCCESS = createAction('AUTH_SIGN_UP_SUCCESS');
export const AUTH_RESTORE_SESSION_INIT = createAction('AUTH_RESTORE_SESSION_INIT');
export const AUTH_RESTORE_SESSION_SUCCESS = createAction('AUTH_RESTORE_SESSION_SUCCESS');
export const AUTH_RESTORE_SESSION_FAIL = createAction('AUTH_RESTORE_SESSION_FAIL');
export const AUTH_LOGOUT_INIT = createAction('AUTH_LOGOUT_INIT');
export const AUTH_LOGOUT_SUCCESS = createAction('AUTH_LOGOUT_SUCCESS');
export const AUTH_SET_PASSWORD_INIT = createAction('AUTH_SET_PASSWORD_INIT');
export const AUTH_SET_PASSWORD_SUCCESS = createAction('AUTH_SET_PASSWORD_SUCCESS');
export const AUTH_SET_PASSWORD_FAIL = createAction('AUTH_SET_PASSWORD_FAIL');
export const AUTH_RESET_PASSWORD_INIT = createAction('AUTH_RESET_PASSWORD_INIT');
export const AUTH_RESET_PASSWORD_SUCCESS = createAction('AUTH_RESET_PASSWORD_SUCCESS');
export const AUTH_RESET_PASSWORD_FAIL = createAction('AUTH_RESET_PASSWORD_FAIL');
export const AUTH_CLEAN_UP = createAction('AUTH_CLEAN_UP');
export const AUTH_FETCH_USER_DATA_INIT = createAction('AUTH_FETCH_USER_DATA_INIT');
export const AUTH_FETCH_USER_DATA_SUCCESS = createAction('AUTH_FETCH_USER_DATA_SUCCESS');
export const AUTH_FETCH_USER_DATA_FAIL = createAction('AUTH_FETCH_USER_DATA_FAIL');
export const AUTH_CHANGE_PASSWORD_INIT = createAction('AUTH_CHANGE_PASSWORD_INIT');
export const AUTH_CHANGE_PASSWORD_SUCCESS = createAction('AUTH_CHANGE_PASSWORD_SUCCESS');
export const AUTH_CHANGE_PASSWORD_FAIL = createAction('AUTH_CHANGE_PASSWORD_FAIL');
export const AUTH_UPDATE_USER_DATA = createAction('AUTH_UPDATE_USER_DATA');
export const AUTH_PROVIDER_INIT = createAction('AUTH_PROVIDER_INIT');
export const AUTH_PROVIDER_SUCCESS = createAction('AUTH_PROVIDER_SUCCESS');
export const AUTH_PROVIDER_FAIL = createAction('AUTH_PROVIDER_FAIL');

export const USERS_FETCH_DATA_INIT = createAction('USERS_FETCH_DATA_INIT');
export const USERS_FETCH_DATA_SUCCESS = createAction('USERS_FETCH_DATA_SUCCESS');
export const USERS_FETCH_DATA_FAIL = createAction('USERS_FETCH_DATA_FAIL');
export const USERS_DELETE_USER_INIT = createAction('USERS_DELETE_USER_INIT');
export const USERS_DELETE_USER_SUCCESS = createAction('USERS_DELETE_USER_SUCCESS');
export const USERS_DELETE_USER_FAIL = createAction('USERS_DELETE_USER_FAIL');
export const USERS_CLEAR_DATA = createAction('USERS_CLEAR_DATA');
export const USERS_CREATE_USER_INIT = createAction('USERS_CREATE_USER_INIT');
export const USERS_CREATE_USER_SUCCESS = createAction('USERS_CREATE_USER_SUCCESS');
export const USERS_CREATE_USER_FAIL = createAction('USERS_CREATE_USER_FAIL');
export const USERS_MODIFY_USER_INIT = createAction('USERS_MODIFY_USER_INIT');
export const USERS_MODIFY_USER_SUCCESS = createAction('USERS_MODIFY_USER_SUCCESS');
export const USERS_MODIFY_USER_FAIL = createAction('USERS_MODIFY_USER_FAIL');
export const USERS_CLEAN_UP = createAction('USERS_CLEAN_UP');
export const USERS_CLEAR_DATA_LOGOUT = createAction('USERS_CLEAR_DATA_LOGOUT');

export const APP_OPEN_DIALOG = createAction('APP_OPEN_DIALOG');
export const APP_CLOSE_DIALOG = createAction('APP_CLOSE_DIALOG');
export const APP_SUBMIT_START  = createAction('APP_SUBMIT_START');
export const APP_SUBMIT_SUCCESS = createAction('APP_SUBMIT_SUCCESS');
export const APP_SUBMIT_FAIL = createAction('APP_SUBMIT_FAIL');
export const APP_SUBMIT_END = createAction('APP_SUBMIT_END');
export const APP_SHOW_ALERT = createAction('APP_SHOW_ALERT');
export const APP_HIDE_ALERT = createAction('APP_HIDE_ALERT');
export const USER_SUBMIT_START  = createAction('USER_SUBMIT_START');
export const USER_SUBMIT_SUCCESS = createAction('USER_SUBMIT_SUCCESS');
export const USER_SUBMIT_FAIL = createAction('USER_SUBMIT_FAIL');
export const USER_SUBMIT_END = createAction('USER_SUBMIT_END');
export const USER_LIST_START  = createAction('USER_LIST_START');
export const USER_LIST_SUCCESS = createAction('USER_LIST_SUCCESS');
export const USER_LIST_FAIL = createAction('USER_LIST_FAIL');
export const USER_LIST_END = createAction('USER_LIST_END');
export const USER_ITEM_START  = createAction('USER_ITEM_START');
export const USER_ITEM_SUCCESS = createAction('USER_ITEM_SUCCESS');
export const USER_ITEM_FAIL = createAction('USER_ITEM_FAIL');
export const USER_ITEM_END = createAction('USER_ITEM_END');
export const USER_DELETE_START  = createAction('USER_DELETE_START');
export const USER_DELETE_SUCCESS = createAction('USER_DELETE_SUCCESS');
export const USER_DELETE_FAIL = createAction('USER_DELETE_FAIL');
export const USER_DELETE_END = createAction('USER_DELETE_END');
export const CATEGORY_SUBMIT_START  = createAction('CATEGORY_SUBMIT_START');
export const CATEGORY_SUBMIT_SUCCESS = createAction('CATEGORY_SUBMIT_SUCCESS');
export const CATEGORY_SUBMIT_FAIL = createAction('CATEGORY_SUBMIT_FAIL');
export const CATEGORY_SUBMIT_END = createAction('CATEGORY_SUBMIT_END');
export const CATEGORY_LIST_START  = createAction('CATEGORY_LIST_START');
export const CATEGORY_LIST_SUCCESS = createAction('CATEGORY_LIST_SUCCESS');
export const CATEGORY_LIST_FAIL = createAction('CATEGORY_LIST_FAIL');
export const CATEGORY_LIST_END = createAction('CATEGORY_LIST_END');
export const CATEGORY_ITEM_START  = createAction('CATEGORY_ITEM_START');
export const CATEGORY_ITEM_SUCCESS = createAction('CATEGORY_ITEM_SUCCESS');
export const CATEGORY_ITEM_FAIL = createAction('CATEGORY_ITEM_FAIL');
export const CATEGORY_ITEM_END = createAction('CATEGORY_ITEM_END');
export const CATEGORY_DELETE_START  = createAction('CATEGORY_DELETE_START');
export const CATEGORY_DELETE_SUCCESS = createAction('CATEGORY_DELETE_SUCCESS');
export const CATEGORY_DELETE_FAIL = createAction('CATEGORY_DELETE_FAIL');
export const CATEGORY_DELETE_END = createAction('CATEGORY_DELETE_END');
export const COMPANY_SUBMIT_START  = createAction('COMPANY_SUBMIT_START');
export const COMPANY_SUBMIT_SUCCESS = createAction('COMPANY_SUBMIT_SUCCESS');
export const COMPANY_SUBMIT_FAIL = createAction('COMPANY_SUBMIT_FAIL');
export const COMPANY_SUBMIT_END = createAction('COMPANY_SUBMIT_END');
export const COMPANY_LIST_START  = createAction('COMPANY_LIST_START');
export const COMPANY_LIST_SUCCESS = createAction('COMPANY_LIST_SUCCESS');
export const COMPANY_LIST_FAIL = createAction('COMPANY_LIST_FAIL');
export const COMPANY_LIST_END = createAction('COMPANY_LIST_END');
export const COMPANY_ITEM_START  = createAction('COMPANY_ITEM_START');
export const COMPANY_ITEM_SUCCESS = createAction('COMPANY_ITEM_SUCCESS');
export const COMPANY_ITEM_FAIL = createAction('COMPANY_ITEM_FAIL');
export const COMPANY_ITEM_END = createAction('COMPANY_ITEM_END');
export const COMPANY_DELETE_START  = createAction('COMPANY_DELETE_START');
export const COMPANY_DELETE_SUCCESS = createAction('COMPANY_DELETE_SUCCESS');
export const COMPANY_DELETE_FAIL = createAction('COMPANY_DELETE_FAIL');
export const COMPANY_DELETE_END = createAction('COMPANY_DELETE_END');
export const COMPANY_USER_SUBMIT_START  = createAction('COMPANY_USER_SUBMIT_START');
export const COMPANY_USER_SUBMIT_SUCCESS = createAction('COMPANY_USER_SUBMIT_SUCCESS');
export const COMPANY_USER_SUBMIT_FAIL = createAction('COMPANY_USER_SUBMIT_FAIL');
export const COMPANY_USER_SUBMIT_END = createAction('COMPANY_USER_SUBMIT_END');
export const COMPANY_USER_LIST_START  = createAction('COMPANY_USER_LIST_START');
export const COMPANY_USER_LIST_SUCCESS = createAction('COMPANY_USER_LIST_SUCCESS');
export const COMPANY_USER_LIST_FAIL = createAction('COMPANY_USER_LIST_FAIL');
export const COMPANY_USER_LIST_END = createAction('COMPANY_USER_LIST_END');
export const COMPANY_USER_ITEM_START  = createAction('COMPANY_USER_ITEM_START');
export const COMPANY_USER_ITEM_SUCCESS = createAction('COMPANY_USER_ITEM_SUCCESS');
export const COMPANY_USER_ITEM_FAIL = createAction('COMPANY_USER_ITEM_FAIL');
export const COMPANY_USER_ITEM_END = createAction('COMPANY_USER_ITEM_END');
export const COMPANY_USER_DELETE_START  = createAction('COMPANY_USER_DELETE_START');
export const COMPANY_USER_DELETE_SUCCESS = createAction('COMPANY_USER_DELETE_SUCCESS');
export const COMPANY_USER_DELETE_FAIL = createAction('COMPANY_USER_DELETE_FAIL');
export const COMPANY_USER_DELETE_END = createAction('COMPANY_USER_DELETE_END');
export const COURSE_SUBMIT_START  = createAction('COURSE_SUBMIT_START');
export const COURSE_SUBMIT_SUCCESS = createAction('COURSE_SUBMIT_SUCCESS');
export const COURSE_SUBMIT_FAIL = createAction('COURSE_SUBMIT_FAIL');
export const COURSE_SUBMIT_END = createAction('COURSE_SUBMIT_END');
export const COURSE_LIST_START  = createAction('COURSE_LIST_START');
export const COURSE_LIST_SUCCESS = createAction('COURSE_LIST_SUCCESS');
export const COURSE_LIST_FAIL = createAction('COURSE_LIST_FAIL');
export const COURSE_LIST_END = createAction('COURSE_LIST_END');
export const COURSE_ITEM_START  = createAction('COURSE_ITEM_START');
export const COURSE_ITEM_SUCCESS = createAction('COURSE_ITEM_SUCCESS');
export const COURSE_ITEM_FAIL = createAction('COURSE_ITEM_FAIL');
export const COURSE_ITEM_END = createAction('COURSE_ITEM_END');
export const COURSE_DELETE_START  = createAction('COURSE_DELETE_START');
export const COURSE_DELETE_SUCCESS = createAction('COURSE_DELETE_SUCCESS');
export const COURSE_DELETE_FAIL = createAction('COURSE_DELETE_FAIL');
export const COURSE_DELETE_END = createAction('COURSE_DELETE_END');
export const COURSE_INSTRUCTOR_SUBMIT_START  = createAction('COURSE_INSTRUCTOR_SUBMIT_START');
export const COURSE_INSTRUCTOR_SUBMIT_SUCCESS = createAction('COURSE_INSTRUCTOR_SUBMIT_SUCCESS');
export const COURSE_INSTRUCTOR_SUBMIT_FAIL = createAction('COURSE_INSTRUCTOR_SUBMIT_FAIL');
export const COURSE_INSTRUCTOR_SUBMIT_END = createAction('COURSE_INSTRUCTOR_SUBMIT_END');
export const COURSE_INSTRUCTOR_LIST_START  = createAction('COURSE_INSTRUCTOR_LIST_START');
export const COURSE_INSTRUCTOR_LIST_SUCCESS = createAction('COURSE_INSTRUCTOR_LIST_SUCCESS');
export const COURSE_INSTRUCTOR_LIST_FAIL = createAction('COURSE_INSTRUCTOR_LIST_FAIL');
export const COURSE_INSTRUCTOR_LIST_END = createAction('COURSE_INSTRUCTOR_LIST_END');
export const COURSE_INSTRUCTOR_ITEM_START  = createAction('COURSE_INSTRUCTOR_ITEM_START');
export const COURSE_INSTRUCTOR_ITEM_SUCCESS = createAction('COURSE_INSTRUCTOR_ITEM_SUCCESS');
export const COURSE_INSTRUCTOR_ITEM_FAIL = createAction('COURSE_INSTRUCTOR_ITEM_FAIL');
export const COURSE_INSTRUCTOR_ITEM_END = createAction('COURSE_INSTRUCTOR_ITEM_END');
export const COURSE_INSTRUCTOR_DELETE_START  = createAction('COURSE_INSTRUCTOR_DELETE_START');
export const COURSE_INSTRUCTOR_DELETE_SUCCESS = createAction('COURSE_INSTRUCTOR_DELETE_SUCCESS');
export const COURSE_INSTRUCTOR_DELETE_FAIL = createAction('COURSE_INSTRUCTOR_DELETE_FAIL');
export const COURSE_INSTRUCTOR_DELETE_END = createAction('COURSE_INSTRUCTOR_DELETE_END');
export const TOPIC_SUBMIT_START  = createAction('TOPIC_SUBMIT_START');
export const TOPIC_SUBMIT_SUCCESS = createAction('TOPIC_SUBMIT_SUCCESS');
export const TOPIC_SUBMIT_FAIL = createAction('TOPIC_SUBMIT_FAIL');
export const TOPIC_SUBMIT_END = createAction('TOPIC_SUBMIT_END');
export const TOPIC_LIST_START  = createAction('TOPIC_LIST_START');
export const TOPIC_LIST_SUCCESS = createAction('TOPIC_LIST_SUCCESS');
export const TOPIC_LIST_FAIL = createAction('TOPIC_LIST_FAIL');
export const TOPIC_LIST_END = createAction('TOPIC_LIST_END');
export const TOPIC_ITEM_START  = createAction('TOPIC_ITEM_START');
export const TOPIC_ITEM_SUCCESS = createAction('TOPIC_ITEM_SUCCESS');
export const TOPIC_ITEM_FAIL = createAction('TOPIC_ITEM_FAIL');
export const TOPIC_ITEM_END = createAction('TOPIC_ITEM_END');
export const TOPIC_DELETE_START  = createAction('TOPIC_DELETE_START');
export const TOPIC_DELETE_SUCCESS = createAction('TOPIC_DELETE_SUCCESS');
export const TOPIC_DELETE_FAIL = createAction('TOPIC_DELETE_FAIL');
export const TOPIC_DELETE_END = createAction('TOPIC_DELETE_END');
export const CONTACT_SUBMIT_START  = createAction('CONTACT_SUBMIT_START');
export const CONTACT_SUBMIT_SUCCESS = createAction('CONTACT_SUBMIT_SUCCESS');
export const CONTACT_SUBMIT_FAIL = createAction('CONTACT_SUBMIT_FAIL');
export const CONTACT_SUBMIT_END = createAction('CONTACT_SUBMIT_END');
export const CONTACT_LIST_START  = createAction('CONTACT_LIST_START');
export const CONTACT_LIST_SUCCESS = createAction('CONTACT_LIST_SUCCESS');
export const CONTACT_LIST_FAIL = createAction('CONTACT_LIST_FAIL');
export const CONTACT_LIST_END = createAction('CONTACT_LIST_END');
export const CONTACT_ITEM_START  = createAction('CONTACT_ITEM_START');
export const CONTACT_ITEM_SUCCESS = createAction('CONTACT_ITEM_SUCCESS');
export const CONTACT_ITEM_FAIL = createAction('CONTACT_ITEM_FAIL');
export const CONTACT_ITEM_END = createAction('CONTACT_ITEM_END');
export const CONTACT_DELETE_START  = createAction('CONTACT_DELETE_START');
export const CONTACT_DELETE_SUCCESS = createAction('CONTACT_DELETE_SUCCESS');
export const CONTACT_DELETE_FAIL = createAction('CONTACT_DELETE_FAIL');
export const CONTACT_DELETE_END = createAction('CONTACT_DELETE_END');

export {
    auth,
    authLogout,
    authEnd,
    authForgotPassRecovery,
    authChangePass
} from './auth';

export {
    openDialog,
    closeDialog,
    showAlert,
    hideAlert
} from './app';

export {
    categorySubmitItem,
    categoryFetchItem,
    categoryDeleteItem,
    categoryFetchList
} from './category';

export {
    userFetchItemByUsername,
    userFetchItemByUserId,
    userSubmitItem,
} from './user';

export {
    companyFetchListByUserId,
    companySubmitItem,
    companyFetchItemByAlias,
    companyFetchItemByCompanyId,
} from './company';

export {
    companyUserFetchListByCompanyId,
    companyUserDeleteItem,
    companyUserSubmitItem,
    companyUserUpdateItem
} from './companyUser';

export {
    courseSubmitItem,
    courseFetchItemByCourseId,
    courseFetchListByUserId,
    courseFetchListByCompanyId
} from './course';

export {
    courseInstructorFetchListByCourseId
} from './courseInstructor';

export {
    topicFetchListByCourseId,
    topicDeleteItem,
    topicSubmitItem
} from './topic';

export {
    contactSubmitItem
} from './contact';