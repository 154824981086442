import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Translate } from "react-localize-redux";
import * as appParams from 'config/params';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import CourseCard from 'components/common/card/CourseCard';
import Grid from '@material-ui/core/Grid';
import CreateCourseIcon from '@material-ui/icons/OndemandVideo';
import Button from '@material-ui/core/Button';
import UpdateIcon from '@material-ui/icons/Create';

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: '100%',
        height: 350,
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        //transform: 'translateZ(0)',
    },
    titleBar: {
        background:
            'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
            'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    icon: {
        color: 'white',
    },
});

const UserProfileCourses = props => {

    const { classes } = props;
    return (
        props.list.length > 0 ?
            <React.Fragment>
                <Typography variant="h6" style={{ paddingBottom: '15px' }}>
                    Cursos como Instructor
            </Typography>
                <Grid container spacing={2}>
                    {props.list.map((course, index) => (
                        <CourseCard
                            key={index}
                            item={course}
                        />
                    ))}
                </Grid>
                {1 == 0 ?
                    <React.Fragment>
                        <Typography gutterBottom variant="button">
                            Cursos como Estudiante
                        </Typography>                        
                    </React.Fragment> : null
                }
            </React.Fragment> :
            <div>
                {!props.item.usernameAt ?
                    <div style={{ textAlign: 'center', marginTop: '40px' }}>
                        <Typography color="textSecondary" align="center">
                            ¡Personaliza tu Perfil para que tus alumnos conozcan más de ti!
                    </Typography>
                        <br />
                        <Link to="/user/update/[userId]" as={"/user/update/" + props.item.userId}>
                            <a style={{ color: "inherit", textDecoration: "none" }}>
                                <Button
                                    variant="outlined"
                                    color="default"
                                    style={{ marginRight: '15px' }}
                                    startIcon={<UpdateIcon />}
                                >
                                    <Translate id="profileEdit" />
                                </Button>
                            </a>
                        </Link>
                    </div> : null}
                <div style={{ textAlign: 'center', marginTop: '40px' }}>
                    <Typography color="textSecondary" align="center">
                        ¿Tienes alguna idea en mente sobre un curso que te gustaría enseñar?
                    </Typography>
                    <br />
                    <Link to="/course/create/[userId]" as={"/course/create/" + props.item.userId}>
                        <a style={{ color: "inherit", textDecoration: "none" }}>
                            <Button
                                variant="outlined"
                                color="default"
                                style={{ marginRight: '15px' }}
                                startIcon={<CreateCourseIcon />}
                            >
                                <Translate id="createCourse" />
                            </Button>
                        </a>
                    </Link>
                </div>
            </div>
    );
}

export default withStyles(styles)(UserProfileCourses);