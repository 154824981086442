import React from 'react';
import { Translate } from "react-localize-redux";
import * as action from 'state/actions';
import firebase from 'firebase.js';

export function contactSubmitItem(formData) {
    return async (dispatch) => {
        dispatch(action.CONTACT_SUBMIT_START());
        const contactData = {
            firstname: formData.firstname,
            surname: formData.surname,
            email: formData.email,
            phone: formData.phone,
            courses: formData.courses
        };
        try {
            await firebase.firestore().collection('contacts').add(contactData).then()
        } catch (error) {
            return dispatch(action.CONTACT_SUBMIT_FAIL({ error: error }));
        }
        return dispatch(action.CONTACT_SUBMIT_SUCCESS({ fullName: formData.firstname }));
    }
};
