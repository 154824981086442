import React from 'react';
import * as appParams from 'config/params';
import * as actionTypes from './actionTypes';
import { Translate } from "react-localize-redux";
import axios from 'axios';
import * as courseInstructor from './courseInstructor';
import * as topic from './topic';
import * as action from 'state/actions';
import firebase from 'firebase.js';

export function courseFetchItemByCourseId(courseId) {
    return dispatch => {
        dispatch(courseItemStart());
        axios.post(appParams.WS_BACKEND + '/course/getByCourseId', { courseId: courseId })
            .then(response => {
                dispatch(courseItemSuccess(response.data));
                dispatch(courseInstructor.courseInstructorFetchListByCourseId(courseId));
                dispatch(topic.topicFetchListByCourseId(courseId));
            })
            .catch(error => {
                dispatch(courseItemFail(error.response.data.error));
                dispatch(showAlert(error.response.data.error, "error"));
            });
    };
};

export function courseSubmitItem(formData, categoryList, companyList) {
    return async (dispatch, getState) => {
        dispatch(action.COURSE_SUBMIT_START());
        const userAuth = getState().auth.user;
        let courseId = formData.courseId ? formData.courseId : null;
        // Get Company Array
        const company = companyList.filter(obj => {
            return obj.id === formData.companyId
        });
        // Get Category Array
        const category = categoryList.filter(obj => {
            return obj.id === formData.categoryId
        });
        if (courseId) { // Update Course                 

        } else { // New Course
            // Course data
            const courseData = {
                status: 0,
                company: company[0] ? company[0] : null,
                category: category[0],
                name: formData.name,
                description: formData.description,
                purpose: formData.purpose,
                audience: formData.audience,
                requirements: formData.requirements,
                owner: userAuth
            };
            try {
                await firebase.firestore().collection('courses').add(courseData)
                    .then(function (course) {
                        courseId = course.id;                        
                        dispatch(showAlert('CREATED', 'success'));
                    })
            } catch (error) {
                dispatch(showAlert('', 'error'));
                return dispatch(action.COURSE_SUBMIT_FAIL({ error: error }));
            }
        }
        return dispatch(action.COURSE_SUBMIT_SUCCESS());
    }    
};

export function courseFetchListByUserId(userId) {
    return async (dispatch) => {
        dispatch(action.COURSE_LIST_START());
        let courses = [];
        try {
            await firebase.firestore().collection('courses').where("owner.id", "==", userId).get()
                .then((query) => {
                    query.forEach(function (doc) {
                        courses.push({ id: doc.id, ...doc.data() });
                    });
                });            
        } catch (error) {
            console.log(error);
            return dispatch(action.COURSE_LIST_FAIL({ error: error }));
        }
        console.log(courses);
        return dispatch(action.COURSE_LIST_SUCCESS({ data: courses }));
    };
    return dispatch => {
        dispatch(courseListStart());
        axios.post(appParams.WS_BACKEND + '/course/listByUserId', { userId: userId })
            .then(response => {
                dispatch(courseListSuccess(response.data));
            })
            .catch(error => {
                dispatch(courseListFail(error));
                dispatch(showAlert(error.response.data.error, "error"))
            });
    };
};

export function courseFetchListByCompanyId(companyId) {
    return dispatch => {
        dispatch(courseListStart());
        axios.post(appParams.WS_BACKEND + '/course/listByCompanyId', { companyId: companyId })
            .then(response => {
                dispatch(courseListSuccess(response.data));
            })
            .catch(error => {
                dispatch(courseListFail(error));
                dispatch(showAlert(error.response.data.error, "error"))
            });
    };
};

export function showAlert(message, alertType) {
    return async (dispatch, getState) => {
        let msj = '';
        switch (message) {
            case 'CREATED':
                msj = <Translate id="successCreated" data={{ name: <Translate id="course" /> }} />;
                break;
            case 'UPDATED':
                msj = <Translate id="successUpdated" data={{ name: <Translate id="course" /> }} />;
                break;
            case 'DELETED':
                msj = <Translate id="successDeleted" data={{ name: <Translate id="course" /> }} />;
                break;
            case 'CHILD_CONSTRAINT':
                msj = <Translate id="childConstraint" data={{ name: <Translate id="course" /> }} />;
                break;
            case 'NOT_FOUND':
                msj = <Translate id="notFound" data={{ name: <Translate id="course" /> }} />;
                break;
            case 'ALREADY_EXISTS':
                msj = <Translate id="alreadyExists" data={{ name: <Translate id="course" /> }} />;
                break;
            default:
                msj = alertType == "error" ? <Translate id="error" /> : "";
                break;
        }
        dispatch(action.APP_SHOW_ALERT({ message: msj, alertType: alertType }));
    }
};


export function courseSubmitStart() {
    return {
        type: actionTypes.COURSE_SUBMIT_START
    };
};

export function courseSubmitSuccess() {
    return {
        type: actionTypes.COURSE_SUBMIT_SUCCESS
    };
};

export function courseSubmitFail(error) {
    //let errorMessage = <Translate id="error" />;
    let errorMessage = error;
    return {
        type: actionTypes.COURSE_SUBMIT_FAIL,
        error: errorMessage
    };
};

export function courseSubmitEnd() {
    return {
        type: actionTypes.COURSE_SUBMIT_END
    };
};

export function courseListStart() {
    return {
        type: actionTypes.COURSE_LIST_START
    };
};

export function courseListSuccess(data) {
    return {
        type: actionTypes.COURSE_LIST_SUCCESS,
        data: data
    };
};

export function courseListFail(error) {
    //let errorMessage = <Translate id="error" />;
    let errorMessage = error;
    return {
        type: actionTypes.COURSE_LIST_FAIL,
        error: errorMessage
    };
};

export function courseListEnd() {
    return {
        type: actionTypes.COURSE_LIST_END
    };
};

export function courseItemStart() {
    return {
        type: actionTypes.COURSE_ITEM_START
    };
};

export function courseItemSuccess(data) {
    return {
        type: actionTypes.COURSE_ITEM_SUCCESS,
        data: data
    };
};

export function courseItemFail(error) {
    //let errorMessage = <Translate id="error" />;
    let errorMessage = error;
    return {
        type: actionTypes.COURSE_ITEM_FAIL,
        error: errorMessage
    };
};

export function courseItemEnd() {
    return {
        type: actionTypes.COURSE_ITEM_END
    };
};

export function courseDeleteStart() {
    return {
        type: actionTypes.COURSE_DELETE_START
    };
};

export function courseDeleteSuccess() {
    return {
        type: actionTypes.COURSE_DELETE_SUCCESS
    };
};

export function courseDeleteFail(error) {
    //let errorMessage = <Translate id="error" />;
    let errorMessage = error;
    return {
        type: actionTypes.COURSE_DELETE_FAIL,
        error: errorMessage
    };
};

export function courseDeleteEnd() {
    return {
        type: actionTypes.COURSE_DELETE_END
    };
};

export function showAlert2(message, alertType) {
    let msj = '';
    switch (message) {
        case 'CREATED':
            msj = <Translate id="successCreated" data={{ name: <Translate id="course" /> }} />;
            break;
        case 'UPDATED':
            msj = <Translate id="successUpdated" data={{ name: <Translate id="course" /> }} />;
            break;
        case 'DELETED':
            msj = <Translate id="successDeleted" data={{ name: <Translate id="course" /> }} />;
            break;
        case 'CHILD_CONSTRAINT':
            msj = <Translate id="childConstraint" data={{ name: <Translate id="course" /> }} />;
            break;
        case 'NOT_FOUND':
            msj = <Translate id="notFound" data={{ name: <Translate id="course" /> }} />;
            break;
        case 'ALREADY_EXISTS':
            msj = <Translate id="alreadyExists" data={{ name: <Translate id="course" /> }} />;
            break;
        default:
            msj = alertType == "error" ? <Translate id="error" /> : "";
            break;
    }

    return {
        type: actionTypes.APP_SHOW_ALERT,
        message: msj,
        alertType: alertType
    };
};