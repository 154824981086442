import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import CreateCourseIcon from '@material-ui/icons/OndemandVideo';
import CreateServiceIcon from '@material-ui/icons/StoreMallDirectory';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import { Translate } from "react-localize-redux";

const styles = theme => ({
    back: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        zIndex: 2000,
        position: 'fixed',
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    list: {
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 90,
        left: 'auto',
        position: 'fixed',
        zIndex: 2001,
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        border: 0,
        borderRadius: 3,
    },
    fab: {
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 20,
        left: 'auto',
        position: 'fixed',
        zIndex: 2001,
    },
});

const FloatingActionButton = props => {

    const [showOptions, setShowOptions] = React.useState(false);

    const handleItemClick = () => {
        setShowOptions(!showOptions);
    }

    const { classes } = props;
    let optionList = showOptions ? (
        <React.Fragment><div className={classes.back} onClick={handleItemClick}></div>
            <List component="nav" aria-label="main mailbox folders" className={classes.list}>
                <Link to="/course/create">
                    <a style={{ color: "inherit", textDecoration: "none" }}>
                        <ListItem button>
                            <ListItemIcon>
                                <CreateCourseIcon />
                            </ListItemIcon>
                            <ListItemText><Translate id="createCourse" /></ListItemText>
                        </ListItem>
                    </a>
                </Link>
                <Link to="/">
                    <a style={{ color: "inherit", textDecoration: "none" }}>
                        <ListItem button>
                            <ListItemIcon>
                                <CreateServiceIcon />
                            </ListItemIcon>
                            <ListItemText><Translate id="createService" /></ListItemText>
                        </ListItem>
                    </a>
                </Link>
            </List>
        </React.Fragment>) : null;
    return (
        <React.Fragment>
            {optionList}
            <Fab color="secondary" aria-label="add" className={classes.fab} onClick={handleItemClick}>
                <AddIcon />
            </Fab>
        </React.Fragment>
    );
}

export default withStyles(styles)(FloatingActionButton);