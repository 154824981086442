import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import ReduxToastr from 'react-redux-toastr';
import { LocalizeProvider } from 'react-localize-redux';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import { configureStore } from './state/store';
import Router from 'pages/Router';
import * as serviceWorker from './serviceWorker';

import { ThemeProvider } from "@material-ui/core";
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme } from '@material-ui/core/styles';
import * as appParams from 'config/params';

const { store, persistor } = configureStore({});
const theme = createMuiTheme(appParams.DEFAULT_MATERIAL_UI_THEME);

const app = (
  <Provider store={store}>
    <LocalizeProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <PersistGate persistor={persistor}>
          <ReduxToastr
            newestOnTop={false}
            preventDuplicates
            position="bottom-right"
            getState={(state) => state.toastr}
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar
            closeOnToastrClick
          />
          <Router />
        </PersistGate>
      </ThemeProvider>
    </LocalizeProvider>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

serviceWorker.unregister();
