import React from 'react';
import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import HomeIcon from '@material-ui/icons/Home';
import UpdateIcon from '@material-ui/icons/Create';
import BusinessIcon from '@material-ui/icons/Business';
import CreateCourseIcon from '@material-ui/icons/OndemandVideo';
import { Translate } from "react-localize-redux";

const styles = theme => ({    
});

const UserProfileButtons = props => {
    const { classes } = props;
    return (
        <BottomNavigation
            value={props.tabValue}
            onChange={(event, newValue) => {
                if (newValue == 0) {
                    props.history.push('/user/' + props.item.username);
                } else if (newValue == 1) {
                    props.history.push('/user/profile/update');
                } else if (newValue == 2) {
                    props.history.push('/company/profile/create');
                }
            }}
            showLabels
            style={{ width: '100%' }}
        >
            <BottomNavigationAction label={<Translate id="home" />} icon={<HomeIcon />} />
            <BottomNavigationAction label={<Translate id="profileEdit" />} icon={<UpdateIcon />} />
            <BottomNavigationAction label={<Translate id="companyCreate" />} icon={<BusinessIcon />} />
        </BottomNavigation>
    );
}

export default withStyles(styles)(withRouter(UserProfileButtons));