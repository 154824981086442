import React from 'react';
import Typography from '@material-ui/core/Typography';
import MainLayout from 'components/common/layout/MainLayout';
import { withStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import red from '@material-ui/core/colors/red';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Translate, withLocalize } from "react-localize-redux";

const styles = theme => ({
    cardTitle: {
        lineHeight: 1.5,
    },
    cardTitleBox: {
        //backgroundColor: 'black',
        paddingBottom: 0,
    },
    cardTitleBoxLink: {
        textDecoration: 'none',
    },
    actions: {
        display: 'flex',
        //backgroundColor: 'black'
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    }
});

const NewsCard = props => {
    const { classes } = props;
    return <Grid item xs={12} sm={6} md={3} lg={3}>
        <Card className={classes.card}>
            <a href="#">
                <CardMedia
                    style={{
                        height: 0,
                        paddingTop: '56.25%', // 16:9                        
                    }}
                    image={props.img}
                    title="" />
            </a>
            <CardContent className={classes.cardTitleBox}>
                <Typography variant="h6" style={{ fontSize: '1rem', fontWeight: 'bolder', paddingBottom: '10px' }}>
                    {props.title}
                </Typography>
                <Typography style={{ fontSize: '13px', height: '110px' }}>
                    {props.description}
                </Typography>
            </CardContent>
            <CardActions className={classes.actions} disableSpacing>
                <Typography variant="h6" style={{ fontSize: '1rem', paddingLeft: '10px' }}>
                    {props.type}
                </Typography>
                <IconButton aria-label="Add to favorites" className={classes.expand}>
                    <FavoriteIcon />
                </IconButton>
            </CardActions>
        </Card>
    </Grid>;
}

export default withStyles(styles)(NewsCard);