import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import StarIcon from '@material-ui/icons/Star';
import WorkIcon from '@material-ui/icons/Work';
import SchoolIcon from '@material-ui/icons/School';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import IconButton from '@material-ui/core/IconButton';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

const styles = theme => ({
    avatar: {
        width: theme.spacing(15),
        height: theme.spacing(15),
        margin: 'auto'
    },
    star: {
        color: '#fab331',
        marginTop: '5px'
    },
    chip: {
        marginRight: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
    },
    section1: {
        margin: theme.spacing(3, 2),
    },
    section2: {
        margin: theme.spacing(2),
    },
    section3: {
        textAlign: 'center',
        margin: theme.spacing(1),
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(0.5),
    },
    icon: {
        position: "relative",
        top: theme.spacing(0.3),
        width: theme.typography.body2.fontSize,
        height: theme.typography.body2.fontSize
    }
});

const CompanySummary = props => {
    const { updatedCompanyPhoto } = useSelector(
        (state) => ({
            updatedCompanyPhoto: state.company.updatedCompanyPhoto,
        }),
        shallowEqual
    );
    const { classes } = props;
    return (
        <React.Fragment>
            {updatedCompanyPhoto ?
                <Avatar
                    src={updatedCompanyPhoto} className={classes.avatar}
                /> :
                <Avatar
                    src={props.item.photo ? props.item.photo : null} className={classes.avatar}
                />}
            <div className={classes.section1}>
                <Grid container alignItems="center">
                    <Grid item xs style={{ marginTop: '-8px' }}>
                        <Typography variant="h5" align="center">
                            {props.item.name}
                        </Typography>
                    </Grid>
                    {1 == 0 ? <Grid item>
                        <Typography gutterBottom variant="h6">
                            <Grid container alignItems="center">
                                <Grid item xs>
                                    <StarIcon className={classes.star} />
                                </Grid>
                                <Grid item>
                                    4.5
                                </Grid>
                            </Grid>
                        </Typography>
                    </Grid> : null}
                </Grid>
                <Typography color="textSecondary" variant="body2" align="center">
                    @{props.item.alias}
                </Typography>
                {props.item.description != null && props.item.description.trim() != '' ?
                    <div style={{ marginTop: '15px' }}>
                        {props.item.description.split("\n").map((line, index) => (
                            <Typography color="textSecondary" variant="body2" align="center" key={index} style={{ marginTop: '0px', marginBottom: '0px' }}>{line}</Typography>
                        ))}
                    </div> : null
                }
            </div>
            {((props.item.facebook != null && props.item.facebook != '')
                || (props.item.twitter != null && props.item.twitter != '')
                || (props.item.instagram != null && props.item.instagram != '')
                || (props.item.linkedin != null && props.item.linkedin != '')) ?
                <React.Fragment>
                    <div className={classes.section3}>
                        {props.item.facebook != null && props.item.facebook != '' ?
                            <a rel="nofollow noopener noreferrer" target="_blank" href={props.item.facebook}>
                                <IconButton>
                                    <FacebookIcon />
                                </IconButton>
                            </a> : null
                        }
                        {props.item.twitter != null && props.item.twitter != '' ?
                            <a rel="nofollow noopener noreferrer" target="_blank" href={props.item.twitter}>
                                <IconButton>
                                    <TwitterIcon />
                                </IconButton>
                            </a> : null
                        }
                        {props.item.instagram != null && props.item.instagram != '' ?
                            <a rel="nofollow noopener noreferrer" target="_blank" href={props.item.instagram}>
                                <IconButton>
                                    <InstagramIcon />
                                </IconButton>
                            </a> : null
                        }
                        {props.item.linkedin != null && props.item.linkedin != '' ?
                            <a rel="nofollow noopener noreferrer" target="_blank" href={props.item.linkedin}>
                                <IconButton>
                                    <LinkedInIcon />
                                </IconButton>
                            </a> : null
                        }
                    </div>
                </React.Fragment> : null
            }
        </React.Fragment >
    );
}

export default withStyles(styles)(CompanySummary);