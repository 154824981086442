import React from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import * as validator from 'utils/form/validator';
import { sortObjectByOrder, updateObject } from 'utils/common/object';
import * as actions from 'state/actions/index';
import FormElement from 'components/common/form/FormElement';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import DoneIcon from '@material-ui/icons/Done';
import { Translate } from "react-localize-redux";
import Alert from '@material-ui/lab/Alert';

const styles = theme => ({
    content: {
        '&:first-child': {
            paddingTop: theme.spacing(0),
        }
    },
    description: {
        fontSize: 13,
        marginBottom: theme.spacing(2)
    },
    spinner: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: theme.spacing(4)
    },
    error: {
        textAlign: 'center',
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(3),
        color: 'red'
    },
    forgotLink: {
        textDecoration: 'none'
    },
    forgotText: {
        marginTop: theme.spacing(2),
        color: 'rgba(0, 0, 0, 0.54)'
    },
    icon: {
        margin: theme.spacing(1),
        fontSize: 50,
        color: 'green'
    }
});

const HeaderDialog = props => {
    const [signInForm, setSignInForm] = React.useState(
        {
            email: {
                type: validator.FORM_ELEMENT_INPUT_EMAIL,
                value: '',
                config: {
                    label: <Translate id="email" />,
                    validation: {
                        required: {
                            error: <Translate id="notEmpty" data={{ name: <Translate id="email" /> }} />
                        },
                        isEmail: {
                            error: <Translate id="notValid" data={{ name: <Translate id="email" /> }} />
                        },
                        maxLength: {
                            value: 50,
                            error: <Translate id="notMaxLength" data={{ value: 50 }} />
                        }
                    },
                },
                valid: false,
                touched: false,
                errorMessage: [],
                order: 1
            },
            password: {
                type: validator.FORM_ELEMENT_INPUT_PASSWORD,
                value: '',
                config: {
                    label: <Translate id="password" />,
                    validation: {
                        required: {
                            error: <Translate id="notEmpty" data={{ name: <Translate id="password" /> }} />
                        },
                        minLength: {
                            value: 8,
                            error: <Translate id="notMinLength" data={{ value: 8 }} />
                        },
                        maxLength: {
                            value: 50,
                            error: <Translate id="notMaxLength" data={{ value: 50 }} />
                        }
                    },
                },
                valid: false,
                touched: false,
                errorMessage: [],
                order: 2
            }
        }
    );
    const [signUpForm, setSignUpForm] = React.useState(
        {
            fullName: {
                type: validator.FORM_ELEMENT_INPUT_TEXT,
                value: '',
                config: {
                    label: <Translate id="fullName" />,
                    validation: {
                        required: {
                            error: <Translate id="notEmpty" data={{ name: <Translate id="fullName" /> }} />
                        },
                        minLength: {
                            value: 3,
                            error: <Translate id="notMinLength" data={{ value: 3 }} />
                        },
                        maxLength: {
                            value: 50,
                            error: <Translate id="notMaxLength" data={{ value: 50 }} />
                        }
                    },
                },
                valid: false,
                touched: false,
                errorMessage: [],
                order: 1
            },
            email: {
                type: validator.FORM_ELEMENT_INPUT_EMAIL,
                value: '',
                config: {
                    label: <Translate id="email" />,
                    validation: {
                        required: {
                            error: <Translate id="notEmpty" data={{ name: <Translate id="email" /> }} />
                        },
                        isEmail: {
                            error: <Translate id="notValid" data={{ name: <Translate id="email" /> }} />
                        },
                        maxLength: {
                            value: 50,
                            error: <Translate id="notMaxLength" data={{ value: 50 }} />
                        }
                    },
                },
                valid: false,
                touched: false,
                errorMessage: [],
                order: 2
            },
            password: {
                type: validator.FORM_ELEMENT_INPUT_PASSWORD,
                value: '',
                config: {
                    label: <Translate id="password" />,
                    validation: {
                        required: {
                            error: <Translate id="notEmpty" data={{ name: <Translate id="password" /> }} />
                        },
                        minLength: {
                            value: 8,
                            error: <Translate id="notMinLength" data={{ value: 8 }} />
                        },
                        maxLength: {
                            value: 50,
                            error: <Translate id="notMaxLength" data={{ value: 50 }} />
                        }
                    },
                },
                valid: false,
                touched: false,
                errorMessage: [],
                order: 3
            },
            suscriber: {
                type: validator.FORM_ELEMENT_CHECKBOX,
                value: true,
                config: {
                    label: <Translate id="suscriberCheckbox" />,
                    validation: {}
                },
                valid: false,
                touched: false,
                errorMessage: [],
                order: 4
            },
            policies: {
                type: validator.FORM_ELEMENT_CHECKBOX,
                value: true,
                config: {
                    label: <Translate id="policyCheckbox" />,
                    validation: {
                        checked: {
                            error: <Translate id="policyNotAccepted" />
                        }
                    },
                },
                valid: false,
                touched: false,
                errorMessage: [],
                order: 5
            }
        }
    );
    const [forgotPassForm, setForgotPassForm] = React.useState(
        {
            email: {
                type: validator.FORM_ELEMENT_INPUT_EMAIL,
                value: '',
                config: {
                    label: <Translate id="email" />,
                    validation: {
                        required: {
                            error: <Translate id="notEmpty" data={{ name: <Translate id="email" /> }} />
                        },
                        isEmail: {
                            error: <Translate id="notValid" data={{ name: <Translate id="email" /> }} />
                        },
                        maxLength: {
                            value: 50,
                            error: <Translate id="notMaxLength" data={{ value: 50 }} />
                        }
                    },
                },
                valid: false,
                touched: false,
                errorMessage: [],
                order: 1
            }
        }
    );
    const [changePassForm, setChangePassForm] = React.useState(
        {
            currentPassword: {
                type: validator.FORM_ELEMENT_INPUT_PASSWORD,
                value: '',
                config: {
                    label: <Translate id="currentPassword" />,
                    validation: {
                        required: {
                            error: <Translate id="notEmpty" data={{ name: <Translate id="currentPassword" /> }} />
                        },
                        minLength: {
                            value: 8,
                            error: <Translate id="notMinLength" data={{ value: 8 }} />
                        },
                        maxLength: {
                            value: 50,
                            error: <Translate id="notMaxLength" data={{ value: 50 }} />
                        }
                    },
                },
                valid: false,
                touched: false,
                errorMessage: [],
                order: 1
            },
            newPassword: {
                type: validator.FORM_ELEMENT_INPUT_PASSWORD,
                value: '',
                config: {
                    label: <Translate id="newPassword" />,
                    validation: {
                        required: {
                            error: <Translate id="notEmpty" data={{ name: <Translate id="newPassword" /> }} />
                        },
                        minLength: {
                            value: 8,
                            error: <Translate id="notMinLength" data={{ value: 8 }} />
                        },
                        maxLength: {
                            value: 50,
                            error: <Translate id="notMaxLength" data={{ value: 50 }} />
                        }
                    },
                },
                valid: false,
                touched: false,
                errorMessage: [],
                order: 2
            }
        }
    );

    const getStateForm = () => {
        let authForm = null;
        switch (props.gDialogType) {
            case "signIn":
                authForm = signInForm;
                break;
            case "signUp":
                authForm = signUpForm;
                break;
            case "forgotPass":
                authForm = forgotPassForm;
                break;
            case "changePass":
                authForm = changePassForm;
                break;
            default:
                break;
        }
        return authForm;
    }

    const updateStateForm = updatedForm => {
        let authForm = null;
        switch (props.gDialogType) {
            case "signIn":
                setSignInForm(updatedForm);
                break;
            case "signUp":
                setSignUpForm(updatedForm);
                break;
            case "forgotPass":
                setForgotPassForm(updatedForm);
                break;
            case "changePass":
                setChangePassForm(updatedForm);
                break;
            default:
                break;
        }
        return authForm;
    }

    const cleanFormData = () => {
        const authForm = getStateForm();
        let formElements = {};
        for (let inputIdentifier in authForm) {
            let updatedFormElement = updateObject(authForm[inputIdentifier], {
                value: authForm[inputIdentifier].type == validator.FORM_ELEMENT_CHECKBOX ? true : '',
                valid: false,
                touched: false,
                errorMessage: []
            });
            formElements[inputIdentifier] = updatedFormElement;
        }
        const updatedAuthForm = updateObject(authForm, formElements);
        updateStateForm(updatedAuthForm);
    }

    const handleClose = () => {
        cleanFormData();
        props.gCloseDialog();
        props.gAuthEnd();
    }

    const handleChange = (event, inputIdentifier) => {
        let authForm = getStateForm();
        const inputValue = authForm[inputIdentifier].type == validator.FORM_ELEMENT_CHECKBOX ? event.target.checked : event.target.value;
        const validation = validator.checkValidity(inputValue, authForm[inputIdentifier].config.validation);
        const updatedFormElement = updateObject(authForm[inputIdentifier], {
            value: inputValue,
            valid: validation[0],
            touched: true,
            errorMessage: validation[1]
        });
        const updatedAuthForm = updateObject(authForm, {
            [inputIdentifier]: updatedFormElement
        });
        updateStateForm(sortObjectByOrder(updatedAuthForm));
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const authForm = getStateForm();
        let formIsValid = true;
        let formElements = {};
        const formData = {};
        for (let inputIdentifier in authForm) {
            let inputValue = authForm[inputIdentifier].value;
            let validation = validator.checkValidity(inputValue, authForm[inputIdentifier].config.validation);
            let inputIsValid = validation[0];
            let updatedFormElement = updateObject(authForm[inputIdentifier], {
                valid: inputIsValid,
                touched: true,
                errorMessage: validation[1]
            });
            formElements[inputIdentifier] = updatedFormElement;
            formIsValid = inputIsValid && formIsValid;
            formData[inputIdentifier] = inputValue;
        }
        const updatedAuthForm = updateObject(authForm, formElements);
        updateStateForm(updatedAuthForm);
        if (formIsValid) {
            switch (props.gDialogType) {
                case "signIn":
                    props.gAuth(formData, false);
                    //cleanFormData();
                    break;
                case "signUp":
                    props.gAuth(formData, true);
                    break;
                case "forgotPass":
                    props.gAuthForgotPassRecovery(formData);
                    break;
                case "changePass":
                    props.gAuthChangePass(formData);
                    break;
            }
        }
    }

    const handleForgotPassClick = () => {
        cleanFormData();
        props.gOpenDialog('forgotPass');
    }

    const { classes } = props;
    const authForm = getStateForm();
    const formElementArray = [];
    for (let key in authForm) {
        formElementArray.push({
            id: key,
            attr: authForm[key]
        });
    }

    let dialogTitle = '';
    let dialogDescription = null;
    let dialogHelperLink = null;
    let submitNotification = null;
    let submitButton = '';

    switch (props.gDialogType) {
        case "signIn":
            dialogTitle = <Translate id="signInTitle" />;
            dialogHelperLink = <a href="#" onClick={() => handleForgotPassClick()} className={classes.forgotLink}>
                <Typography className={classes.forgotText}><Translate id="forgotPassword" /></Typography></a>
            submitButton = <Translate id="signIn" />;
            break;
        case "signUp":
            dialogTitle = <Translate id="welcomeTo" />;
            dialogDescription = <DialogContentText className={classes.description}><Translate id="signUpDescription" /></DialogContentText>
            submitNotification = <Typography variant="subtitle1" align="center">
                <DoneIcon className={classes.icon} /><br />
                <Translate id="signUpNotification" data={{ email: props.gNotifyEmail }} options={{ renderInnerHtml: true }} />
            </Typography>
            submitButton = <Translate id="signUp" />;
            break;
        case "forgotPass":
            dialogTitle = <Translate id="forgotPasswordTitle" />;
            dialogDescription = <DialogContentText className={classes.description}><Translate id="forgotPasswordDescription" /></DialogContentText>
            submitNotification = <Typography variant="subtitle1" align="center">
                <DoneIcon className={classes.icon} /><br />
                <Translate id="forgotPasswordNotification" data={{ email: props.gNotifyEmail }} options={{ renderInnerHtml: true }} />
            </Typography>
            submitButton = <Translate id="submit" />;
            break;
        case "changePass":
            dialogTitle = <Translate id="changePasswordTitle" />;
            dialogDescription = <DialogContentText className={classes.description}><Translate id="changePasswordDescription" /></DialogContentText>
            submitNotification = <Typography variant="subtitle1" align="center">
                <DoneIcon className={classes.icon} /><br />
                <Translate id="changePasswordNotification" options={{ renderInnerHtml: true }} />
            </Typography>
            submitButton = <Translate id="submit" />;
            break;
        default:
            break;
    }

    return (
        props.gIsDialogOpen ?
            (<Dialog open={props.gIsDialogOpen} onClose={() => handleClose()} aria-labelledby="form-dialog-title" fullWidth>
                <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
                {props.gIsLoading ?
                    (<div className={classes.spinner}><CircularProgress className={classes.progress} size={50} /></div>) :
                    (<form onSubmit={(event) => handleSubmit(event)} noValidate autoComplete="off">
                        <DialogContent className={classes.content}>
                            {dialogDescription}
                            {props.gFormError ? <Alert severity="error" style={{ marginBottom: '15px' }}>{props.gFormError}</Alert> : null}
                            {props.gNotifyEmail ?
                                submitNotification : (
                                    <div>
                                        {formElementArray.map(formElement => (
                                            <FormElement
                                                key={formElement.id}
                                                isVisible="true"
                                                elementType={formElement.attr.type}
                                                elementValue={formElement.attr.value}
                                                labelName={formElement.attr.config.label}
                                                invalid={!formElement.attr.valid}
                                                shouldValidate={formElement.attr.config.validation}
                                                touched={formElement.attr.touched}
                                                errorMessage={formElement.attr.errorMessage}
                                                onChange={(event) => handleChange(event, formElement.id)} />
                                        ))}
                                    </div>)}
                            {dialogHelperLink}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleClose()} color="default">
                                <Translate id="close" />
                            </Button>
                            {props.gNotifyEmail ? null :
                                <Button type="submit" onClick={(event) => handleSubmit(event)} color="secondary">
                                    {submitButton}
                                </Button>}
                        </DialogActions>
                    </form>)}
            </Dialog>) : null
    );
}

const mapStateToProps = state => {
    return {
        gIsAuth: !!state.auth.user.id,
        gDialogType: state.app.dialogType,
        gIsDialogOpen: ["signIn", "signUp", "forgotPass", "changePass"].includes(state.app.dialogType),
        gIsLoading: state.auth.loading,
        gFormError: state.auth.error,
        gNotifyEmail: state.auth.notifyEmail
    };
};

const mapDispatchToProps = dispatch => {
    return {
        gOpenDialog: (dialogType) => dispatch(actions.openDialog(dialogType)),
        gAuth: (formData, isNewUser) => dispatch(actions.auth(formData, isNewUser)),
        gAuthEnd: () => dispatch(actions.authEnd()),
        gCloseDialog: () => dispatch(actions.closeDialog()),
        gAuthForgotPassRecovery: (formData) => dispatch(actions.authForgotPassRecovery(formData)),
        gAuthChangePass: (formData) => dispatch(actions.authChangePass(formData))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(HeaderDialog));