import React from 'react';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';
import { Translate } from "react-localize-redux";

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const styles = theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    close: {
        padding: theme.spacing(0.5),
    },
    snackbar: {
        [theme.breakpoints.down('xs')]: {
          bottom: 90,
        },
      },
});

const Alert = props => {

    const [open, setOpen] = React.useState(props.open);

    const handleClose = (event, reason, onClose) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        onClose();
    }

    const { classes, className, message, onClose, variant } = props;
    const Icon = variantIcon[variant];
    return (
        <Snackbar
            className={classes.snackbar}    
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            open={open}
            autoHideDuration={9000}
            onClose={(event, reason) => handleClose(event, reason, onClose)}
        >
            <SnackbarContent
                className={classNames(classes[variant], className)}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        <Icon className={classNames(classes.icon, classes.iconVariant)} />
                        {message}
                    </span>
                }
                action={[
                    <Button key="gotit" color="inherit" size="small" onClick={(event, reason) => handleClose(event, reason, onClose)}>
                        <Translate id="gotit" />
                    </Button>
                ]}
            />
        </Snackbar>
    );
}


export default withStyles(styles)(Alert);