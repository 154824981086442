import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Translate, withLocalize } from "react-localize-redux";
import CategoryIcon from '@material-ui/icons/Category';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ListIcon from '@material-ui/icons/List';
import AddIcon from '@material-ui/icons/Add';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    iconBox: {
        margin: theme.spacing(2),
        textAlign: 'center'
    },
});

const CategoryMenu = props => {

    const { classes } = props;
    return (
        <Grid item xs={12}>
            <Card>
                <div className={classes.iconBox}>
                    <CategoryIcon style={{ fontSize: 80 }} color="secondary" />
                    <Typography gutterBottom variant="h5" component="h2">
                        <Translate id="categories" />
                    </Typography>
                </div>
                <Divider />
                <List component="nav" aria-label="main mailbox folders">
                    <Link to="/category/list">
                        <a style={{ color: "inherit", textDecoration: "none" }}>
                            <ListItem button selected={(props.item == null || props.item == 'list') ? true : false}>
                                <ListItemIcon>
                                    <ListIcon />
                                </ListItemIcon>
                                <ListItemText primary={<Translate id="listCategory" />} />
                            </ListItem>
                        </a>
                    </Link>
                    <Link to="/category/create">
                        <a style={{ color: "inherit", textDecoration: "none" }}>
                            <ListItem button selected={props.item == 'create' ? true : false}>
                                <ListItemIcon>
                                    <AddIcon />
                                </ListItemIcon>
                                <ListItemText primary={<Translate id="addCategory" />} />
                            </ListItem>
                        </a>
                    </Link>
                </List>
            </Card>
        </Grid>
    );
}

export default withStyles(styles)(withLocalize(CategoryMenu));