import React from 'react';
import { useParams, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import MainLayout from 'components/common/layout/MainLayout';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import * as actions from 'state/actions/index';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import UserProfileSummary from './box/UserProfileSummary';
import UserProfileButtons from './box/UserProfileButtons';
import UserProfileCompanies from './box/UserProfileCompanies';
import UserProfileCourses from './box/UserProfileCourses';

const styles = theme => ({
    root: {
        flexGrow: 1,
        margin: 20,
        marginTop: theme.spacing(10)
    },
    spinner: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: theme.spacing(4)
    },
});

const UserProfileItem = props => {

    const { username } = useParams();

    React.useEffect(() => {
        props.gFetchItem(username);
    }, [username]);

    const { classes } = props;

    return (
        <MainLayout>
            {props.gItemError ? <Redirect to="/not-found" /> : null}
            <div className={classes.root}>
                {props.gItemLoading || props.gListLoading || props.gListCoursesLoading ?
                    (<div className={classes.spinner}><CircularProgress className={classes.progress} size={50} /></div>) :
                    (<Grid container spacing={4}>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                            <UserProfileSummary item={props.gItem} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={9} lg={9}>
                            <Grid container spacing={2}>
                                {props.gIsAuth && props.gUserId == props.gItem.id ?
                                    <Grid item xs={12}>
                                        <Card>
                                            <CardContent style={{ padding: '0' }}>
                                                {<UserProfileButtons item={props.gItem} tabValue={0} />}
                                            </CardContent>
                                        </Card>
                                    </Grid> : null}
                                <Grid item xs={12}>
                                    <UserProfileCompanies list={props.gList} />
                                    <UserProfileCourses item={props.gItem} list={props.gListCourses} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>)}
            </div>
        </MainLayout>
    );
}

const mapStateToProps = state => {
    return {
        gIsAuth: !!state.auth.user.id,
        gUserId: state.auth.user.id,
        gItem: state.user.item,
        gItemLoading: state.user.item == null,
        gItemError: state.user.itemError,
        gList: state.company.list,
        gListLoading: !state.company.list,
        gListError: state.company.listError,
        gListCourses: state.course.list,
        gListCoursesLoading: !state.course.list,
        gListCoursesError: state.course.listError
    };
};

const mapDispatchToProps = dispatch => {
    return {
        gFetchItem: (username) => dispatch(actions.userFetchItemByUsername(username)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserProfileItem));