import React from 'react';
import { Translate } from "react-localize-redux";
import * as action from 'state/actions';
import firebase from 'firebase.js';

export function companyUserFetchListByCompanyId(companyId) {
    return async (dispatch) => {
        dispatch(action.COMPANY_USER_LIST_START());
        let companyUserList = [];
        try {
            await firebase.firestore().collection('companies').doc(companyId).collection('editors').get()
                .then((query) => {
                    query.forEach(function (doc) {
                        companyUserList.push({ id: doc.id, ...doc.data(), role: 'EDITOR' });
                    });
                });
            await firebase.firestore().collection('companies').doc(companyId).collection('instructors').get()
                .then((query) => {
                    query.forEach(function (doc) {
                        companyUserList.push({ id: doc.id, ...doc.data(), role: 'INSTRUCTOR' });
                    });
                });
        } catch (error) {
            console.log(error);
            return dispatch(action.COMPANY_USER_LIST_FAIL({ error: error }));
        }
        return dispatch(action.COMPANY_USER_LIST_SUCCESS({ data: companyUserList }));
    };
};

export function companyUserDeleteItem(company, member) {
    return async (dispatch) => {
        dispatch(action.COMPANY_USER_DELETE_START());
        try {
            const subCollection = member.role == 'EDITOR' ? 'editors' : 'instructors';
            await firebase.firestore().collection('companies').doc(company.id).collection(subCollection).doc(member.id).delete()
                .then(function (user) {
                    firebase.firestore().collection('companies').doc(company.id).update({
                        [subCollection]: firebase.firestore.FieldValue.arrayRemove(member.id)
                    });
                    dispatch(showAlert('DELETED', 'success'));
                    dispatch(companyUserFetchListByCompanyId(company.id));
                })
        } catch (error) {
            dispatch(showAlert('', 'error'));
            return dispatch(action.COMPANY_USER_DELETE_FAIL({ error: error }));
        }
        return dispatch(action.COMPANY_USER_DELETE_SUCCESS());
    };
};

export function companyUserSubmitItem(company, members, formData) {
    return async (dispatch, getState) => {
        dispatch(action.COMPANY_USER_SUBMIT_START());
        const userAuth = getState().auth.user;
        let userExists = false;
        members.map((m, index) => {
            if (m.email == formData.email.trim()) {
                userExists = true;
            }
        })
        if (userExists || company.owner.id !== userAuth.id || (formData.email && formData.email == userAuth.email) || (formData.userId && formData.userId == userAuth.id)) {
            dispatch(showAlert('ALREADY_EXISTS', 'error'));
            return dispatch(action.COMPANY_USER_SUBMIT_FAIL({ error: 'ALREADY_EXISTS' }));
        }
        // Fetch User data
        let user;
        try {
            await firebase.firestore().collection('users').where("email", "==", formData.email).limit(1).get()
                .then((query) => {
                    query.forEach(function (doc) {
                        user = { id: doc.id, ...doc.data() };
                    });
                });
        } catch (error) {
            return dispatch(action.COMPANY_USER_SUBMIT_FAIL({ error: error }));
        }
        if (!user) {
            dispatch(showAlert('NOT_FOUND', 'error'));
            return dispatch(action.COMPANY_USER_SUBMIT_FAIL({ error: 'NOT_FOUND' }));
        }
        try {
            const subCollection = formData.role == 'EDITOR' ? 'editors' : 'instructors';
            await firebase.firestore().collection('companies').doc(company.id).collection(subCollection).doc(user.id).set({ ...user, role: formData.role, company: company })
                .then(function (member) {
                    firebase.firestore().collection('companies').doc(company.id).update({
                        [subCollection]: firebase.firestore.FieldValue.arrayUnion(user.id)
                    });
                    dispatch(showAlert('CREATED', 'success'));
                    dispatch(companyUserFetchListByCompanyId(company.id));
                })
        } catch (error) {
            console.log(error);
            dispatch(showAlert('', 'error'));
            return dispatch(action.COMPANY_USER_SUBMIT_FAIL({ error: error }));
        }
        return dispatch(action.COMPANY_USER_SUBMIT_SUCCESS());
    }
};

export function companyUserUpdateItem(company, member, newRole) {
    return async (dispatch, getState) => {
        dispatch(action.COMPANY_USER_SUBMIT_START());
        const userAuth = getState().auth.user;
        if (company.owner.id !== userAuth.id) {
            dispatch(showAlert('NOT_ALLOW', 'error'));
            return dispatch(action.COMPANY_USER_SUBMIT_FAIL({ error: 'NOT_ALLOW' }));
        }
        if (member.role !== newRole) {
            try {
                const oldSubCollection = member.role == 'EDITOR' ? 'editors' : 'instructors';
                const newSubCollection = newRole == 'EDITOR' ? 'editors' : 'instructors';
                await firebase.firestore().collection('companies').doc(company.id).collection(oldSubCollection).doc(member.id).delete()
                    .then(function (user) {
                        firebase.firestore().collection('companies').doc(company.id).collection(newSubCollection).doc(member.id).set({ ...member, role: newRole })
                            .then(function (user) {
                                firebase.firestore().collection('companies').doc(company.id).update({
                                    [oldSubCollection]: firebase.firestore.FieldValue.arrayRemove(member.id),
                                    [newSubCollection]: firebase.firestore.FieldValue.arrayUnion(member.id)
                                });
                                dispatch(companyUserFetchListByCompanyId(company.id));
                            })
                    })
            } catch (error) {
                dispatch(showAlert('', 'error'));
                return dispatch(action.COMPANY_USER_SUBMIT_FAIL({ error: error }));
            }
        }
        dispatch(showAlert('UPDATED', 'success'));
        return dispatch(action.COMPANY_USER_SUBMIT_SUCCESS());
    }
};

export function showAlert(message, alertType) {
    return async (dispatch, getState) => {
        let msj = '';
        switch (message) {
            case 'CREATED':
                msj = <Translate id="successCreated" data={{ name: <Translate id="member" /> }} />;
                break;
            case 'UPDATED':
                msj = <Translate id="successUpdated" data={{ name: <Translate id="member" /> }} />;
                break;
            case 'DELETED':
                msj = <Translate id="successDeleted" data={{ name: <Translate id="member" /> }} />;
                break;
            case 'CHILD_CONSTRAINT':
                msj = <Translate id="childConstraint" data={{ name: <Translate id="member" /> }} />;
                break;
            case 'NOT_FOUND':
                msj = <Translate id="notFound" data={{ name: <Translate id="member" /> }} />;
                break;
            case 'ALREADY_EXISTS':
                msj = <Translate id="alreadyExists" data={{ name: <Translate id="member" /> }} />;
                break;
            case 'NOT_ALLOW':
                msj = <Translate id="notAllow" data={{ name: <Translate id="member" /> }} />;
                break;
            default:
                msj = alertType == "error" ? <Translate id="error" /> : "";
                break;
        }
        dispatch(action.APP_SHOW_ALERT({ message: msj, alertType: alertType }));
    }
};