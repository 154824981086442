import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Translate } from "react-localize-redux";
import Button from '@material-ui/core/Button';
import * as validator from 'utils/form/validator';
import { sortObjectByOrder, updateObject } from 'utils/common/object';
import FormElement from 'components/common/form/FormElement';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    iconBox: {
        margin: theme.spacing(2),
        textAlign: 'center'
    },
    buttonSubmit: {
        marginTop: theme.spacing(1)
    },
    buttonClear: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
    spinner: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: theme.spacing(4)
    },
    error: {
        textAlign: 'center',
        color: 'red'
    },
});

const ItemForm = props => {

    const [customForm, setCustomForm] = React.useState(props.formParams);

    const handleChange = (event, inputIdentifier) => {
        let inputValue = ''; let inputFile = null;
        switch (customForm[inputIdentifier].type) {
            case validator.FORM_ELEMENT_CHECKBOX:
                inputValue = event.target.checked;
                break;
            case validator.FORM_ELEMENT_INPUT_FILE:
                inputValue = event.target.value;
                inputFile = event.target.files[0];
                break;
            default:
                inputValue = event.target.value;
                break;
        }
        const validation = validator.checkValidity(inputValue, customForm[inputIdentifier].config.validation);
        const updatedFormElement = updateObject(customForm[inputIdentifier], {
            value: inputValue,
            file: inputFile,
            valid: validation[0],
            touched: true,
            errorMessage: validation[1]
        });
        const updatedForm = updateObject(customForm, {
            [inputIdentifier]: updatedFormElement
        });
        setCustomForm(sortObjectByOrder(updatedForm));
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        let formIsValid = true;
        let formElements = {};
        const formData = {};
        for (let inputIdentifier in customForm) {
            let inputValue = customForm[inputIdentifier].value;
            let inputFile = customForm[inputIdentifier].file;
            let validation = validator.checkValidity(inputValue, customForm[inputIdentifier].config.validation);
            let inputIsValid = validation[0];
            let updatedFormElement = updateObject(customForm[inputIdentifier], {
                valid: inputIsValid,
                touched: true,
                errorMessage: validation[1]
            });
            formElements[inputIdentifier] = updatedFormElement;
            formIsValid = inputIsValid && formIsValid;
            formData[inputIdentifier] = customForm[inputIdentifier].type == validator.FORM_ELEMENT_INPUT_FILE ? inputFile : (inputValue + "");
        }
        const updatedForm = updateObject(customForm, formElements);
        setCustomForm(updatedForm);
        if (formIsValid) {
            props.onValidSubmit(formData);
        }
    }

    const cleanFormData = () => {
        let formElements = {};
        for (let inputIdentifier in customForm) {
            let updatedFormElement = updateObject(customForm[inputIdentifier], {
                value: customForm[inputIdentifier].type == validator.FORM_ELEMENT_CHECKBOX ? true : '',
                valid: false,
                touched: false,
                errorMessage: []
            });
            formElements[inputIdentifier] = updatedFormElement;
        }
        const updatedForm = updateObject(customForm, formElements);
        setCustomForm(updatedForm);
    }

    const { classes } = props;
    const formElementArray = [];
    for (let key in customForm) {
        formElementArray.push({
            id: key,
            attr: customForm[key]
        });
    }
    return (
        <form onSubmit={(event) => handleSubmit(event)}>
            {formElementArray.map(formElement => (
                <FormElement
                    key={formElement.id}
                    isVisible="true"
                    elementType={formElement.attr.type}
                    elementValue={formElement.attr.value}
                    labelName={formElement.attr.config.label}
                    helperText={formElement.attr.config.helper}
                    selectList={formElement.attr.config.selectList}
                    invalid={!formElement.attr.valid}
                    shouldValidate={formElement.attr.config.validation}
                    touched={formElement.attr.touched}
                    errorMessage={formElement.attr.errorMessage}
                    onChange={(event) => handleChange(event, formElement.id)} />
            ))}
            <Button type="submit" onClick={(event) => handleSubmit(event)} variant="contained" color="secondary" className={classes.buttonSubmit}>
                <Translate id="save" />
            </Button>
            <Button variant="contained" onClick={cleanFormData} className={classes.buttonClear}><Translate id="clear" /></Button>
        </form>
    );
}

export default withStyles(styles)(ItemForm);