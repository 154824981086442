export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_NOTIFICATION = 'AUTH_NOTIFICATION';
export const AUTH_END = 'AUTH_END';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const APP_OPEN_DIALOG = 'APP_OPEN_DIALOG';
export const APP_CLOSE_DIALOG = 'APP_CLOSE_DIALOG';
export const APP_SUBMIT_START  = 'APP_SUBMIT_START';
export const APP_SUBMIT_SUCCESS = 'APP_SUBMIT_SUCCESS';
export const APP_SUBMIT_FAIL = 'APP_SUBMIT_FAIL';
export const APP_SUBMIT_END = 'APP_SUBMIT_END';
export const APP_SHOW_ALERT = 'APP_SHOW_ALERT';
export const APP_HIDE_ALERT = 'APP_HIDE_ALERT';
export const CATEGORY_SUBMIT_START  = 'CATEGORY_SUBMIT_START';
export const CATEGORY_SUBMIT_SUCCESS = 'CATEGORY_SUBMIT_SUCCESS';
export const CATEGORY_SUBMIT_FAIL = 'CATEGORY_SUBMIT_FAIL';
export const CATEGORY_SUBMIT_END = 'CATEGORY_SUBMIT_END';
export const CATEGORY_LIST_START  = 'CATEGORY_LIST_START';
export const CATEGORY_LIST_SUCCESS = 'CATEGORY_LIST_SUCCESS';
export const CATEGORY_LIST_FAIL = 'CATEGORY_LIST_FAIL';
export const CATEGORY_LIST_END = 'CATEGORY_LIST_END';
export const CATEGORY_ITEM_START  = 'CATEGORY_ITEM_START';
export const CATEGORY_ITEM_SUCCESS = 'CATEGORY_ITEM_SUCCESS';
export const CATEGORY_ITEM_FAIL = 'CATEGORY_ITEM_FAIL';
export const CATEGORY_ITEM_END = 'CATEGORY_ITEM_END';
export const CATEGORY_DELETE_START  = 'CATEGORY_DELETE_START';
export const CATEGORY_DELETE_SUCCESS = 'CATEGORY_DELETE_SUCCESS';
export const CATEGORY_DELETE_FAIL = 'CATEGORY_DELETE_FAIL';
export const CATEGORY_DELETE_END = 'CATEGORY_DELETE_END';
export const USER_SUBMIT_START  = 'USER_SUBMIT_START';
export const USER_SUBMIT_SUCCESS = 'USER_SUBMIT_SUCCESS';
export const USER_SUBMIT_FAIL = 'USER_SUBMIT_FAIL';
export const USER_SUBMIT_END = 'USER_SUBMIT_END';
export const USER_LIST_START  = 'USER_LIST_START';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_FAIL = 'USER_LIST_FAIL';
export const USER_LIST_END = 'USER_LIST_END';
export const USER_ITEM_START  = 'USER_ITEM_START';
export const USER_ITEM_SUCCESS = 'USER_ITEM_SUCCESS';
export const USER_ITEM_FAIL = 'USER_ITEM_FAIL';
export const USER_ITEM_END = 'USER_ITEM_END';
export const USER_DELETE_START  = 'USER_DELETE_START';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL';
export const USER_DELETE_END = 'USER_DELETE_END';
export const COMPANY_SUBMIT_START  = 'COMPANY_SUBMIT_START';
export const COMPANY_SUBMIT_SUCCESS = 'COMPANY_SUBMIT_SUCCESS';
export const COMPANY_SUBMIT_FAIL = 'COMPANY_SUBMIT_FAIL';
export const COMPANY_SUBMIT_END = 'COMPANY_SUBMIT_END';
export const COMPANY_LIST_START  = 'COMPANY_LIST_START';
export const COMPANY_LIST_SUCCESS = 'COMPANY_LIST_SUCCESS';
export const COMPANY_LIST_FAIL = 'COMPANY_LIST_FAIL';
export const COMPANY_LIST_END = 'COMPANY_LIST_END';
export const COMPANY_ITEM_START  = 'COMPANY_ITEM_START';
export const COMPANY_ITEM_SUCCESS = 'COMPANY_ITEM_SUCCESS';
export const COMPANY_ITEM_FAIL = 'COMPANY_ITEM_FAIL';
export const COMPANY_ITEM_END = 'COMPANY_ITEM_END';
export const COMPANY_DELETE_START  = 'COMPANY_DELETE_START';
export const COMPANY_DELETE_SUCCESS = 'COMPANY_DELETE_SUCCESS';
export const COMPANY_DELETE_FAIL = 'COMPANY_DELETE_FAIL';
export const COMPANY_DELETE_END = 'COMPANY_DELETE_END';
export const COMPANY_USER_SUBMIT_START  = 'COMPANY_USER_SUBMIT_START';
export const COMPANY_USER_SUBMIT_SUCCESS = 'COMPANY_USER_SUBMIT_SUCCESS';
export const COMPANY_USER_SUBMIT_FAIL = 'COMPANY_USER_SUBMIT_FAIL';
export const COMPANY_USER_SUBMIT_END = 'COMPANY_USER_SUBMIT_END';
export const COMPANY_USER_LIST_START  = 'COMPANY_USER_LIST_START';
export const COMPANY_USER_LIST_SUCCESS = 'COMPANY_USER_LIST_SUCCESS';
export const COMPANY_USER_LIST_FAIL = 'COMPANY_USER_LIST_FAIL';
export const COMPANY_USER_LIST_END = 'COMPANY_USER_LIST_END';
export const COMPANY_USER_ITEM_START  = 'COMPANY_USER_ITEM_START';
export const COMPANY_USER_ITEM_SUCCESS = 'COMPANY_USER_ITEM_SUCCESS';
export const COMPANY_USER_ITEM_FAIL = 'COMPANY_USER_ITEM_FAIL';
export const COMPANY_USER_ITEM_END = 'COMPANY_USER_ITEM_END';
export const COMPANY_USER_DELETE_START  = 'COMPANY_USER_DELETE_START';
export const COMPANY_USER_DELETE_SUCCESS = 'COMPANY_USER_DELETE_SUCCESS';
export const COMPANY_USER_DELETE_FAIL = 'COMPANY_USER_DELETE_FAIL';
export const COMPANY_USER_DELETE_END = 'COMPANY_USER_DELETE_END';
export const COURSE_SUBMIT_START  = 'COURSE_SUBMIT_START';
export const COURSE_SUBMIT_SUCCESS = 'COURSE_SUBMIT_SUCCESS';
export const COURSE_SUBMIT_FAIL = 'COURSE_SUBMIT_FAIL';
export const COURSE_SUBMIT_END = 'COURSE_SUBMIT_END';
export const COURSE_LIST_START  = 'COURSE_LIST_START';
export const COURSE_LIST_SUCCESS = 'COURSE_LIST_SUCCESS';
export const COURSE_LIST_FAIL = 'COURSE_LIST_FAIL';
export const COURSE_LIST_END = 'COURSE_LIST_END';
export const COURSE_ITEM_START  = 'COURSE_ITEM_START';
export const COURSE_ITEM_SUCCESS = 'COURSE_ITEM_SUCCESS';
export const COURSE_ITEM_FAIL = 'COURSE_ITEM_FAIL';
export const COURSE_ITEM_END = 'COURSE_ITEM_END';
export const COURSE_DELETE_START  = 'COURSE_DELETE_START';
export const COURSE_DELETE_SUCCESS = 'COURSE_DELETE_SUCCESS';
export const COURSE_DELETE_FAIL = 'COURSE_DELETE_FAIL';
export const COURSE_DELETE_END = 'COURSE_DELETE_END';
export const COURSE_INSTRUCTOR_SUBMIT_START  = 'COURSE_INSTRUCTOR_SUBMIT_START';
export const COURSE_INSTRUCTOR_SUBMIT_SUCCESS = 'COURSE_INSTRUCTOR_SUBMIT_SUCCESS';
export const COURSE_INSTRUCTOR_SUBMIT_FAIL = 'COURSE_INSTRUCTOR_SUBMIT_FAIL';
export const COURSE_INSTRUCTOR_SUBMIT_END = 'COURSE_INSTRUCTOR_SUBMIT_END';
export const COURSE_INSTRUCTOR_LIST_START  = 'COURSE_INSTRUCTOR_LIST_START';
export const COURSE_INSTRUCTOR_LIST_SUCCESS = 'COURSE_INSTRUCTOR_LIST_SUCCESS';
export const COURSE_INSTRUCTOR_LIST_FAIL = 'COURSE_INSTRUCTOR_LIST_FAIL';
export const COURSE_INSTRUCTOR_LIST_END = 'COURSE_INSTRUCTOR_LIST_END';
export const COURSE_INSTRUCTOR_ITEM_START  = 'COURSE_INSTRUCTOR_ITEM_START';
export const COURSE_INSTRUCTOR_ITEM_SUCCESS = 'COURSE_INSTRUCTOR_ITEM_SUCCESS';
export const COURSE_INSTRUCTOR_ITEM_FAIL = 'COURSE_INSTRUCTOR_ITEM_FAIL';
export const COURSE_INSTRUCTOR_ITEM_END = 'COURSE_INSTRUCTOR_ITEM_END';
export const COURSE_INSTRUCTOR_DELETE_START  = 'COURSE_INSTRUCTOR_DELETE_START';
export const COURSE_INSTRUCTOR_DELETE_SUCCESS = 'COURSE_INSTRUCTOR_DELETE_SUCCESS';
export const COURSE_INSTRUCTOR_DELETE_FAIL = 'COURSE_INSTRUCTOR_DELETE_FAIL';
export const COURSE_INSTRUCTOR_DELETE_END = 'COURSE_INSTRUCTOR_DELETE_END';
export const TOPIC_SUBMIT_START  = 'TOPIC_SUBMIT_START';
export const TOPIC_SUBMIT_SUCCESS = 'TOPIC_SUBMIT_SUCCESS';
export const TOPIC_SUBMIT_FAIL = 'TOPIC_SUBMIT_FAIL';
export const TOPIC_SUBMIT_END = 'TOPIC_SUBMIT_END';
export const TOPIC_LIST_START  = 'TOPIC_LIST_START';
export const TOPIC_LIST_SUCCESS = 'TOPIC_LIST_SUCCESS';
export const TOPIC_LIST_FAIL = 'TOPIC_LIST_FAIL';
export const TOPIC_LIST_END = 'TOPIC_LIST_END';
export const TOPIC_ITEM_START  = 'TOPIC_ITEM_START';
export const TOPIC_ITEM_SUCCESS = 'TOPIC_ITEM_SUCCESS';
export const TOPIC_ITEM_FAIL = 'TOPIC_ITEM_FAIL';
export const TOPIC_ITEM_END = 'TOPIC_ITEM_END';
export const TOPIC_DELETE_START  = 'TOPIC_DELETE_START';
export const TOPIC_DELETE_SUCCESS = 'TOPIC_DELETE_SUCCESS';
export const TOPIC_DELETE_FAIL = 'TOPIC_DELETE_FAIL';
export const TOPIC_DELETE_END = 'TOPIC_DELETE_END';