import { createReducer } from 'redux-act';
import * as action from 'state/actions';

const initialState = {
  locale: null
};

export const preferencesReducer = createReducer(
  {
    [action.PREFERENCES_SET_LOCALE]: (state, payload) => ({
      ...state,
      locale: payload.locale
    })
  },
  initialState
);