import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from 'state/actions/index';
import MainLayout from 'components/common/layout/MainLayout';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import BusinessIcon from '@material-ui/icons/Business';
import Typography from '@material-ui/core/Typography';
import DoneIcon from '@material-ui/icons/Done';
import { Translate } from "react-localize-redux";
import Button from '@material-ui/core/Button';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import ViewIcon from '@material-ui/icons/Visibility';
import CourseForm from './CourseForm';

const styles = theme => ({
    root: {
        flexGrow: 1,
        margin: 20,
        marginTop: theme.spacing(10)
    },
    spinner: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: theme.spacing(4)
    },
    avatar: {
        width: theme.spacing(15),
        height: theme.spacing(15),
        margin: 'auto'
    },
    icon: {
        margin: theme.spacing(1),
        fontSize: 50,
        color: 'green'
    }
});

const CourseCreate = props => {

    React.useEffect(() => {
        props.gFetchList();
        props.gFetchCompanyList(props.gUserId);
    }, []);

    const onValidSubmit = (formData) => {
        props.gSubmitItem(formData, props.gList, props.gCompanyList);
    }

    const { classes } = props;

    return (
        <MainLayout>
            <div className={classes.root}>
                {props.gListLoading || props.gCompanyListLoading || props.gSubmitLoading ?
                    (<div className={classes.spinner}><CircularProgress className={classes.progress} size={50} /></div>) :
                    (<React.Fragment>
                        <Grid container spacing={2} style={{marginBottom: '20px'}}>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs>
                                        <Typography variant="h6">
                                            Nuevo Curso
                                    </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" startIcon={<ViewIcon />}
                                            color="primary" disabled>Vista Previa</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <Stepper activeStep={0} orientation="vertical" style={{marginTop: '-8px'}}>
                                    <Step>
                                        <StepLabel>Datos Generales</StepLabel>
                                        <StepContent></StepContent>
                                    </Step>
                                    <Step>
                                        <StepLabel>Contenido</StepLabel>
                                        <StepContent></StepContent>
                                    </Step>
                                    <Step>
                                        <StepLabel>Publicación</StepLabel>
                                        <StepContent></StepContent>
                                    </Step>
                                </Stepper>
                            </Grid>
                            <Grid item xs={12} sm={12} md={9} lg={9}>
                                <Grid container spacing={2}>                                    
                                    <Card>
                                        <CardContent>
                                            <CourseForm
                                                companyList={props.gCompanyList}
                                                categoryList={props.gList}
                                                onValidSubmit={onValidSubmit}
                                                submitError={props.gSubmitError}
                                            />
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    </React.Fragment>)}
            </div>
        </MainLayout>
    );
}

const mapStateToProps = state => {
    return {
        gIsAuth: state.auth.sessionToken !== null,
        gUserId: state.auth.user.id,
        gSubmitLoading: state.course.submitLoading,
        gSubmitError: state.course.submitError,
        gList: state.category.list,
        gListLoading: !state.category.list,
        gListError: state.category.listError,
        gCompanyList: state.company.list,
        gCompanyListLoading: !state.company.list,
        gCompanyListError: state.company.listError

    };
};

const mapDispatchToProps = dispatch => {
    return {
        gSubmitItem: (formData, categoryList, companyList) => dispatch(actions.courseSubmitItem(formData, categoryList, companyList)),
        gFetchList: () => dispatch(actions.categoryFetchList()),
        gFetchCompanyList: (userId) => dispatch(actions.companyFetchListByUserId(userId))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CourseCreate));