import React from 'react';
import * as appParams from 'config/params';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    avatar: {
        width: theme.spacing(5),
        height: theme.spacing(5),
        margin: 'auto'
    }
});

const UserProfileCompanies = props => {

    const { classes } = props;
    return (
        <React.Fragment>
            <Typography gutterBottom variant="button">
                Empresas
            </Typography>
            <Divider light style={{ marginBottom: '25px' }} />
            <Grid container alignItems="center" spacing={3} style={{ marginBottom: '20px' }}>
                {props.list.map((company, index) => (
                    <Grid key={company.companyId} item xs={6} sm={6} md={3} lg={3} style={{ textAlign: 'center' }}>
                        <Avatar
                            src={appParams.WS_BACKEND + '/category/file/' + company.photo} className={classes.avatar}
                        />
                        <Typography gutterBottom variant="caption" >
                            {company.name}
                        </Typography>
                    </Grid>
                ))}                
            </Grid>
        </React.Fragment>
    );
}

export default withStyles(styles)(UserProfileCompanies);