import { createReducer } from 'redux-act';
import * as action from 'state/actions';

const initialState = {
  list: null,
  listLoading: false,
  listError: null,
  item: null,
  itemLoading: false,
  itemError: null,
  submitLoading: false,
  submitError: null,
  deleteLoading: false,
  deleteError: null,
  updatedUserPhoto: null
};

export const userReducer = createReducer(
  {
    [action.USER_SUBMIT_START]: (state, action) => ({
      ...state,
      submitError: null,
      submitLoading: true
    }),

    [action.USER_SUBMIT_SUCCESS]: (state, action) => ({
      ...state,
      submitError: null,
      submitLoading: false,
      updatedUserPhoto: action.photo
    }),

    [action.USER_SUBMIT_FAIL]: (state, action) => ({
      ...state,
      submitError: action.error,
      submitLoading: false
    }),

    [action.USER_SUBMIT_END]: (state, action) => ({
      ...state,
      submitError: null,
      submitLoading: false
    }),

    [action.USER_LIST_START]: (state, action) => ({
      ...state,
      list: null,
      listLoading: true,
      listError: null
    }),

    [action.USER_LIST_SUCCESS]: (state, action) => ({
      ...state,
      list: action.data,
      listLoading: false,
      listError: null
    }),

    [action.USER_LIST_FAIL]: (state, action) => ({
      ...state,
      list: null,
      listLoading: false,
      listError: action.error
    }),

    [action.USER_LIST_END]: (state, action) => ({
      ...state,
      listLoading: false,
      listError: null
    }),

    [action.USER_ITEM_START]: (state, action) => ({
      ...state,
      updatedUserPhoto: null,
      item: null,
      itemLoading: true,
      itemError: null
    }),

    [action.USER_ITEM_SUCCESS]: (state, action) => ({
      ...state,
      item: action.data,
      itemLoading: false,
      itemError: null
    }),

    [action.USER_ITEM_FAIL]: (state, action) => ({
      ...state,
      item: null,
      itemLoading: false,
      itemError: action.error
    }),

    [action.USER_ITEM_END]: (state, action) => ({
      ...state,
      itemLoading: false,
      itemError: null
    }),

    [action.USER_DELETE_START]: (state, action) => ({
      ...state,
      deleteError: null,
      deleteLoading: true
    }),

    [action.USER_DELETE_SUCCESS]: (state, action) => ({
      ...state,
      deleteError: null,
      deleteLoading: false
    }),

    [action.USER_DELETE_FAIL]: (state, action) => ({
      ...state,
      deleteError: action.error,
      deleteLoading: false
    }),
    [action.USER_DELETE_END]: (state, action) => ({
      ...state,
      deleteError: null,
      deleteLoading: false
    })
  },
  initialState
);