export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export function sortObjectByOrder(objectArray) {
    let newArray = {};
    let order = 1;
    for (let key1 in objectArray) {
        for (let key2 in objectArray) {
            if (objectArray[key2].order === order) {
                newArray[key2] = objectArray[key2];
            }
        }
        order = order + 1;
    }
    return newArray;
}