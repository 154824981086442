import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
    root: {
        marginBottom: '50px'
    },
    divider: {
        marginTop: '50px', 
        marginBottom: '20px'
    },
    grid1: {
        textAlign: 'right',
        paddingRight: 20,
        [theme.breakpoints.down('xs')]: {
            paddingRight: 0,
            textAlign: 'center'
        }
    },
    grid2: {
        textAlign: 'left',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center'
        }
    },
});

const Footer = props => {
    const { classes } = props;
    return (
        <React.Fragment>
            <Divider variant="middle" className={classes.divider}/>
            <Grid container alignItems="center" className={classes.root}>
                <Grid item xs={12} sm={6} md={6} lg={6} className={classes.grid1}>
                    <Typography variant="body2" color="textSecondary" component="p">
                        © 2020 Join the Master
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} className={classes.grid2}>
                    <a rel="nofollow noopener noreferrer" target="_blank" href="https://facebook.com/jointhemaster">
                        <IconButton>
                            <FacebookIcon />
                        </IconButton>
                    </a>
                    <a rel="nofollow noopener noreferrer" target="_blank" href="https://instagram.com/jointhemaster">
                        <IconButton>
                            <InstagramIcon />
                        </IconButton>
                    </a>
                    <a rel="nofollow noopener noreferrer" target="_blank" href="https://twitter.com/jointhemaster">
                        <IconButton>
                            <TwitterIcon />
                        </IconButton>
                    </a>
                    <a rel="nofollow noopener noreferrer" target="_blank" href="https://linkedin.com/company/jointhemaster">
                        <IconButton>
                            <LinkedInIcon />
                        </IconButton>
                    </a>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default withStyles(styles)(Footer);