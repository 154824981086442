export const checkValidity = (value, rules) => {
    let ruleIsValid = true;
    let inputIsValid = true;
    let errorMessage = [];
    if (!rules) {
        return true;
    }

    if (rules.required) {
        ruleIsValid = value.trim() !== '';
        inputIsValid = ruleIsValid && inputIsValid;
        if (!ruleIsValid) {
            errorMessage.push(rules.required.error);
        }
    }

    if (rules.notEmpty) {
        ruleIsValid = value !== '';
        inputIsValid = ruleIsValid && inputIsValid;
        if (!ruleIsValid) {
            errorMessage.push(rules.notEmpty.error);
        }
    }

    if (rules.checked) {
        inputIsValid = value && inputIsValid;
        if (!value) {
            errorMessage.push(rules.checked.error);
        }
    }

    if (rules.minLength) {
        ruleIsValid = value.trim().length >= rules.minLength.value;
        inputIsValid = ruleIsValid && inputIsValid;
        if (!ruleIsValid) {
            errorMessage.push(rules.minLength.error);
        }
    }

    if (rules.maxLength) {
        ruleIsValid = value.trim().length <= rules.maxLength.value;
        inputIsValid = ruleIsValid && inputIsValid;
        if (!ruleIsValid) {
            errorMessage.push(rules.maxLength.error);
        }
    }

    if (rules.isEmail) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        ruleIsValid = pattern.test(value);
        inputIsValid = ruleIsValid && inputIsValid;
        if (!ruleIsValid) {
            errorMessage.push(rules.isEmail.error);
        }
    }

    if (rules.isNumeric) {
        const pattern = /^\d+$/;
        ruleIsValid = pattern.test(value) || value == '';
        inputIsValid = ruleIsValid && inputIsValid;
        if (!ruleIsValid) {
            errorMessage.push(rules.isNumeric.error);
        }
    }

    if (rules.minValue) {
        ruleIsValid = value >= rules.minValue.value;
        inputIsValid = ruleIsValid && inputIsValid;
        if (!ruleIsValid) {
            errorMessage.push(rules.minValue.error);
        }
    }

    if (rules.maxValue) {
        ruleIsValid = value <= rules.maxValue.value;
        inputIsValid = ruleIsValid && inputIsValid;
        if (!ruleIsValid) {
            errorMessage.push(rules.maxValue.error);
        }
    }

    return [inputIsValid, errorMessage];
}

export const FORM_ELEMENT_INPUT_TEXT = 'FORM_ELEMENT_INPUT_TEXT';
export const FORM_ELEMENT_INPUT_EMAIL = 'FORM_ELEMENT_INPUT_EMAIL';
export const FORM_ELEMENT_INPUT_PASSWORD = 'FORM_ELEMENT_INPUT_PASSWORD';
export const FORM_ELEMENT_CHECKBOX = 'FORM_ELEMENT_CHECKBOX';
export const FORM_ELEMENT_INPUT_SELECT = 'FORM_ELEMENT_INPUT_SELECT';
export const FORM_ELEMENT_INPUT_FILE = 'FORM_ELEMENT_INPUT_FILE';
export const FORM_ELEMENT_INPUT_TEXTAREA = 'FORM_ELEMENT_INPUT_TEXTAREA';