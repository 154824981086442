import React from 'react';
import { connect } from 'react-redux';
import * as actions from 'state/actions/index';
import MainLayout from 'components/common/layout/MainLayout';
import UserProfileSummary from './box/UserProfileSummary';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import UserProfileButtons from './box/UserProfileButtons';
import UserProfileForm from './UserProfileForm';

const styles = theme => ({
    root: {
        flexGrow: 1,
        margin: 20,
        marginTop: theme.spacing(10)
    },
    spinner: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: theme.spacing(4)
    }
});

const UserProfileUpdate = props => {

    React.useEffect(() => {
        props.gFetchItem();
    }, []);

    const onValidSubmit = (formData) => {
        props.gSubmitItem(formData);
    }

    const { classes } = props;

    return (
        <MainLayout>
            <div className={classes.root}>
                {props.gItemLoading ?
                    (<div className={classes.spinner}><CircularProgress className={classes.progress} size={50} /></div>) :
                    (<Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                            <UserProfileSummary item={props.gItem} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={9} lg={9}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Card>
                                        <CardContent style={{ padding: '0' }}>
                                            <UserProfileButtons item={props.gItem} tabValue={1} />
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card>
                                        <CardContent>
                                            {props.gItemLoading || props.gSubmitLoading || props.gListLoading ?
                                                (<div className={classes.spinner}><CircularProgress className={classes.progress} size={50} /></div>) :
                                                (
                                                    <UserProfileForm
                                                        item={props.gItem}
                                                        onValidSubmit={onValidSubmit}
                                                        submitError={props.gSubmitError}
                                                    />
                                                )}
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>)}
            </div>
        </MainLayout>
    );
}

const mapStateToProps = state => {
    return {
        gIsAuth: !!state.auth.user.id,
        gUserId: state.auth.user.id,
        gItem: state.user.item,
        gItemLoading: !state.user.item,
        gItemError: state.user.itemError,
        gSubmitLoading: state.user.submitLoading,
        gSubmitError: state.user.submitError,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        gFetchItem: () => dispatch(actions.userFetchItemByUserId()),
        gSubmitItem: (formData) => dispatch(actions.userSubmitItem(formData)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserProfileUpdate));