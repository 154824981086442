import React from 'react';
import { Translate } from "react-localize-redux";
import * as action from 'state/actions';
import firebase from 'firebase.js';

export function companyFetchListByUserId(userId) {
    return async (dispatch) => {
        dispatch(action.COMPANY_LIST_START());
        let companies = [];
        try {
            await firebase.firestore().collection('companies').where("owner.id", "==", userId).get()
                .then((query) => {
                    query.forEach(function (doc) {
                        companies.push({ id: doc.id, ...doc.data() });
                    });
                });
            await firebase.firestore().collectionGroup('editors').where("id", "==", userId).get()
                .then((query) => {
                    query.forEach(function (doc) {
                        companies.push({ ...doc.data().company });
                    });
                });
            await firebase.firestore().collectionGroup('instructors').where("id", "==", userId).get()
                .then((query) => {
                    query.forEach(function (doc) {
                        companies.push({ ...doc.data().company });
                    });
                });
        } catch (error) {
            return dispatch(action.COMPANY_LIST_FAIL({ error: error }));
        }
        return dispatch(action.COMPANY_LIST_SUCCESS({ data: companies }));
    };
};

export function companyFetchItemByAlias(alias) {
    return async (dispatch) => {
        dispatch(action.COMPANY_ITEM_START());
        let company;
        try {
            await firebase.firestore().collection('companies').where("alias", "==", alias).limit(1).get()
                .then((query) => {
                    query.forEach(function (doc) {
                        company = { id: doc.id, ...doc.data() };
                    });
                });
        } catch (error) {
            return dispatch(action.COMPANY_ITEM_FAIL({ error: error }));
        }
        if (!company) {
            return dispatch(action.COMPANY_ITEM_FAIL({ error: "NOT_FOUND" }));
        }
        return dispatch(action.COMPANY_ITEM_SUCCESS({ data: company }));
    };
};

export function companyFetchItemByCompanyId(companyId) {
    return async (dispatch, getState) => {
        dispatch(action.COMPANY_ITEM_START());
        let company;
        try {
            await firebase.firestore().collection('companies').doc(companyId).get()
                .then((doc) => {
                    if (doc.data()) {
                        company = { id: doc.id, ...doc.data() };
                    }
                });
        } catch (error) {
            return dispatch(action.COMPANY_ITEM_FAIL({ error: error }));
        }        
        if (!company) {
            return dispatch(action.COMPANY_ITEM_FAIL({ error: "NOT_FOUND" }));
        }
        return dispatch(action.COMPANY_ITEM_SUCCESS({ data: company }));
    };
};

export function companySubmitItem(formData) {
    return async (dispatch, getState) => {
        dispatch(action.COMPANY_SUBMIT_START());
        const userAuth = getState().auth.user;
        let companyId = formData.companyId ? formData.companyId : null;
        const photo = formData.oldPhoto ? formData.oldPhoto : null;
        const alias = formData.oldAlias ? formData.oldAlias : null;
        // Validate company alias
        if (formData.alias != alias) {
            let company;
            try {
                await firebase.firestore().collection('companies').where("alias", "==", formData.alias).limit(1).get()
                    .then((query) => {
                        query.forEach(function (doc) {
                            company = { id: doc.id, ...doc.data() };
                        });
                    });
            } catch (error) {
                dispatch(showAlert('', 'error'));
                return dispatch(action.COMPANY_SUBMIT_FAIL({ error: error }));
            }
            if (company) {
                dispatch(showAlert('ALREADY_EXISTS', 'error'));
                return dispatch(action.COMPANY_SUBMIT_FAIL({ error: 'ALREADY_EXISTS' }));
            }
        }
        let imagePreviewUrl = null;
        if (companyId) { // Update Company
            // Delete old photo & upload new photo
            let deletePhotoTask;
            let uploadPhotoTask;
            let newPhotoUrl = photo;
            if (formData.photo) {
                newPhotoUrl = getPhotoUrl(companyId, formData.photo);
                deletePhotoTask = photo && deletePhoto(photo);
                uploadPhotoTask = uploadPhoto(companyId, formData.photo);
                imagePreviewUrl = URL.createObjectURL(formData.photo);
            }
            // Company data
            const companyData = {
                name: formData.name,
                alias: formData.alias,
                description: formData.description,
                location: formData.location,
                facebook: formData.facebook,
                twitter: formData.twitter,
                instagram: formData.instagram,
                linkedin: formData.linkedin,
                photo: newPhotoUrl
            };
            const companyDbTask = firebase.firestore().collection('companies').doc(companyId).set(companyData, { merge: true });
            // Execute all tasks
            try {
                await Promise.all([deletePhotoTask, uploadPhotoTask, companyDbTask]);
            } catch (error) {
                dispatch(showAlert('', 'error'));
                return dispatch(action.COMPANY_SUBMIT_FAIL({ error: error }));
            }
            dispatch(showAlert('UPDATED', 'success'));
            dispatch(companyFetchItemByCompanyId(companyId));
        } else { // New Company
            // Company data
            const companyData = {
                name: formData.name,
                alias: formData.alias,
                description: formData.description,
                location: formData.location,
                facebook: formData.facebook,
                twitter: formData.twitter,
                instagram: formData.instagram,
                linkedin: formData.linkedin,
                photo: null,
                owner: userAuth
            };
            try {
                await firebase.firestore().collection('companies').add(companyData)
                    .then(function (company) {
                        companyId = company.id;
                        if (formData.photo) {
                            uploadPhoto(companyId, formData.photo);
                            const newPhotoUrl = getPhotoUrl(companyId, formData.photo);
                            firebase.firestore().collection('companies').doc(companyId).set({ photo: newPhotoUrl }, { merge: true })
                        }
                        imagePreviewUrl = companyId;
                        dispatch(showAlert('CREATED', 'success'));
                    })
            } catch (error) {
                console.log(error);
                dispatch(showAlert('', 'error'));
                return dispatch(action.COMPANY_SUBMIT_FAIL({ error: error }));
            }
        }
        return dispatch(action.COMPANY_SUBMIT_SUCCESS({ photo: imagePreviewUrl, alias: formData.alias }));
    }
};

export function showAlert(message, alertType) {
    return async (dispatch, getState) => {
        let msj = '';
        switch (message) {
            case 'CREATED':
                msj = <Translate id="successCreated" data={{ name: <Translate id="company" /> }} />;
                break;
            case 'UPDATED':
                msj = <Translate id="successUpdated" data={{ name: <Translate id="company" /> }} />;
                break;
            case 'DELETED':
                msj = <Translate id="successDeleted" data={{ name: <Translate id="company" /> }} />;
                break;
            case 'CHILD_CONSTRAINT':
                msj = <Translate id="childConstraint" data={{ name: <Translate id="company" /> }} />;
                break;
            case 'NOT_FOUND':
                msj = <Translate id="notFound" data={{ name: <Translate id="company" /> }} />;
                break;
            case 'ALREADY_EXISTS':
                msj = <Translate id="alreadyExists" data={{ name: <Translate id="company" /> }} />;
                break;
            default:
                msj = alertType == "error" ? <Translate id="error" /> : "";
                break;
        }
        dispatch(action.APP_SHOW_ALERT({ message: msj, alertType: alertType }));
    }
};

const deletePhoto = (oldPhoto) => {
    if (!oldPhoto.includes('firebasestorage')) {
        return null;
    }
    const photoPath = oldPhoto.split('companies%2F').pop().split('?alt=media').shift();
    return firebase.storage().ref(`companies/${photoPath}`).delete();
};

const uploadPhoto = (uid, file) => {
    const storageRef = firebase.storage().ref();
    const fileExtension = file.name.split('.').pop();
    const fileName = `${uid}.${fileExtension}`;
    return storageRef.child(`companies/${fileName}`).put(file);
};

const getPhotoUrl = (uid, file) => {
    const fileExtension = file.name.split('.').pop();
    const bucketUrl = `${process.env.REACT_APP_FIRE_BASE_STORAGE_API}`;
    return `${bucketUrl}/o/companies%2F${uid}_200x200.${fileExtension}?alt=media`;
};