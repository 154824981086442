import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { CookieStorage } from 'redux-persist-cookie-storage';
import Cookies from 'cookies-js';

import { authReducer } from './auth';
import { categoryReducer } from './category';
import { userReducer } from './user';
import { preferencesReducer } from './preferences';
import { appReducer } from './app';
import { companyReducer } from './company';
import { companyUserReducer } from './companyUser';
import { contactReducer } from './contact';
import { courseReducer } from './course';
import { courseInstructorReducer } from './courseInstructor';
import { topicReducer } from './topic';

export default combineReducers({
  auth: persistReducer(
    {
      key: 'auth',
      storage,
      blacklist: ['error', 'loading'],
    },
    authReducer
  ),
  preferences: persistReducer(
    { key: 'preferences', storage: new CookieStorage(Cookies) },
    preferencesReducer
  ),
  user: userReducer,
  app: appReducer,
  category: categoryReducer,
  company: companyReducer,
  companyUser: companyUserReducer,
  contact: contactReducer,
  course: courseReducer,
  courseInstructor: courseInstructorReducer,
  topic: topicReducer,
  toastr: toastrReducer,
});
