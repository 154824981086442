import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from 'state/actions/index';
import MainLayout from 'components/common/layout/MainLayout';
import CategoryMenu from 'components/category/CategoryMenu';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CompanyForm from './CompanyForm';
import UserProfileSummary from 'components/user/box/UserProfileSummary';
import Avatar from '@material-ui/core/Avatar';
import BusinessIcon from '@material-ui/icons/Business';
import Typography from '@material-ui/core/Typography';
import UserProfileButtons from 'components/user/box/UserProfileButtons';
import DoneIcon from '@material-ui/icons/Done';
import { Translate } from "react-localize-redux";
import Button from '@material-ui/core/Button';

const styles = theme => ({
    root: {
        flexGrow: 1,
        margin: 20,
        marginTop: theme.spacing(10)
    },
    spinner: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: theme.spacing(4)
    },
    avatar: {
        width: theme.spacing(15),
        height: theme.spacing(15),
        margin: 'auto'
    },
    icon: {
        margin: theme.spacing(1),
        fontSize: 50,
        color: 'green'
    }
});

const CompanyCreate = props => {

    React.useEffect(() => {
        props.gFetchItem();
    }, []);

    const onValidSubmit = (formData) => {
        props.gSubmitItem(formData);
    }

    const { classes } = props;

    return (
        <MainLayout>
            <div className={classes.root}>
                {props.gItemLoading || props.gSubmitLoading ?
                    (<div className={classes.spinner}><CircularProgress className={classes.progress} size={50} /></div>) :
                    (<Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                            <UserProfileSummary item={props.gItem} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={9} lg={9}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Card>
                                        <CardContent style={{ padding: '0' }}>
                                            <UserProfileButtons item={props.gItem} tabValue={2} />
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card>
                                        <CardContent>
                                            {props.gSubmitedCompany ?
                                                <div style={{ textAlign: 'center', marginTop: '50px', marginBottom: '50px' }}>
                                                    <Typography variant="subtitle1" align="center">
                                                        <DoneIcon className={classes.icon} /><br />
                                                        <Translate id="createdCompanyNotification" />
                                                    </Typography>
                                                    <br />
                                                    <Link to={"/company/" + props.gSubmitedCompany} style={{ textDecoration: 'none' }}>
                                                        <Button variant="contained" color="secondary">
                                                            <Translate id="goToCompanyProfile" />
                                                        </Button>
                                                    </Link>
                                                </div> :
                                                <CompanyForm
                                                    onValidSubmit={onValidSubmit}
                                                    submitError={props.gSubmitError}
                                                />
                                            }
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>)}
            </div>
        </MainLayout>
    );
}

const mapStateToProps = state => {
    return {
        gIsAuth: !!state.auth.user.id,
        gUserId: state.auth.user.id,
        gItem: state.user.item,
        gItemLoading: !state.user.item,
        gItemError: state.user.itemError,
        gSubmitLoading: state.company.submitLoading,
        gSubmitError: state.company.submitError,
        gSubmitedCompany: state.company.submitedCompany,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        gSubmitItem: (formData) => dispatch(actions.companySubmitItem(formData)),
        gFetchItem: () => dispatch(actions.userFetchItemByUserId()),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CompanyCreate));